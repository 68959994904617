import "./App.css";
import React from "react";
import "./Style/main.css";
import "./Style/stylecomponent";
import AppRoutes from "./Routes/Routes";
import { FaAngleUp } from "react-icons/fa";
import ScrollToTop from "react-scroll-to-top";

function App() {
  return (
    <div className="App">
      {/* <ScrollToTop smooth component={<FaAngleUp />} /> */}
      <AppRoutes />
    </div>
  );
}

export default App;
