import React from "react";
import { useNavigate } from "react-router-dom";
import "./VouchersBox.css";

const VouchersBox = ({ title, route, idx, listingType }) => {
  const navigate = useNavigate();

  return (
    <div className="col-md-6">
      {listingType ? (
        <>
          <div
            className="Vouchers_box"
            onClick={() =>
              navigate(route, {
                state: listingType,
              })
            }
          >
            <h2>{title}</h2>
          </div>
        </>
      ) : (
        <>
          <div className="Vouchers_box" onClick={() => navigate(route)}>
            <h2>{title}</h2>
          </div>
        </>
      )}
    </div>
  );
};

export default VouchersBox;
