import axios from "axios";
import { apiActiveURL, appId, appKey } from "../../ApiBaseURL";

export const Category = (data) => {
  const SessionData = JSON.parse(localStorage.getItem("SessionData"));
  // const subrub = localStorage.getItem("subrub");
  // const area = localStorage.getItem("area");
  // console.log(data, "area area");
  var config = {
    url: `${apiActiveURL}/categories?area=${data.area}&subrub=${data.suburb}&listing_type=${data.listingType}`,
    method: "GET",
    headers: {
      AppKey: appKey,
      Token: SessionData[0],

      AppId: appId,
    },
  };

  return (dispatch) => {
    axios(config)
      .then((res) => {
        // console.log(res, "Category response");

        if (res.data.status) {
          // console.log("kasdmaskjdsak");
          CategorySuccess(dispatch, res);
        } else {
          // console.log(res.data.message, "res.data.messageres.data.message");
          CategoryFail(dispatch, res.data.message);
        }
      })
      .catch((e) => CategoryFail(dispatch, e.message));
  };
};

export const ResetCategory = () => {
  return (dispatch) => {
    dispatch({ type: "CATEGORY_SUCCESS", payload: null });
  };
};

const CategorySuccess = (dispatch, res) => {
  //console.log(res, 'post data res');
  dispatch({ type: "CATEGORY_SUCCESS", payload: res });
};

export const CategoryId = (res) => {
  // console.log(res, "post data res");
  return (dispatch) => {
    dispatch({ type: "CATEGORY_ID", payload: res });
  };
};
const CategoryFail = (dispatch, res) => {
  // console.log(res, "res error");
  dispatch({ type: "CATEGORY_FAIL", payload: res });
};
export const ResetCategoryFail = () => {
  return (dispatch) => {
    dispatch({ type: "CATEGORY_FAIL", payload: null });
  };
};
