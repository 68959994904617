import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import "./header.css";

import logo from "../assets/images/Logo.png";
import mobilelogo from "../assets/images/MobileLogo.png";
import BackArrow from "../assets/images/arrow_back.png";

import { Button, Container, Nav, Navbar } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { connect, useSelector } from "react-redux";
// import { ThemeMode } from "../Redux/Actions";
import { Spinner } from "react-bootstrap";
import { IoIosSearch } from "react-icons/io";
import { BsFillBellFill } from "react-icons/bs";
import SelectionRegion from "./SelectionRegion/SelectionRegion";
import { Logout } from "../Redux/Actions/Logout";
import { useDispatch } from "react-redux";
import { ResetLogout, ResetLogoutError } from "../Redux/Actions/Logout";
import { ToastContainer, toast } from "react-toastify";
import { useMediaQuery } from "react-responsive";
import { BsList } from "react-icons/bs";
import { RxCross2 } from "react-icons/rx";
import { Search } from "../Redux/Actions/Search";

// import Search from "../component/Search/Search";

const Header = forwardRef((props, ref) => {
  // console.log(props, "propsprops");

  const listingType = localStorage.getItem("listingType");
  const searchActive = useRef(null);
  // console.log(props, "header_component");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);
  const [searchData, setSearchData] = useState("");

  const test = useSelector((state) => state?.Focus?.data);

  const logoutSuccess = useSelector((state) => {
    // console.log(state.logout, "logout success selector");
    return state.logout.data;
  });

  const logoutError = useSelector((state) => {
    // console.log(state.logout.error, "logout error selector");
    return state.logout.error;
  });
  const SaveUserSuccess = useSelector((state) => {
    return state.GetUser.data;
  });

  // const SearchSuccess = useSelector((state) => {
  //   console.log(state?.Search?.data, "state?.Search?.data");
  // });
  const [open, setOpen] = useState(false);

  const [showmenu, setShowMenu] = useState(false);

  // Get Page data useEffect

  // useEffect(() => {
  //   // if (SearchSuccess) {
  //   console.log(SearchSuccess, "SearchSuccess");
  //   // }

  //   return () => {};
  // }, [SearchSuccess]);

  useEffect(() => {
    if (logoutSuccess) {
      // console.log(logoutSuccess, "if useEffect logout success");
      toast.success("User Logged out Successfully", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      // setLoading(false);
      // emptyfields();
      navigate("/login-account", { replace: true });
      localStorage.removeItem("SessionData");
      // localStorage.removeItem("area");
      // localStorage.removeItem("subrub");

      dispatch(ResetLogout());
    }
    // else if (logoutError) {
    //   console.log(logoutError, "else if useEffect sign up error");
    //   // setLoading(false);
    //   // emptyfields();
    //   toast.error(logoutError, {
    //     position: "top-center",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "colored",
    //   });

    //   // setErrMsg(true);
    //   dispatch(ResetLogoutError());
    // }
    else {
      // setLoading(false);
      // emptyfields();
      dispatch(ResetLogout());
      dispatch(ResetLogoutError());
    }
  }, [logoutSuccess, logoutError]);

  const handleSearch = (e) => {
    // console.log("handleSearch");
    navigate("/search-business");
    // e.preventDefault();
    let data = {
      listingType: listingType ? listingType : 0,
      area: SaveUserSuccess?.data?.data?.area,
      suburb: SaveUserSuccess?.data?.data?.suburb,
      categoryId: 0,
      serviceId: 0,
      query: searchData,
    };

    dispatch(Search(data));
  };

  const handleLogout = (e) => {
    e.preventDefault();
    // console.log("logout");
    const SessionData = JSON.parse(localStorage.getItem("SessionData"));
    // console.log(SessionData, "Session Data");
    dispatch(Logout());
  };

  const handleShowMenu = () => {
    setShowMenu((current) => !current);
  };

  const isMobileSize = useMediaQuery({ query: "(max-width: 767px)" });

  const closeOpenDropdown = (e) => {
    if (
      dropdownRef.current &&
      showmenu &&
      !dropdownRef.current.contains(e.target)
    ) {
      setShowMenu(false);
    }
  };

  document.addEventListener("mousedown", closeOpenDropdown);

  useEffect(() => {
    if (test) {
      // console.log(test, "localStorage.getItem('test')");
      searchActive?.current?.focus();
    }

    return () => {};
  }, [test]);

  return (
    <header className="header" id="top">
      <div className="header_inner">
        <div className="container">
          {isMobileSize ? (
            <div className="row">
              <div className="col-md-6">
                <div className="mobile_menu_navigation" ref={dropdownRef}>
                  <div className="mobile_menu_content">
                    <div className="header_mobile_section">
                      <div className="header_logo">
                        {/* <a onClick={() => navigate("/")}> */}
                        <img src={logo} alt="" />
                        {/* </a> */}
                      </div>
                      <div className="header_search">
                        <input
                          ref={searchActive}
                          type="text"
                          placeholder="Search for a business"
                          onChange={(e) => {
                            setSearchData(e.target.value);
                          }}
                        />
                        <IoIosSearch
                          className="search_icon"
                          color="#434040"
                          width={"100px"}
                          onClick={() => handleSearch()}
                        />
                      </div>
                    </div>
                  </div>
                  {showmenu && (
                    <div className="menu_content_main">
                      <div className="menu_content_inner">
                        <div className="menu_bottom_btn">
                          <button
                            className="help_button"
                            onClick={() => {
                              localStorage.removeItem("listingType");
                              navigate("/home");
                              setShowMenu(false);
                            }}
                          >
                            HOME
                          </button>
                          <button className="logout_brn" onClick={handleLogout}>
                            LOGOUT
                          </button>
                          {window.location.pathname == "/home" ? null : (
                            <button
                              className="help_button"
                              onClick={() => navigate(-1)}
                            >
                              BACK
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="header_mobile_menu_list">
                  <div className="menu_nofication">
                    <div className="menu_icon" onClick={() => handleShowMenu()}>
                      {showmenu ? (
                        <RxCross2 color="#528441" size={27} />
                      ) : (
                        <BsList color="#528441" size={27} />
                      )}
                    </div>
                    <div className="nofication_icon nofication_indicate">
                      <BsFillBellFill color="#707070" />
                    </div>
                  </div>
                  <div className="region_selection_notify">
                    <h4>Location:</h4>
                    <SelectionRegion />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-md-4">
                <div className="header_logo">
                  {/* <a onClick={() => navigate("/")}> */}
                  <img src={logo} alt="" />
                  {/* </a> */}
                </div>
              </div>
              <div className="col-md-8">
                <div className="header_right_side">
                  <SelectionRegion />
                  <div className="header_right_side_inner">
                    {/* {window.location.pathname != "/my-account" ? ( */}
                    <div className="header_search">
                      <input
                        ref={searchActive}
                        type="text"
                        placeholder="Search for a business"
                        // ref={ref}
                        // onClick={() => navigate("/search-business")}
                        onChange={(e) => {
                          setSearchData(e.target.value);
                        }}
                      />
                      <IoIosSearch
                        className="search_icon"
                        color="#434040"
                        onClick={() => handleSearch()}
                      />
                    </div>
                    {/* ) : null} */}

                    <button
                      className="help_button"
                      onClick={() => {
                        localStorage.removeItem("listingType");
                        navigate("/home");
                      }}
                    >
                      HOME
                    </button>
                    <button className="logout_brn" onClick={handleLogout}>
                      LOGOUT
                    </button>
                    {window.location.pathname == "/home" ? null : (
                      <button
                        className="help_button"
                        onClick={() => navigate(-1)}
                      >
                        BACK
                      </button>
                    )}
                    <div className="nofication_icon nofication_indicate">
                      <BsFillBellFill color="#707070" />
                    </div>
                  </div>
                  <Search open={open} />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </header>
  );
});

const mapStateToProps = (state) => {
  return {
    // mode: state.mode.themeMode,
  };
};

export default connect(mapStateToProps, {
  // ThemeMode,
})(Header);
