import axios from "axios";
import { apiActiveURL, appId, appKey } from "../../ApiBaseURL";

export const Favourite = (service_id) => {
  const SessionData = JSON.parse(localStorage.getItem("SessionData"));
  // console.log(service_id, SessionData, "location data");
  //   return;

  var config = {
    url: `${apiActiveURL}/add_favourite?user_id=${SessionData[1]}&service_id=${service_id}`,
    method: "POST",
    headers: {
      AppKey: appKey,
      Token: SessionData[0],

      AppId: appId,
    },
    // data: data,
  };

  return (dispatch) => {
    axios(config)
      .then((res) => {
        // console.log(res, "fAVOURITE response =======");
        // console.log(res, "hotel response");
        if (res.data.code == 200) {
          FavouriteSuccess(dispatch, res.data);
        } else {
          FavouriteFail(dispatch, res.data);
        }
      })
      .catch((e) => {
        // console.log(e, "fail");
        FavouriteFail(dispatch, e.message);
      });
  };
};

export const ResetFavourite = () => {
  // console.log("reset sign up action called");
  return (dispatch) => {
    dispatch({ type: "FAVOURITE_SUCCESS", payload: null });
  };
};

export const ResetFavouriteError = () => {
  // console.log("reset sign up error action called");
  return (dispatch) => {
    dispatch({ type: "FAVOURITE_FAIL", payload: null });
  };
};

const FavouriteSuccess = (dispatch, res) => {
  // console.log(res, "sign up data");
  dispatch({ type: "FAVOURITE_SUCCESS", payload: res });
};

const FavouriteFail = (dispatch, res) => {
  // console.log(res, "res action");
  dispatch({ type: "FAVOURITE_FAIL", payload: res });
};
