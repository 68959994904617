import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import InputField from "../../InputText/InputText";
import user_icon from "../../../assets/images/user_icon.png";
import user_mail_icon from "../../../assets/images/user_mail_icon.png";
import phone_icon from "../../../assets/images/phone.svg";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Loader from "../../Loader/Loader";
import Button from "../../Button/Button";
// import { apiActiveURL, appId, appKey } from "../../ApiBaseURL";
import { apiActiveURL, appId, appKey } from "../../../ApiBaseURL";
import Axios from "axios";
// import { apiActiveURL, appId, appKey } from "../../../ApiBaseURL";
// import axios from "axios";
import { toast } from "react-toastify";

const CustomerDetail = () => {
  var SessionData = JSON.parse(localStorage.getItem("SessionData"));
  const [showPassword, setShowPassword] = useState(false);
  const [showconfirmPassword, setShowConfirmPassword] = useState(false);
  const [msgBody, setMsgBody] = useState("");
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [loader, setLoader] = React.useState(false);

  const [loader2, setLoader2] = useState(false);

  useEffect(() => {
    fetchUser();
    return () => {};
  }, []);

  const changePassword = (e) => {
    e.preventDefault();
    setLoader2(true);
    // console.log(password, confirmPassword, "values");
    let SessionData = JSON.parse(localStorage.getItem("SessionData"));
    const url = `${apiActiveURL}/forgotuserpassword/${SessionData[1]}`;
    if (password == confirmPassword) {
      let ApiParamForUpdateUser = {
        password: password,
      };
      const options = {
        method: "POST",
        headers: {
          AppKey: appKey,
          Token: SessionData[0],
          AppId: appId,
        },
        data: ApiParamForUpdateUser,
        url,
      };
      Axios(options)
        .then((res) => {
          // console.log(res, "response------");
          if (res.data.code === 200) {
            // console.log("Password Updated Successfully");
            toast.success("Password Updated Successfully", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            // props.setFeedback("YourHotel", "Password Reset Successful!", true, "");
            setLoader2(false);
          } else if (res.data.code === 403) {
            if (
              res.data.message === "Passwords must be a minimum of 8 digits"
            ) {
              // console.log(res.data.message, "403");
              // props.setFeedback("YourHotel", res.data.message, true, "");
              toast.error("Passwords must be a minimum of 8 digits", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
              setLoader2(false);
            } else {
              toast.error("Something Went Wrong", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
              // props.setFeedback(
              //   "YourHotel",
              //   "Session Expired. Logging Out",
              //   true,
              //   ""
              // );
              // handleSessionId(props.userid, props.token, props);
              setLoader2(false);
            }
          } else {
            // console.log(res, "user else");
            setLoader2(false);
            toast.error(res.data.message, {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });

            // props.setFeedback("YourHotel", res.data.message, true, "");
          }
          // setLoader3(true);
        })
        .catch((error) => {
          // props.setFeedback("YourHotel", "Something Went Wrong", true, "");
          // console.log(error, "user");
          toast.error("Something Went Wrong", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setLoader2(false);
          // setLoader3(true);
        });
    } else {
      // console.log("Confirm Passsword Incorrect");
      setLoader2(false);
      // props.setFeedback("YourHotel", "Confirm Password Incorrect!", true, "");
    }
  };

  const fetchUser = () => {
    // const SessionData = JSON.parse(localStorage.getItem("SessionData"));
    const url = `${apiActiveURL}/get_user/${SessionData[1]}`;
    const options = {
      method: "GET",
      headers: {
        AppKey: appKey,
        Token: SessionData[0],
        AppId: appId,
      },
      url,
    };
    Axios(options)
      .then((res) => {
        // console.log("userId", res);
        if (res.data.code === 200) {
          //setUserDetails(res.data.data);
          if (res.data.data.username) {
            setUserName(res.data.data.username);
          }
          if (res.data.data.email) {
            setEmail(res.data.data.email);
          }
          if (res.data.data.phone) {
            setPhone(res.data.data.phone);
          }
          setLoader2(false);
        } else {
          toast.error("Something Went Wrong", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setLoader2(false);
        }
      })
      .catch((error) => {
        toast.error("Something Went Wrong", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoader2(false);
      });
  };

  const update = (e) => {
    e.preventDefault();
    setLoader(true);
    // const SessionData = JSON.parse(localStorage.getItem("SessionData"));
    const url = `${apiActiveURL}/update_user/${SessionData[1]}`;
    let ApiParamForUpdateUser = {
      username: userName,
      email: email,
      phone: phone,
    };
    const options = {
      method: "POST",
      headers: {
        AppKey: appKey,
        Token: SessionData[0],
        AppId: appId,
      },
      data: ApiParamForUpdateUser,
      url,
    };
    Axios(options)
      .then((res) => {
        // console.log(res, "user");
        if (res.data.code === 200) {
          setLoader(false);
          toast.success("User Updated Successfully", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else if (res.data.code === 403) {
          setLoader(false);

          // props.setFeedback('YourHotel', 'Session Expired. Logging Out', true, '');
          toast.error("Something Went Wrong", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          // setMsgTitle('TEAM Business Network');
          // setMsgBody('Session Expired. Logging Out');
          // setVisible(true);
          // setLoader(true);
          // handleSessionId(props.userid, props.token, props);
        } else {
          setLoader(false);

          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          // console.log(res, 'user else');
          // setMsgTitle('TEAM Business Network');
          // setMsgBody(res.data.message);
          // setVisible(true);
        }
      })
      .catch((error) => {
        setLoader(false);

        toast.error("No Data Found...", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        // setMsgTitle('TEAM Business Network');
        // setMsgBody('No Data Found...');
        // setVisible(true);
        // console.log(error, 'user');
        // setLoader(true);
      });
  };

  return (
    <div className="customer_account_detail">
      <h2>My Details</h2>
      <div className="account_detail_inner">
        <Form
          // onSubmit={(e) => {
          //   update(e, email, phone);
          // }}
          onSubmit={update}
        >
          <div className="row">
            <div className="col-md-6">
              <InputField
                className="custom_input_field"
                DivclassName="name_input_section"
                type="text"
                placeholder="Username"
                icon={user_icon}
                iconClass="account_name_icons"
                required
                onChange={(e) => setUserName(e.target.value)}
                value={userName}
              />
            </div>
            <div className="col-md-6">
              <InputField
                DivclassName="mail_input_section"
                type="email"
                placeholder="Email Address"
                className="custom_input_field"
                icon={user_mail_icon}
                iconClass="account_mail_icons"
                required
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                disabled
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <InputField
                DivclassName="mail_input_section phone_number"
                type="number"
                placeholder="Phone Number"
                icon={phone_icon}
                className="custom_input_field"
                iconClass="account_mail_icons"
                required
                onChange={(e) => setPhone(e.target.value)}
                value={phone}
              />
            </div>
          </div>
          <div className="update_account_div">
            <Button
              name={loader ? <Loader /> : "Update"}
              className="update_account_btn green_btn"
              type="submit"
            />
          </div>
        </Form>

        <Form onSubmit={changePassword}>
          <div className="row_section">
            <div className="password_section">
              <InputField
                DivclassName="creation_input_section"
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                className="custom_input_field"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              {showPassword ? (
                <FaEyeSlash onClick={() => setShowPassword(false)} />
              ) : (
                <FaEye onClick={() => setShowPassword(true)} />
              )}
            </div>
            <div className="password_section">
              <InputField
                DivclassName="creation_input_section"
                type={showconfirmPassword ? "text" : "password"}
                placeholder="Confirm Password"
                className="custom_input_field"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
              {showconfirmPassword ? (
                <FaEyeSlash onClick={() => setShowConfirmPassword(false)} />
              ) : (
                <FaEye onClick={() => setShowConfirmPassword(true)} />
              )}
            </div>
          </div>
          <div className="update_account_div">
            <Button
              name={loader2 ? <Loader /> : "Change Password"}
              className="update_account_btn green_btn"
              type="submit"
            />
          </div>
        </Form>
      </div>
    </div>
  );
};

export default CustomerDetail;
