import axios from "axios";
import { apiActiveURL, appId, appKey } from "../../ApiBaseURL";

export const GuestDirectory = (data) => {
  // console.log(data, 'data===============================================')
  // return

  var config = {
    url: `${apiActiveURL}/services?listing_type=${data.list_type}&hotel_id=${data.hotel_id}`,
    method: "GET",
    headers: {
      AppKey: appKey,
      // Token: "A3Oy18BqjvFPIUTXY94im76ENuh5HVsngbo2lJxkL0reaZftKWcSDQwMpCzR",
      AppId: appId,
      // Token: token
    },
  };

  return (dispatch) => {
    axios(config)
      .then((res) => {
        // console.log(res, "guest response");

        if (res.data.code === 401) {
          //   console.log("kasdmaskjdsak");
          GuestDirectoryFail(dispatch);
        } else {
          GuestDirectorySuccess(dispatch, res);
        }
      })
      .catch(() => GuestDirectoryFail(dispatch));
  };
};

export const ResetGuestDirectory = () => {
  return (dispatch) => {
    dispatch({ type: "GUEST_DIRECTORY_SUCCESS", payload: null });
  };
};

const GuestDirectorySuccess = (dispatch, res) => {
  //console.log(res, 'post data res');
  dispatch({ type: "GUEST_DIRECTORY_SUCCESS", payload: res });
};

const GuestDirectoryFail = (dispatch) => {
  dispatch({ type: "GUEST_DIRECTORY_FAIL", payload: "Something Went Wrong" });
};
