const INITIAL_STATE = {
  service: null,
  // category_id: 0,
  error: null,
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SERVICE_SUCCESS":
      return { ...state, service: action.payload };
    //   case "CATEGORY_ID":
    //     return { ...state, category_id: action.payload };

    case "SERVICE_FAIL":
      return { ...state, error: action.payload };

    default:
      return state;
  }
};
