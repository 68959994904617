import React from "react";
import { Form } from "react-bootstrap";
import { FaUser } from "react-icons/fa";

const InputField = ({
  type,
  required,
  value,
  onBlur,
  placeholder,
  onChange,
  maxlength,
  className,
  DivclassName,
  icon,
  ref,
  rows,
  as,
  iconClass,
  disabled,
}) => {
  return (
    <div className={DivclassName}>
      <Form.Group>
        <Form.Control
          type={type}
          ref={ref}
          as={as}
          rows={rows}
          required={required}
          value={value}
          onBlur={onBlur}
          placeholder={placeholder}
          onChange={onChange}
          maxlength={maxlength}
          className={className}
          disabled={disabled}
        />
        {icon && <img src={icon} alt="" className={iconClass} />}
      </Form.Group>
    </div>
  );
};

export default InputField;
