import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Button from "../../component/Button/Button";
import FavouriteBox from "../../component/FavouriteBox/FavouriteBox";
import Loader from "../../component/Loader/Loader";
import Footer from "../../footer/footer";
import Header from "../../Header/Header";

import {
  GetSpecialPromotions,
  ResetGetSpecialPromotions,
  ResetGetSpecialPromotionsError,
} from "../../Redux/Actions/GetSpecialPromotions";

import {
  GetPassCoupons,
  ResetGetPassCoupons,
  ResetGetPassCouponsError,
} from "../../Redux/Actions/GetPassCoupons";
import "./ListPassCoupons.css";
import { useLocation } from "react-router";

const ListPassCoupons = (props) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(true);
  const [error, setError] = useState("");
  const location = useLocation();
  // console.log(location, "location 360 pass coupons");

  const [passCouponsData, setPassCouponsData] = useState([]);

  const GetPassCouponsSuccess = useSelector((state) => {
    // console.log(state.GetPassCoupons, "get 360 pass success");
    return state.GetPassCoupons.data;
  });

  const GetPassCouponsError = useSelector((state) => {
    return state.GetPassCoupons.error;
  });
  useEffect(() => {
    let listingtype = location.state;
    dispatch(GetPassCoupons(listingtype));

    return () => {
      dispatch(ResetGetPassCoupons());
      dispatch(ResetGetPassCouponsError());
    };
  }, []);

  useEffect(() => {
    if (GetPassCouponsSuccess) {
      //   console.log(
      //     GetPassCouponsSuccess,
      //     "if GetPassCouponsSuccess"
      //   );
      setLoader(false);
      setPassCouponsData(GetPassCouponsSuccess.data.data);
    } else if (GetPassCouponsError) {
      setLoader(false);
      toast.error(GetPassCouponsError, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      setLoader(false);
      setError("No Special Promotions Found");
    }

    return () => {
      // dispatch(ResetCategoryFail());
      // dispatch(ResetCategory());
    };
  }, [GetPassCouponsSuccess, GetPassCouponsError]);

  return (
    <>
      <div className="bg_body">
        <Header />
        <div className="inner_pg">
          <div className="voucherscoupons">
            <div className="container">
              <div className="row">
                <div className="col-md-8 offset-md-2">
                  <h1 className="top_heading">360 Pass and Coupons</h1>
                  {!loader ? (
                    <div className="row">
                      {passCouponsData.length > 0 ? (
                        passCouponsData.map((item) => (
                          <>
                            {console.log(item, "item")}
                            <FavouriteBox data={item} name="passCoupons" />
                          </>
                        ))
                      ) : (
                        <p className="text-center">No 360Pass Coupons</p>
                      )}
                    </div>
                  ) : (
                    <Loader />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default ListPassCoupons;
