import React from "react";
import "./ChildCategoryBox.css";
import { useNavigate } from "react-router";
import defaultThumbnail from "../../assets/images/default_thumbnail.png";

const ChildCategoryBox = ({ title, text, data, next, businessimage }) => {
  // console.log(window.location, "child car box");

  // console.log(
  //   { title: title, text: text, data: data, businessimage: businessimage },
  //   "business--listing--data"
  // );
  const navigate = useNavigate();
  const handleCategory = (data) => {
    // console.log(data, "clicked data");
  };

  return (
    <>
      {/* <div
        className={
          window.location.pathname == "/search-business"
            ? "col-md-4"
            : "col-md-6"
        }
      >
        <div
          className="child_category_box"
          onClick={() => {
            handleCategory(data);
            navigate("/business", {
              state: data,
            });
          }}
        >
          <div className="child_category_box_inner">
            <h2>{title}</h2>
            <p
              dangerouslySetInnerHTML={{
                __html: text,
              }}
            ></p>
          </div>
        </div>
      </div> */}

      <div
        className="col-lg-4 col-md-6"
        onClick={() => {
          handleCategory(data);
          navigate("/business", {
            state: data,
          });
        }}
      >
        <div className="services_box business_listing_box">
          <div className="services_icon">
            <img src={businessimage ? businessimage : defaultThumbnail} />
          </div>
          <div className="services_name">
            <h2>{title}</h2>
            <p
              dangerouslySetInnerHTML={{
                __html: text,
              }}
            ></p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChildCategoryBox;
