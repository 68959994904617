import axios from "axios";
import { apiActiveURL, appId, appKey } from "../../ApiBaseURL";

export const SaveLocation = (data) => {
  const SessionData = JSON.parse(localStorage.getItem("SessionData"));
  // console.log(data, SessionData, "location data");
  //   return;

  var config = {
    url: `${apiActiveURL}/save/location`,
    method: "POST",
    headers: {
      AppKey: appKey,
      Token: SessionData[0],

      AppId: appId,
    },
    data: data,
  };

  return (dispatch) => {
    axios(config)
      .then((res) => {
        // console.log(res, "save location response =======");
        // console.log(res, "hotel response");
        if (res.data.status) {
          SaveLocationlSuccess(dispatch, res);
        } else {
          SaveLocationlFail(dispatch, res.data.message);
        }
      })
      .catch((e) => {
        // console.log(e, "fail");
        SaveLocationlFail(dispatch, e.message);
      });
  };
};

export const ResetSaveLocation = () => {
  // console.log("reset sign up action called");
  return (dispatch) => {
    dispatch({ type: "SAVE_LOCATION_SUCCESS", payload: null });
  };
};

export const ResetSaveLocationError = () => {
  // console.log("reset sign up error action called");
  return (dispatch) => {
    dispatch({ type: "SAVE_LOCATION_FAIL", payload: null });
  };
};

const SaveLocationlSuccess = (dispatch, res) => {
  // console.log(res, "sign up data");
  dispatch({ type: "SAVE_LOCATION_SUCCESS", payload: res });
};

const SaveLocationlFail = (dispatch, res) => {
  // console.log(res, "res action");
  dispatch({ type: "SAVE_LOCATION_FAIL", payload: res });
};
