import axios from "axios";
import { apiActiveURL, appId, appKey } from "../../ApiBaseURL";

export const FetchArea = () => {
  const SessionData = JSON.parse(localStorage.getItem("SessionData"));

  var config = {
    url: `${apiActiveURL}/get_areas_list`,
    method: "GET",
    headers: {
      AppKey: appKey,
      Token: SessionData[0],
      AppId: appId,
    },
  };

  return (dispatch) => {
    axios(config)
      .then((res) => {
        // console.log(res, "fetch area response");

        if (res.data.status) {
          FetchAreaSuccess(dispatch, res);
        } else {
          FetchAreaFail(dispatch, res.data.message);
        }
      })
      .catch((e) => {
        FetchAreaFail(dispatch, e.message);
      });
  };
};

export const ResetFetchArea = () => {
  return (dispatch) => {
    dispatch({ type: "FETCH_AREA_SUCCESS", payload: null });
  };
};

export const ResetFetchAreaError = () => {
  return (dispatch) => {
    dispatch({ type: "FETCH_AREA_FAIL", payload: null });
  };
};

const FetchAreaSuccess = (dispatch, res) => {
  //console.log(res, 'post data res');
  dispatch({ type: "FETCH_AREA_SUCCESS", payload: res });
};

const FetchAreaFail = (dispatch, res) => {
  dispatch({ type: "FETCH_AREA_FAIL", payload: res });
};
