import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Button from "../../component/Button/Button";
import FavouriteBox from "../../component/FavouriteBox/FavouriteBox";
import Loader from "../../component/Loader/Loader";
import Footer from "../../footer/footer";
import Header from "../../Header/Header";
import {
  GetFavourite,
  RemoveFavourite,
  ResetGetFavourite,
  ResetGetFavouriteError,
} from "../../Redux/Actions/GetFavourites";
import "./FavouriteList.css";

const FavouriteList = (props) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(true);
  const [error, setError] = useState("");

  const [favouriteData, setfavouriteData] = useState([]);
  const GetFavouriteSuccess = useSelector((state) => {
    return state.GetFavourites.data;
  });

  const GetFavouriteError = useSelector((state) => {
    return state.GetFavourites.error;
  });
  useEffect(() => {
    dispatch(GetFavourite());

    return () => {
      dispatch(ResetGetFavourite());
      dispatch(ResetGetFavouriteError());
    };
  }, []);

  useEffect(() => {
    if (GetFavouriteSuccess?.data?.code == 200) {
      // console.log(GetFavouriteSuccess, "GetFavouriteSuccess");
      setLoader(false);

      if (GetFavouriteSuccess.data?.data.length > 0) {
        setLoader(false);
        // console.log(
        //   GetFavouriteSuccess?.data?.data,
        //   "GetFavouriteSuccess?.data?.data"
        // );
        setfavouriteData(GetFavouriteSuccess?.data?.data);
        // dispatch(ResetCategory());
      } else {
        setLoader(false);
        setError("No Favourite Found");
      }
    } else if (GetFavouriteError) {
      setLoader(false);
      toast.error(GetFavouriteError, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      setLoader(false);
      setError("No Favourite Found");
    }

    return () => {
      // dispatch(ResetCategoryFail());
      // dispatch(ResetCategory());
    };
  }, [GetFavouriteSuccess, GetFavouriteError]);

  const removeItem = (removeId) => {
    const afterRemoval = favouriteData.filter((a) => a.id != removeId);
    // console.log(afterRemoval, ":afterRemoval");
    setfavouriteData([...afterRemoval]);
  };
  return (
    <>
      <div className="bg_body">
        <Header />
        <div className="inner_pg">
          <div className="voucherscoupons">
            <div className="container">
              <div className="row">
                <div className="col-md-8 offset-md-2">
                  <h1 className="top_heading">Favourites</h1>
                  {!loader ? (
                    <div className="row">
                      {favouriteData.length > 0 ? (
                        favouriteData.map((item) => (
                          <>
                            {console.log(item, "item")}
                            <FavouriteBox
                              data={item}
                              removeItem={removeItem}
                              name="favoriteList"
                            />
                          </>
                        ))
                      ) : (
                        <p className="text-center">No Favourites Found</p>
                      )}
                    </div>
                  ) : (
                    <Loader />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default FavouriteList;
