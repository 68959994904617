import axios from "axios";
import { apiActiveURL, appId, appKey } from "../../ApiBaseURL";

export const GetFavourite = () => {
  const SessionData = JSON.parse(localStorage.getItem("SessionData"));

  var config = {
    url: `${apiActiveURL}/get_user_favorites/${SessionData[1]}`,
    method: "GET",
    headers: {
      AppKey: appKey,
      Token: SessionData[0],
      AppId: appId,
    },
  };

  return (dispatch) => {
    axios(config)
      .then((res) => {
        // console.log(res, "get fav response =======");
        if (res.data.status) {
          GetFavouriteSuccess(dispatch, res);
        } else {
          GetFavouriteFail(dispatch, res.data.message);
        }
      })
      .catch((e) => {
        GetFavouriteFail(dispatch, e.message);
      });
  };
};

export const RemoveFavourite = (data) => {
  // console.log(data, "my data");
  const SessionData = JSON.parse(localStorage.getItem("SessionData"));

  var config = {
    url: `${apiActiveURL}/remove_favourite/${SessionData[1]}/${data}`,
    method: "DELETE",
    headers: {
      AppKey: appKey,
      Token: SessionData[0],
      AppId: appId,
    },
  };

  return (dispatch) => {
    axios(config)
      .then((res) => {
        if (res.data.status) {
          RemoveFavouriteSuccess(dispatch, res);
        } else {
          RemoveFavouriteFail(dispatch, res.data.message);
        }
      })
      .catch((e) => {
        // console.log(e, "fail");
        RemoveFavouriteFail(dispatch, e.message);
      });
  };
};

export const ResetGetFavourite = () => {
  return (dispatch) => {
    dispatch({ type: "GET_FAVOURITE_SUCCESS", payload: null });
  };
};

export const ResetGetFavouriteError = () => {
  return (dispatch) => {
    dispatch({ type: "GET_FAVOURITE_FAIL", payload: null });
  };
};
export const ResetRemoveFavourite = () => {
  return (dispatch) => {
    dispatch({ type: "REMOVE_FAVOURITE_SUCCESS", payload: null });
  };
};
export const ResetRemoveFavouriteError = () => {
  return (dispatch) => {
    dispatch({ type: "REMOVE_FAVOURITE_FAIL", payload: null });
  };
};

const GetFavouriteSuccess = (dispatch, res) => {
  dispatch({ type: "GET_FAVOURITE_SUCCESS", payload: res });
};
const RemoveFavouriteSuccess = (dispatch, res) => {
  dispatch({ type: "REMOVE_FAVOURITE_SUCCESS", payload: res });
};

const RemoveFavouriteFail = (dispatch, res) => {
  dispatch({ type: "REMOVE_FAVOURITE_FAIL", payload: res });
};

const GetFavouriteFail = (dispatch, res) => {
  dispatch({ type: "GET_FAVOURITE_FAIL", payload: res });
};
