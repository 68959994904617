import "./ServicesBox.css";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const ServicesBox = ({
  services_name,
  services_img,
  listingType,
  selected,
  pageName,
}) => {
  // console.log(listingType, "listingType");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClick = () => {
    if (selected) {
      localStorage.setItem("listingType", listingType);
      navigate(pageName, { state: services_name });
    } else {
      toast.error("Please Select Area and Suburb", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
  return (
    <div
      className="col-lg-4 col-md-6"
      onClick={() => {
        handleClick();
      }}
    >
      <div className="services_box">
        <div className="services_icon">
          <img src={services_img} />
        </div>
        <div className="services_name">
          <h3>{services_name}</h3>
        </div>
      </div>
    </div>
  );
};

export default ServicesBox;
