import axios from "axios";
import { apiActiveURL, appId, appKey } from "../../ApiBaseURL";

export const Service = (data) => {
  const SessionData = JSON.parse(localStorage.getItem("SessionData"));
  // console.log(data.listingType, "data.list");
  const serviceId = localStorage.getItem("categoryId");
  let url = "";
  // console.log(localStorage.getItem("categoryId"), "localStorage.getItem");

  if (serviceId == "All") {
    url = `${apiActiveURL}/all_services?area=${data.area}&subrub=${data.suburb}&listing_type=${data.listingType}`;
  } else if (serviceId == "New") {
    url = `${apiActiveURL}/service_new?area=${data.area}&listing_type=${data.listingType}`;
  } else if (serviceId == "Popular") {
    if (data.listingType == 9 || data.listingType == 5) {
      // console.log("data.listingType == 9 || data.listingType == 5");
      url = `${apiActiveURL}/service/most/popular?area=${data.area}&subrub=${data.suburb}&listing_type=${data.listingType}`;
    } else {
      // console.log("data.listingType = other");
      url = `${apiActiveURL}/service/most/popular?listing_type=${data.listingType}`;
    }
  } else {
    url = `${apiActiveURL}/services/${data.serviceId}?area=${data.area}&subrub=${data.suburb}&listing_type=${data.listingType}`;
  }
  var config = {
    // url: `${apiActiveURL}/categories?area=${area}&subrub=${subrub}&listing_type=${data.listingType}`,
    method: "GET",
    headers: {
      AppKey: appKey,
      Token: SessionData[0],

      AppId: appId,
    },
    url,
  };

  return (dispatch) => {
    axios(config)
      .then((res) => {
        // console.log(res, "Service response");

        if (res.data.status) {
          ServiceSuccess(dispatch, res);
        } else {
          // console.log(res.data.message, "res.data.messageres.data.message");
          ServiceFail(dispatch, res.data.message);
        }
      })
      .catch((e) => ServiceFail(dispatch, e.message));
  };
};

export const ResetService = () => {
  return (dispatch) => {
    dispatch({ type: "SERVICE_SUCCESS", payload: null });
  };
};

const ServiceSuccess = (dispatch, res) => {
  //console.log(res, 'post data res');
  dispatch({ type: "SERVICE_SUCCESS", payload: res });
};

// export const CategoryId = (res) => {
//   console.log(res, "post data res");
//   return (dispatch) => {
//     dispatch({ type: "CATEGORY_ID", payload: res });
//   };
// };
const ServiceFail = (dispatch, res) => {
  // console.log(res, "res error");
  dispatch({ type: "SERVICE_FAIL", payload: res });
};
export const ResetServiceFail = () => {
  return (dispatch) => {
    dispatch({ type: "SERVICE_FAIL", payload: null });
  };
};
