import React, { Fragment, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Button from "../../component/Button/Button";
import Footer from "../../footer/footer";
import Header from "../../Header/Header";
import { useLocation } from "react-router";
import InputField from "../../component/InputText/InputText";
import { toast } from "react-toastify";
import { apiActiveURL, appKey, appId } from "../../ApiBaseURL";
import axios from "axios";
import Loader from "../../component/Loader/Loader";
import { useNavigate } from "react-router-dom";

const VoucherDetails = () => {
  const [specialPromotion, setSpecialPromotion] = useState();
  const [payableAmount, setPayableAmount] = useState();
  const [confirmationcode, setConfirmationCode] = useState("");
  const [netamount, setNetAmount] = useState(0.0);
  const [loader, setLoader] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const SessionData = JSON.parse(localStorage.getItem("SessionData"));

  const data = location.state.data;

  useEffect(() => {
    if (data) {
      // console.log(data, "data");
      // console.log(location.state, "bilal--------");
      let specialPromotionDetails = JSON.parse(data?.coupon_details);
      // console.log(specialPromotionDetails, "details");
      setSpecialPromotion(specialPromotionDetails);
    }
  }, [data]);

  useEffect(() => {
    setConfirmationCode(generateConfirmationCode(6));
  }, []);

  const handleChangeBill = (e) => {
    // console.log(e.target.value, "valuee");
    setPayableAmount(e.target.value);
    if (e.target.value > 50) {
      console.warn("greater than 50");
      let discounted_price;
      let original_price = e.target.value;
      let discount = specialPromotion.coupon_percentage;
      discounted_price = original_price - (original_price * discount) / 100;
      setNetAmount(discounted_price);
    } else {
      setNetAmount(e.target.value);
    }
  };

  const handleVerifyCoupon = (e) => {
    e.preventDefault();
    // console.log(
    //   "payable Amount --------",
    //   payableAmount,
    //   "confirmation code----------",
    //   confirmationcode
    // );
    if (!payableAmount) {
      toast.error("Please enter net amount value.", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    } else if (!confirmationcode) {
      toast.error("Please tap 'Verify' again to confirm your purchase", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    }

    setLoader(true);

    const url = `${apiActiveURL}/add_transaction`;
    let ApiParamForTransaction = {
      coupon_id: data.id,
      payable_amount: payableAmount,
      discounted_amount: location.state.name == "passCoupons" ? netamount : 0,
      confirmation_code: confirmationcode,
    };
    //  console.log(ApiParamForTransaction, props.route.params.route);
    const options = {
      method: "POST",
      headers: {
        AppKey: appKey,
        Token: SessionData[0],
        AppId: appId,
      },
      data: ApiParamForTransaction,
      url,
    };
    axios(options)
      .then((res) => {
        // console.log(res, "add_transactionn");
        if (res.data.code === 200) {
          setLoader(false);
          toast.success("Coupon Verified Successfully!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          navigate("/home");
        } else if (res.data.code === 403) {
          setLoader(false);
          toast.error("Session Expired. Logging Out", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          setLoader(false);
          toast.error("No Data found", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch((error) => {
        setLoader(false);
        toast.error("Something went wrong", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        // console.log("how", error);
      });
  };

  const generateConfirmationCode = (length) => {
    let confirmationcode = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (let i = 0; i < length; i++) {
      confirmationcode += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return confirmationcode;
  };

  return (
    <Fragment>
      <div className="bg_body">
        <Header />
        <Container>
          <div className="favourites_main">
            <h4 className="favourites_main_head">My Favourites</h4>
            <div className="favourites_main_flex">
              <div className="favourites_main_inner">
                <div className="favourites_info_inner">
                  <h5>
                    {specialPromotion
                      ? specialPromotion?.coupon_name
                      : "Name of Pass Business"}
                  </h5>

                  <p>
                    {specialPromotion
                      ? specialPromotion?.coupon_details
                      : "No Data"}
                  </p>
                  {/* <p>
                    Phasellus erat magna, cursus non nisi vitae, fringilla
                    varius purus. Lorem ipsum dolor amet, consectetur adipiscing
                    elit. Cras eget sodales odio.
                  </p> */}
                  <div className="favourites_info_buttons">
                    {loader ? (
                      <>
                        <Button
                          name={<Loader />}
                          className="green_btn favourites_verify_btn"
                          // onClick={handleVerifyCoupon}
                        />
                      </>
                    ) : (
                      <>
                        <Button
                          name="Verify"
                          className="green_btn favourites_verify_btn"
                          onClick={handleVerifyCoupon}
                        />
                      </>
                    )}

                    <div className="verify_conformation">
                      <h4>Confirmation Code</h4>
                      <span>{confirmationcode ? confirmationcode : null}</span>
                    </div>
                  </div>
                </div>
              </div>
              {location.state.name == "specialPromotions" ? (
                <>
                  <div className="favourites_price_info">
                    <div className="favourites_price_info_inner">
                      <h4>Amount payable</h4>
                      {/* <h1>$36.00</h1> */}
                      <div className="row_section">
                        <InputField
                          DivclassName="mail_input_section"
                          type="number"
                          placeholder="Amount Payable"
                          className="custom_input_field"
                          // icon={user_mail_icon}
                          iconClass="account_mail_icons"
                          // onChange={(e) => {
                          //   setPayableAmount(e.target.value);
                          // }}
                          onChange={handleChangeBill}
                          // // required
                          value={payableAmount}
                        />
                      </div>
                      <p>
                        Show your 360 Pass to your Server and then tap verify
                      </p>
                    </div>
                    {/* <div className="favourites_price_percent_main">
                      <div className="favourites_price_percent_info price_percent_info_after">
                        <p>Insert the full value of your bill</p>
                        <div className="price_percent">
                          <span>$45.00</span>
                        </div>
                      </div>
                      <div className="favourites_price_percent_info availbe_discount">
                        <p>Available Discount</p>
                        <div className="price_percent availbe_discount_percent">
                          <span>20%</span>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </>
              ) : (
                <>
                  <div className="favourites_price_info">
                    <div className="favourites_price_info_inner">
                      <h4>Net amount payable</h4>
                      <h1>${netamount}</h1>

                      <p>Show your 360 Pass to your Server</p>
                    </div>
                    <div className="favourites_price_percent_main">
                      <div className="favourites_price_percent_info price_percent_info_after">
                        <p>Insert the full value of your bill</p>
                        <div className="row_section">
                          <InputField
                            DivclassName="mail_input_section"
                            type="number"
                            placeholder="Amount Payable"
                            className="custom_input_field"
                            // icon={user_mail_icon}
                            iconClass="account_mail_icons"
                            // onChange={(e) => {
                            //   setPayableAmount(e.target.value);
                            // }}
                            onChange={handleChangeBill}
                            // // required
                            value={payableAmount}
                          />
                        </div>
                        <div className="price_percent">
                          {/* <span>$45.00</span> */}
                        </div>
                      </div>
                      <div className="favourites_price_percent_info availbe_discount">
                        <p>Available Discount</p>
                        <div className="price_percent availbe_discount_percent">
                          <span>
                            {specialPromotion
                              ? specialPromotion.coupon_percentage
                              : 1}
                            %
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </Container>
        <Footer />
      </div>
    </Fragment>
  );
};

export default VoucherDetails;
