import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import ThemeReducer from "./ThemeReducer";
import GuestDirectory from "./GuestDirectory";
import Hotel from "./Hotel";
import SignUp from "./SignUp";
import Login from "./Login";
import Area from "./Area";
import Categories from "./Categories";
import ChildCategories from "./ChildCategories";
import Logout from "./Logout";
import Suburb from "./Suburb";
import FeaturedPromotion from "./FeaturedPromotion";
import SaveLocation from "./SaveLocation";
import GetUser from "./GetUser";
import Services from "./Services";
import GetBusiness from "./GetBusiness";
import Favourite from "./Favourite";
import GetFavourites from "./GetFavourites";
import GetSpecialPromotions from "./GetSpecialPromotions";
import GetPassCoupons from "./GetPassCoupons";
import Search from "./Search";
import Focus from "./focusHandlerReducer";

// import Service from "./Service";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  // mode: persistReducer(persistConfig, ThemeReducer),
  guest: GuestDirectory,
  hotel: Hotel,
  SignUp: SignUp,
  login: Login,
  Area: Area,
  Categories: persistReducer(persistConfig, Categories),
  ChildCategories: persistReducer(persistConfig, ChildCategories),
  logout: Logout,
  Suburb,
  FeaturedPromotion,
  SaveLocation,
  GetUser: persistReducer(persistConfig, GetUser),
  Services: persistReducer(persistConfig, Services),
  GetBusiness,
  Favourite,
  GetFavourites,
  GetSpecialPromotions,
  GetPassCoupons,
  Search,
  Focus,
  // hotel: persistReducer(persistConfig, Hotel),
});

const appReducer = (state, action) => {
  if (action.type == "LOGOUT_USER_SUCCESS") {
    return rootReducer(undefined, action);
  }
  return rootReducer(state, action);
};

export const store = createStore(appReducer, applyMiddleware(thunk));
export const persistor = persistStore(store);
