import React, { useState } from "react";
import { Form } from "react-bootstrap";
import InputField from "../../InputText/InputText";
import user_icon from "../../../assets/images/user_icon.png";
import user_mail_icon from "../../../assets/images/user_mail_icon.png";
import phone_icon from "../../../assets/images/phone.svg";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Loader from "../../Loader/Loader";
import Button from "../../Button/Button";
import { apiActiveURL, appId, appKey } from "../../../ApiBaseURL";
import axios from "axios";
import { toast } from "react-toastify";

const ContactTeam = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [msgBody, setMsgBody] = useState("");
  const [subject, setSubject] = useState("");
  const [msg, setMsg] = useState("");
  const [email, setEmail] = useState("");

  // const [msgBody, setMsgBody] = useState("");

  const [loader, setLoader] = React.useState(false);
  const SessionData = JSON.parse(localStorage.getItem("SessionData"));

  const handleContact = (e) => {
    e.preventDefault();
    setLoader(true);
    let currentdate = new Date();
    let datetime =
      currentdate.getFullYear() +
      "-" +
      (currentdate.getMonth() + 1) +
      "-" +
      currentdate.getDate() +
      " " +
      currentdate.getHours() +
      ":" +
      currentdate.getMinutes() +
      ":" +
      currentdate.getSeconds();
    const url = `${apiActiveURL}/add_contact`;
    let ApiParamForContact = {
      user_id: SessionData[1],
      email: email,
      subject: subject,
      message: msg,
      date: datetime,
    };
    const options = {
      method: "POST",
      headers: {
        AppKey: appKey,
        Token: SessionData[0],
        AppId: appId,
      },
      data: ApiParamForContact,
      url,
    };
    axios(options)
      .then((res) => {
        // console.log(res, "Contact");
        if (res.data.code === 200) {
          setLoader(false);
          toast.success("Form Submission Successfully", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          // props.setFeedback('YourHotel', 'Form Submission Successfully!!', true , '');
          setEmail("");
          setSubject("");
          setMsg("");
        } else {
          setLoader(false);
          toast.error("Something Went Wrong", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          // props.setFeedback('YourHotel', 'No Data Found ... ', true , '');
        }
      })
      .catch((error) => {
        setLoader(false);
        toast.error("Something Went Wrong", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        // props.setFeedback("YourHotel", "No Data Found ... ", true, "");
        // console.log("how", error);
      });
  };
  return (
    <div className="customer_account_detail">
      <h2>Contact Team</h2>
      <div className="contact_team_inner">
        <Form onSubmit={handleContact}>
          <div className="row_section">
            <InputField
              className="custom_input_field"
              DivclassName="name_input_section"
              type="email"
              placeholder="Email Address"
              icon={user_icon}
              iconClass="account_name_icons"
              required
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
            <InputField
              DivclassName="mail_input_section"
              type="text"
              placeholder="Subject"
              className="custom_input_field"
              icon={user_mail_icon}
              iconClass="account_mail_icons"
              required
              onChange={(e) => setSubject(e.target.value)}
              value={subject}
            />
          </div>
          <div className="row_section">
            <InputField
              DivclassName="message_section"
              as="textarea"
              type="text"
              placeholder="Message"
              className="custom_input_field"
              iconClass="account_mail_icons"
              required
              onChange={(e) => setMsg(e.target.value)}
              value={msg}
            />
          </div>
          <div className="update_account_div">
            <Button
              name={loader ? <Loader /> : "Submit"}
              className="update_account_btn green_btn"
              type="submit"
            />
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ContactTeam;
