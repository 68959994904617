import React, { useEffect, useState } from "react";
import "./PromoBusiness.css";
import defaultThumbnail from "../../assets/images/default_thumbnail.png";
import Header from "../../Header/Header";
import Footer from "../../footer/footer";
import TextButton from "../../component/TextButton/TextButton";
import Buttonn from "../../component/Button/Button";
import GoogleMapReact from "google-map-react";
import { useLocation } from "react-router";
import { GetBusiness } from "../../Redux/Actions/GetBusiness";
import { useDispatch, useSelector } from "react-redux";
import {
  ResetGetBusiness,
  ResetGetBusinessError,
} from "../../Redux/Actions/GetBusiness";
import Loader from "../../component/Loader/Loader";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {
  Favourite,
  ResetFavourite,
  ResetFavouriteError,
} from "../../Redux/Actions/Favourite";
import { toast } from "react-toastify";
import { BsCheck2 } from "react-icons/bs";
import axios from "axios";
import { apiActiveURL, appId, appKey } from "../../ApiBaseURL";
const PromoBusiness = (props) => {
  const AnyReactComponent = ({ text }) => <div>{text}</div>;
  const location = useLocation();
  const dispatch = useDispatch();
  // console.log(location, "promo business data");
  const data = location.state;
  // console.log(data, "data");

  const SessionData = JSON.parse(localStorage.getItem("SessionData"));

  const [servicedetail, setServiceDetail] = useState([]);
  const [featuredpromotion, setFeaturedPromotion] = useState();
  const [showCoupon, setShowCoupon] = useState("");
  const [coupon, setCoupon] = useState("");
  const [discountCoupon, setDiscountCoupon] = useState("");

  const [successCoupon, setSuccessCoupon] = useState("");

  const [address, setAddress] = useState("");
  const [servicephone, setServicePhone] = useState("");
  const [vaccine, setVaccine] = useState("");
  const [menuURL, setMenuURL] = useState("");
  const [servicelatitude, setServiceLatitude] = useState("");
  var [tableHead, setTableHeads] = useState([
    "M",
    "T",
    "W",
    "T",
    "F",
    "S",
    "S",
  ]);
  const [calendardetails, setCalendarDetails] = useState();

  const [servicelongitude, setServiceLongitude] = useState("");
  const [websiteUrl, setWebsiteUrl] = useState("");
  const [servicebookingurl, setServiceBookingURl] = useState("");
  const [showWeb, setShowWeb] = useState(false);
  const [promodetails, setPromoDetails] = useState([]);
  const [confirmationcode, setConfirmationCode] = useState("");

  const [qoinDetails, setQoinDetails] = useState("");
  const [showCoin, setShowCoin] = useState(false);
  const [visibleQoin, setVisibleQoin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [showQoin, setShowQoin] = useState(false);
  const [isRequest, setIsRequest] = useState(true);
  const [mondiscount, setMonDiscount] = useState("");
  const [fridiscount, setFriDiscount] = useState("");
  const [coupondetails, setCouponDetails] = useState({});

  const [favLoader, setFavLoader] = useState(false);
  const GetBusinessSuccess = useSelector((state) => {
    // console.log(state.GetBusiness.data, "state.GetBusiness.data");
    return state.GetBusiness.data;
  });
  const FavouriteSuccess = useSelector((state) => {
    // console.log(state?.Favourite?.data, "state.Favourite.success");

    return state.Favourite.data;
  });
  const FavouriteFail = useSelector((state) => {
    // console.log(state?.Favourite?.error?.data, "state.Favourite.error");
    return state?.Favourite?.error?.data;
  });

  const GetBusinessError = useSelector((state) => {
    return state.GetBusiness.error;
  });

  useEffect(() => {
    if (data) {
      dispatch(GetBusiness(data.id));
      setLoading(true);
    } else {
    }
  }, [data]);
  useEffect(() => {
    if (GetBusinessSuccess) {
      // console.log(first)
      setLoading(false);
      // console.log(
      //   GetBusinessSuccess.data.data.service,
      //   "if useEffect get business success"
      // );
      setServiceDetail(GetBusinessSuccess.data.data.service);

      if (GetBusinessSuccess.data.data.service.vaccine_password) {
        // console.log(
        //   GetBusinessSuccess.data.data.service.vaccine_password,
        //   "GetBusinessSuccess.data.data.service.vaccine_password"
        // );
        if (GetBusinessSuccess.data.data.service.vaccine_password == "1") {
          setVaccine(require("../../assets/images/vaccine_pass.jpg"));
        } else if (
          GetBusinessSuccess.data.data.service.vaccine_password == "2"
        ) {
          setVaccine(require("../../assets/images/vaccine_pass_cross.png"));
        } else {
          setVaccine("");
        }
      }

      let objCoupon = GetBusinessSuccess.data.data.service.meta.find(
        (o) => o.meta_key === "coupon"
      );
      let parseCoupon = JSON.parse(objCoupon.meta_value);
      let today = new Date();
      let day = today.getDay();
      let daylist = [
        "sunday",
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
      ];
      if (parseCoupon.hasOwnProperty(daylist[day])) {
        let couponpercentage = parseCoupon[daylist[day]];
        let moncouponpercentage = parseCoupon.monday;
        let fricouponpercentage = parseCoupon.friday;
        setMonDiscount(moncouponpercentage);
        setFriDiscount(fricouponpercentage);
        if (moncouponpercentage <= 0 && fricouponpercentage <= 0) {
          setIsRequest(false);
        }
        setCouponDetails({
          coupon_name: parseCoupon.coupon_name,
          coupon_details: parseCoupon.coupon_details,
          coupon_percentage: couponpercentage,
          min_amount: parseCoupon.min_amount,
        });
      }

      let showcoupon = GetBusinessSuccess.data.data.service.meta.find(
        (o) => o.meta_key === "show_coupon"
      );
      setShowCoupon(showcoupon.meta_value);

      let objServiceDetails =
        GetBusinessSuccess?.data?.data?.service?.meta.find(
          (o) => o.meta_key === "service_details"
        );
      if (objServiceDetails != undefined) {
        let parseServiceDetails = JSON.parse(objServiceDetails.meta_value);
        // console.log(parseServiceDetails, "parseServiceDetails");
        setCalendarDetails([
          [
            parseServiceDetails?.monday?.breakfast,
            parseServiceDetails?.tuesday?.breakfast,
            parseServiceDetails?.wednesday?.breakfast,
            parseServiceDetails?.thursday?.breakfast,
            parseServiceDetails?.friday?.breakfast,
            parseServiceDetails?.saturday?.breakfast,
            parseServiceDetails?.sunday?.breakfast,
          ],
          [
            parseServiceDetails?.monday?.lunch,
            parseServiceDetails?.tuesday?.lunch,
            parseServiceDetails?.wednesday?.lunch,
            parseServiceDetails?.thursday?.lunch,
            parseServiceDetails?.friday?.lunch,
            parseServiceDetails?.saturday?.lunch,
            parseServiceDetails?.sunday?.lunch,
          ],
          [
            parseServiceDetails?.monday?.dinner,
            parseServiceDetails?.tuesday?.dinner,
            parseServiceDetails?.wednesday?.dinner,
            parseServiceDetails?.thursday?.dinner,
            parseServiceDetails?.friday?.dinner,
            parseServiceDetails?.saturday?.dinner,
            parseServiceDetails?.sunday?.dinner,
          ],
          [
            parseServiceDetails?.monday?.allday,
            parseServiceDetails?.tuesday?.allday,
            parseServiceDetails?.wednesday?.allday,
            parseServiceDetails?.thursday?.allday,
            parseServiceDetails?.friday?.allday,
            parseServiceDetails?.saturday?.allday,
            parseServiceDetails?.sunday?.allday,
          ],
        ]);
      } else {
        setCalendarDetails([
          ["0", "0", "0", "0", "0", "0", "0"],
          ["0", "0", "0", "0", "0", "0", "0"],
          ["0", "0", "0", "0", "0", "0", "0"],
          ["0", "0", "0", "0", "0", "0", "0"],
        ]);
      }

      // console.log(calendardetails, "calendarDetail &&");
      /*****************   Feature Promotion Work   *********************/

      let featuredImage = GetBusinessSuccess.data.data.service.meta.find(
        (o) => o.meta_key === "image"
      );

      if (featuredImage !== undefined) {
        setFeaturedPromotion(featuredImage.meta_value);
      }
      setPromoDetails(GetBusinessSuccess.data.data.promotions);

      /*****************   Address Work   *********************/

      let serviceaddress = GetBusinessSuccess.data.data.service.meta.find(
        (o) => o.meta_key === "service_address"
      );
      setAddress(serviceaddress.meta_value);

      /*****************   Phone Work   *********************/

      let servicephone = GetBusinessSuccess.data.data.service.meta.find(
        (o) => o.meta_key === "phone"
      );
      // console.log(servicephone, "servicephone");
      if (servicephone != undefined) {
        setServicePhone(servicephone.meta_value);
      }

      //vaccine_password work
      // let vaccine_pass = GetBusinessSuccess.data.data.service.meta.find(
      //   (o) => o.meta_key === "vaccine_password"
      // );
      // console.log(vaccine_pass, "vaccine_pass");
      // if (vaccine_pass) {
      //   if (vaccine_pass.meta_value == "1") {
      //     setVaccine(require("../../assets/images/vaccine_pass.jpg"));
      //   } else if (vaccine_pass.meta_value == "0") {
      //     setVaccine(require("../../assets/images/vaccine_pass_cross.png"));
      //   } else {
      //     setVaccine("");
      //   }
      // }

      //hotel_menu_url work
      let hotel_menu_url = GetBusinessSuccess.data.data.service.meta.find(
        (o) => o.meta_key === "hotel_menu_url"
      );
      if (hotel_menu_url) {
        setMenuURL(hotel_menu_url.meta_value);
      }

      //latlong work
      let servicelatitude = GetBusinessSuccess.data.data.service.meta.find(
        (o) => o.meta_key === "service_latitude"
      );
      // console.log(servicelatitude, "servicelatitude");
      if (servicelatitude != undefined) {
        setServiceLatitude(servicelatitude.meta_value);
      } else {
        setServiceLatitude("0.000000");
      }

      let servicelongitude = GetBusinessSuccess.data.data.service.meta.find(
        (o) => o.meta_key === "service_longitude"
      );
      if (servicelongitude != undefined) {
        setServiceLongitude(servicelongitude.meta_value);
      } else {
        setServiceLongitude("0.000000");
      }

      // website_url work
      let web_url = GetBusinessSuccess.data.data.service.meta.find(
        (o) => o.meta_key === "website_url"
      );
      if (web_url != undefined) {
        setWebsiteUrl(web_url.meta_value);
      }

      let servicebookingurl = GetBusinessSuccess.data.data.service.meta.find(
        (o) => o.meta_key === "booking_url"
      );
      // console.log(servicebookingurl, "servicebookingurl");
      if (servicebookingurl != undefined) {
        setServiceBookingURl(servicebookingurl.meta_value);
      }

      // Qoin_terms_conditions work

      let qoinShow = GetBusinessSuccess.data.data.service.meta.find(
        (o) => o.meta_key === "show_qoin"
      );

      let qoinTerms = GetBusinessSuccess.data.data.service.meta.find(
        (o) => o.meta_key === "qoin_terms"
      );
      // console.log(qoinShow, "qoin showwww");
      // console.log(qoinTerms, "qoin termsssssssssss");

      if (qoinShow !== undefined) {
        if (qoinShow?.meta_value == "1") {
          setShowCoin(true);
          setQoinDetails(qoinTerms?.meta_value);
        } else {
          setShowCoin(false);
        }
      } else {
        setShowCoin(false);
      }

      //website button show

      let show_web = GetBusinessSuccess.data.data.service.meta.find(
        (o) => o.meta_key === "show_website"
      );
      // console.log(show_web, "show_web");

      if (show_web !== undefined) {
        if (show_web?.meta_value == "1") {
          setShowWeb(true);
          // setQoinDetails(qoinTerms.meta_value);
        } else {
          setShowWeb(false);
        }
      } else {
        setShowWeb(false);
      }
      // console.log(show_web, "show_web");
      setConfirmationCode(generateConfirmationCode(6));
      dispatch(ResetGetBusiness());
    } else if (GetBusinessError) {
      // console.log(signUpError, "else if useEffect sign up error");
      // setLoading(false);
      // emptyfields();
      // toast.error(signUpError, {
      //   position: "top-center",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "colored",
      // });
      // setErrMsg(true);
      dispatch(ResetGetBusinessError());
    } else {
      // setLoading(false);
      // emptyfields();
      dispatch(ResetGetBusiness());
      dispatch(ResetGetBusinessError());
    }
  }, [GetBusinessSuccess, GetBusinessError]);
  const generateConfirmationCode = (length) => {
    let confirmationcode = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (let i = 0; i < length; i++) {
      confirmationcode += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return confirmationcode;
  };
  const defaultProps = {
    center: { lat: 10.99835602, lng: 77.01502627 },
    zoom: 11,
  };

  return (
    <>
      <div className="bg_body business_bg_body">
        <Header />
        <div className="inner_pg">
          <div className="PromoBusiness">
            <div className="container">
              <div className="row">
                <div className="col-md-8 offset-md-2">
                  <h1 className="top_heading">
                    {data.name ? data.name : "Promo Business / Topic Name"}
                  </h1>
                  <div className="row align-items-center">
                    <div className="col-md-6">
                      <div
                        className="bg_img"
                        style={{
                          // backgroundImage: `url(${img_c})`,
                          backgroundImage: `url(${
                            data.image_url ? data.image_url : defaultThumbnail
                          })`,
                        }}
                      ></div>
                    </div>
                    <div className="col-md-6">
                      <div className="promo_business_content">
                        <p>
                          Lorem ipsum dolor amet, consectetur adipiscing elit.
                          Cras eget sodales odio. Phasellus erat magna, cursus
                          non nisi vitae, fringilla varius purus.
                        </p>
                        <div className="promo_business_buttons">
                          <Button name="Book Now" className="green_btn" />
                          <Button
                            name="Add To Favourites"
                            className="black_btn"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="map_section">
        <div className="map_frame_section">
          <GoogleMapReact
            defaultCenter={defaultProps?.center}
            defaultZoom={defaultProps?.zoom}
          >
            <AnyReactComponent
              lat={59.955413}
              lng={30.337844}
              text="My Marker"
            />
          </GoogleMapReact>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PromoBusiness;
