import axios from "axios";
import { apiActiveURL, appId, appKey } from "../../ApiBaseURL";

export const Logout = () => {
  const SessionData = JSON.parse(localStorage.getItem("SessionData"));
  // console.log(SessionData, "Session Data");

  var config = {
    url: `${apiActiveURL}/logout/${SessionData[1]}`,
    method: "GET",
    headers: {
      AppKey: appKey,
      Token: SessionData[0],
      AppId: appId,
    },
  };

  return (dispatch) => {
    axios(config)
      .then((res) => {
        // console.log(res.data, "logout response =======");
        // console.log(res, "hotel response");
        if (res.data.status) {
          LogoutSuccess(dispatch, res);
          localStorage.removeItem("SessionData");
        } else {
          LogoutFail(dispatch, res.data.message);
        }
      })
      .catch((e) => {
        // console.log(e, "fail");
        LogoutFail(dispatch, e.message);
      });
  };
};

export const ResetLogout = () => {
  // console.log("reset logout action called");
  return (dispatch) => {
    dispatch({ type: "LOGOUT_SUCCESS", payload: null });
  };
};

export const ResetLogoutError = () => {
  // console.log("reset logout error action called");
  return (dispatch) => {
    dispatch({ type: "LOGOUT_FAIL", payload: null });
  };
};

const LogoutSuccess = (dispatch, res) => {
  // console.log(res, "logout data");
  dispatch({ type: "LOGOUT_SUCCESS", payload: res });
};

const LogoutFail = (dispatch, res) => {
  // console.log(res, "res action");
  dispatch({ type: "LOGOUT_FAIL", payload: res });
};
