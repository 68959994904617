import React, { Fragment, useState, useRef, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Header from "../../Header/Header";
import Footer from "../../footer/footer";
import CarouselMulti from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";
import restaurantImage from "../../assets/images/restaurant_images.png";
import restaurantdefault from "../../assets/images/slider_default_img.png";
import Button from "../../component/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import carousel_img from "../../assets/images/restaurant_images.png";
import {
  Category,
  CategoryId,
  ResetCategory,
  ResetCategoryFail,
} from "../../Redux/Actions";
import Loader from "../../component/Loader/Loader";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";

import { toast } from "react-toastify";
import {
  FeaturedPromotion,
  ResetFeaturePromotionFail,
} from "../../Redux/Actions/FeaturedPromotion";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useMediaQuery } from "react-responsive";

const Categories = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  // console.log(location.state, "location cat");

  const dispatch = useDispatch();

  let carouselRef = useRef();
  let carouselTwoRef = useRef();
  let headerRef = useRef();
  let footerRef = useRef();

  const [activeId, setActiveId] = useState();
  const [loader, setLoader] = useState(true);
  const [featureLoader, setFeatureLoader] = useState(true);

  const [error, setError] = useState("");

  const [categoryData, setCategoryData] = useState([]);
  const [featurePromotion, setFeaturePromotion] = useState([]);
  const [selectedArea, setSelectedArea] = useState("");
  const [selectedSuburb, setSelectedSuburb] = useState("");

  const CategorySuccess = useSelector((state) => state?.Categories);
  const CategoryFail = useSelector((state) => state?.Categories?.error);

  // const SaveUserSuccess = useSelector((state) => {
  //   console.log(state.GetUser.data, "get user data");
  //   return state.GetUser.data;
  // });

  const SaveUserSuccess = useSelector((state) => {
    // console.log(state.GetUser.data, "Home");
    return state.GetUser.data;
  });
  const GetFeaturePromotionsSucces = useSelector((state) => {
    return state.FeaturedPromotion.data;
  });

  const GetFeaturePromotionsError = useSelector((state) => {
    return state.FeaturedPromotion.error;
  });
  const listing_type = localStorage.getItem("listingType");
  // console.log(listing_type, "Category ------- list ");

  useEffect(() => {
    if (
      SaveUserSuccess?.data?.data?.area &&
      SaveUserSuccess?.data?.data?.suburb
    ) {
      setSelectedArea(SaveUserSuccess?.data?.data?.area);
      setSelectedSuburb(SaveUserSuccess?.data?.data?.suburb);
      // setSelected(false);
    }
    //  else {
    //   setSelected(true);
    // }
  }, [SaveUserSuccess]);

  useEffect(() => {
    setLoader(true);
    setFeatureLoader(true);

    const data = {
      listingType: listing_type,
      area: SaveUserSuccess?.data?.data?.area,
      suburb: SaveUserSuccess?.data?.data?.suburb,
    };

    dispatch(Category(data));
    dispatch(FeaturedPromotion(data));
    return () => {
      // console.log(
      //   CategorySuccess,
      //   CategoryFail,
      //   GetFeaturePromotionsError,
      //   "reset category"
      // );
      dispatch(ResetCategory());
      dispatch(ResetCategoryFail());
      dispatch(ResetFeaturePromotionFail());
    };
  }, [listing_type]);

  useEffect(() => {
    if (CategorySuccess.category?.data?.code == 200) {
      setLoader(false);
      // console.log(
      //   CategorySuccess.category?.data?.data.length,
      //   "CategorySuccess.category?.data?.datas.length"
      // );
      if (CategorySuccess.category?.data?.data.length > 0) {
        // console.log(CategorySuccess.category?.data?.data, "category data");
        let sortCategories = CategorySuccess.category?.data?.data.sort(
          (a, b) => a.display_order - b.display_order
        );
        // console.log(sortCategories, "category data");
        setCategoryData(sortCategories);
        // dispatch(ResetCategory());
      } else {
        setError("No Categories Found");
      }
    } else if (CategoryFail) {
      toast.error(CategoryFail, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }

    return () => {
      // dispatch(ResetCategoryFail());
      // dispatch(ResetCategory());
    };
  }, [CategorySuccess, CategoryFail]);

  useEffect(() => {
    if (GetFeaturePromotionsSucces) {
      setFeatureLoader(false);
      // console.log(GetFeaturePromotionsSucces, "if useEffect get Area success");

      let sortedarr = Object.values(GetFeaturePromotionsSucces.data.data);

      // console.log(sortedarr, "sorted arr");
      setFeaturePromotion(sortedarr);
    } else if (GetFeaturePromotionsError) {
      setFeatureLoader(false);
      // console.log(
      //   GetFeaturePromotionsError,
      //   "else if useEffect GetAreaError error"
      // );
      // setLoading(false);
      // //  emptyfields();
      toast.error(GetFeaturePromotionsError, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      // dispatch(ResetFeaturePromotionFail());
    }

    //    setErrMsg(true);
    else {
      // dispatch(ResetFeaturePromotionFail());
      // setFeatureLoader(false);
      // setLoading(false);
    }

    return () => {
      // dispatch(ResetFeaturePromotionFail());
    };
  }, [GetFeaturePromotionsSucces, GetFeaturePromotionsError]);

  const handleFeaturePromotion = (promotion) => {
    console.log("promotion clicked", promotion);
    // const url = "https://www.google.com/";
    // window.open(url, "_blank");
    // return;
    if (promotion?.status) {
      if (!promotion.id) {
        window.open(promotion.iframe_url).then((data) => {
          if (data) {
            window.open(promotion.iframe_url);
          } else {
            // props.setFeedback("YourHotel", "Sorry, Broken Link", true, "");
            console.log("sorry broken link");
          }
        });
      } else {
        navigate("/business", {
          state: promotion,
        });
        // navigation.navigate("Promotions", {
        //   screen: "Promotion Business",
        //   params: {
        //     promoid: promotion.id,
        //     promotitle: promotion.title,
        //     screen: "promotion",
        //     couponStatus: promotion.coupon,
        //     term_conditions: promotion.term_conditions,
        //     tagline: promotion.tagline,
        //     expiry_date: promotion.expiry,
        //     coupon_details: promotion.coupon_details,
        //   },
        // });
      }
    } else {
      // props.setFeedback(
      //   "YourHotel",
      //   "Sorry, No Feature Promotions Available",
      //   true,
      //   ""
      // );
    }
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 5120, min: 1680 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 1679, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const restaurantresponsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const activeSearch = (data) => {
    // console.log(headerRef, "data in active callback");
    headerRef?.current?.activeSearchField();
  };
  useEffect(() => {
    if (headerRef) console.log(headerRef, "headerRefheaderRef");

    return () => {};
  }, [headerRef]);

  const desktopSize = useMediaQuery({ query: "(max-width: 1680px)" });
  const largeDesktopSize = useMediaQuery({ query: "(max-width: 3400px)" });
  const superLargeDesktopSize = useMediaQuery({
    query: "(max-width: 5120px)",
  });

  return (
    <Fragment>
      <div className="bg_body">
        <Header ref={headerRef} />
        <Container>
          <div className="broadbeach_casual">
            <div className="broadbeach_casual_inner">
              <h2>{location?.state}</h2>

              {loader ? (
                <div className="category_loader">
                  <Loader />
                </div>
              ) : categoryData.length > 0 ? (
                <div className="broadbeach_casual_slider">
                  <Row>
                    <Col md={8} className="offset-md-2">
                      <div className="Carousel_sec">
                        <div className="slide_button">
                          <div
                            className="previous_slide"
                            onClick={() => {
                              carouselRef.previous();
                            }}
                          >
                            <BsArrowLeft />
                          </div>
                          <div
                            className="next_slide"
                            onClick={() => {
                              carouselRef.next();
                            }}
                          >
                            <BsArrowRight />
                          </div>
                        </div>

                        <CarouselMulti
                          ref={(el) => {
                            carouselRef = el;
                          }}
                          arrows
                          swipeable={false}
                          draggable={true}
                          showDots={false}
                          responsive={responsive}
                          ssr={true}
                          infinite={true}
                          autoPlay={false}
                          autoPlaySpeed={1000}
                          keyBoardControl={true}
                          customTransition="all 0.5s"
                          transitionDuration={500}
                          containerClass="carousel-container"
                          removeArrowOnDeviceType={["tablet", "mobile"]}
                          deviceType={props.deviceType}
                          dotListClass="custom-dot-list-style"
                        >
                          {/* {categoryData.length > 0 ? ( */}
                          {listing_type == 5 ? (
                            <div
                              onClick={() => {
                                // dispatch(CategoryId(val?.id));
                                localStorage.setItem("categoryId", "All");
                                navigate("/services", {
                                  state: "All",
                                });
                                setActiveId();
                              }}
                              className={`broadbeach_casual_slider_content ${
                                activeId === "item-active"
                              }`}
                            >
                              <p>ALL</p>
                            </div>
                          ) : null}

                          {categoryData.map((val, index) => (
                            <div
                              key={index}
                              onClick={() => {
                                dispatch(CategoryId(val?.id));
                                localStorage.setItem("categoryId", val.id);
                                navigate("/child-categories", {
                                  state: location?.state,
                                });
                                setActiveId(val?.id);
                              }}
                              className={`broadbeach_casual_slider_content ${
                                activeId === val?.id ? "item-active" : ""
                              }`}
                            >
                              <p>{val?.name}</p>
                            </div>
                          ))}
                        </CarouselMulti>
                      </div>
                    </Col>
                  </Row>
                </div>
              ) : (
                <div className="category_loader text-center">{error}</div>
              )}

              <div className="feature_promotions_section">
                <div className="feature_promotions_content">
                  <p>Feature Promotions</p>
                  {/* <h3>Restaurant Name</h3> */}
                </div>
                {featureLoader ? (
                  <div className="category_loader">
                    <Loader />
                  </div>
                ) : (
                  <>
                    {" "}
                    <div className="feature_promotions_slider">
                      <Row>
                        {featurePromotion?.length == 0 ? (
                          <div className="restaurant_image">
                            <img src={restaurantdefault} alt="" />
                          </div>
                        ) : (
                          <Col
                            xl={0}
                            md={8}
                            className="offset-md-2 offset-xl-2 slider_cols"
                          >
                            {/* <div className="Carousel_sec_category_img">
                              <div className="slide_button">
                                <div
                                  className="previous_slide"
                                  onClick={() => {
                                    carouselTwoRef.previous();
                                  }}
                                >
                                  <BsArrowLeft />
                                </div>
                                <div
                                  className="next_slide"
                                  onClick={() => {
                                    carouselTwoRef.next();
                                  }}
                                >
                                  <BsArrowRight />
                                </div>
                              </div>

                              <Carousel
                                ref={(el) => {
                                  carouselTwoRef = el;
                                }}
                                arrows
                                swipeable={false}
                                draggable={true}
                                showDots={false}
                                responsive={restaurantresponsive}
                                ssr={true}
                                infinite={true}
                                autoPlay={true}
                                autoPlaySpeed={5000}
                                keyBoardControl={true}
                                customTransition="all 0.5s"
                                transitionDuration={500}
                                containerClass="carousel-container"
                                removeArrowOnDeviceType={["tablet", "mobile"]}
                                deviceType={props.deviceType}
                                dotListClass="custom-dot-list-style"
                                itemClass="carousel-item-padding-40-px"
                              >
                                {featurePromotion.map((val, index) => (
                                  <>
                                    {console.log(val, "val")}
                                    <div key={index}>
                                      <div className="feature_promotions_content">
                                        <h3>{val.name}</h3>
                                      </div>

                                      <div
                                        onClick={() => {
                                          handleFeaturePromotion(val);
                                        }}
                                        className="restaurant_image"
                                      >
                                        <div className="restaurant_bg_img">
                                          <img src={val?.image_url} />
                                          <div
                                            className="restaurant_info_para"
                                            dangerouslySetInnerHTML={{
                                              __html: val.tagline,
                                            }}
                                          ></div>

                                          <Button
                                            name="Learn More"
                                            className="learn_more_category_btn"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ))}
                              </Carousel>
                            </div> */}
                            <Carousel
                              showThumbs={false}
                              showIndicators={false}
                              swipeable={true}
                              useKeyboardArrows={true}
                              showStatus={false}
                              infiniteLoop={true}
                              renderArrowPrev={(clickHandler, hasPrev) => {
                                return (
                                  <div
                                    className={`${
                                      hasPrev ? "absolute" : "hidden"
                                    } custom_arrow_pre_slide custom_arrow_slide`}
                                    onClick={clickHandler}
                                  >
                                    <BsArrowLeft
                                      size={
                                        desktopSize
                                          ? 27
                                          : largeDesktopSize
                                          ? 45
                                          : superLargeDesktopSize
                                          ? 75
                                          : 75
                                      }
                                      color="#CAC8C8"
                                    />
                                  </div>
                                );
                              }}
                              renderArrowNext={(clickHandler, hasNext) => {
                                return (
                                  <div
                                    className={`${
                                      hasNext ? "absolute" : "hidden"
                                    } custom_arrow_next_slide custom_arrow_slide`}
                                    onClick={clickHandler}
                                  >
                                    <BsArrowRight
                                      size={
                                        desktopSize
                                          ? 27
                                          : largeDesktopSize
                                          ? 45
                                          : superLargeDesktopSize
                                          ? 75
                                          : 75
                                      }
                                      color="#CAC8C8"
                                    />
                                  </div>
                                );
                              }}
                            >
                              {featurePromotion.map((val, index) => (
                                <>
                                  {/* {console.log(val, "val")} */}
                                  <div key={index}>
                                    <div className="feature_promotions_content">
                                      <h3>{val.name}</h3>
                                    </div>

                                    <div
                                      onClick={() => {
                                        handleFeaturePromotion(val);
                                      }}
                                      className="restaurant_image"
                                    >
                                      <div className="restaurant_bg_img">
                                        <img src={val?.image_url} />
                                        <div
                                          className="restaurant_info_para"
                                          dangerouslySetInnerHTML={{
                                            __html: val.tagline,
                                          }}
                                        ></div>

                                        <Button
                                          name="Learn More"
                                          className="learn_more_category_btn"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ))}
                            </Carousel>
                          </Col>
                        )}
                      </Row>
                    </div>
                  </>
                )}
              </div>
              {/* <div className="broadbeach_casual_bottom">
                <Button
                  name="New"
                  className="broadbeach_new_btn"
                  onClick={() => {
                    localStorage.setItem("categoryId", "New");
                    navigate("/services");
                  }}
                />
                <Button
                  name="Most Popular"
                  className="broadbeach_Popular_btn"
                  onClick={() => {
                    localStorage.setItem("categoryId", "Popular");
                    navigate("/services");
                  }}
                />
              </div> */}
            </div>
          </div>
        </Container>
        <Footer ref={footerRef} activeSearch={activeSearch} />
      </div>
    </Fragment>
  );
};
export default Categories;
