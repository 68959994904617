import axios from "axios";
import React, { Component, useState } from "react";
import { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { apiActiveURL, appId, appKey } from "../../../ApiBaseURL";

const MyTransaction = () => {
  const [transaction, setTransaction] = useState("");
  const [show, setShow] = useState(false);
  const [data, setData] = useState();

  const SessionData = JSON.parse(localStorage.getItem("SessionData"));

  useEffect(() => {
    fetchTransactions();
    return () => {};
  }, []);

  const fetchTransactions = () => {
    const url = `${apiActiveURL}/get_transaction?user_id=${SessionData[1]}`;
    const options = {
      method: "GET",
      headers: {
        AppKey: appKey,
        Token: SessionData[0],
        AppId: appId,
      },
      url,
    };
    axios(options)
      .then((res) => {
        // console.log("get_transaction", res);
        // return;
        if (res.data.code === 200) {
          setTransaction(res.data.data);
          //   setLoader(false);
        }
        // else {
        //   console.log("get_transaction", res);
        //   setMsgTitle("TEAM Business Network");
        //   setMsgBody("No Data Found...");
        //   setVisible(true);
        //   setLoader(false);
        // }
      })
      .catch((error) => {
        // setMsgTitle("TEAM Business Network");
        // setMsgBody("No Data Found...");
        // setVisible(true);
        // setLoader(false);
        // console.log("get_transaction", error);
      });
  };
  const handleTransactionShow = (item) => {
    // console.log(item, "item clicked");
    setData(item);
    setShow(true);
  };
  const hideTransactionShow = () => setShow(false);
  const handledate = (date) => {
    // console.log(date, "date");
    let splitdate = date?.split(" ");
    return splitdate[0];
  };

  const handletime = (time) => {
    let splittime = time?.split(" ");
    return splittime[1];
  };

  const showTransaction = (item) => {
    // console.log(item, "itemitemitem");
    return (
      <Modal show={show} onHide={hideTransactionShow} centered>
        <Modal.Header closeButton>
          <Modal.Title>{data ? data.coupon.service_name : "title"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul>
            <li>
              <span>Date of Transaction: </span>
              {data ? handledate(data?.coupon?.created_at) : "date"}
              {/* {data ? data.coupon.created_at : "date"} */}
            </li>
          </ul>

          <ul>
            <li>
              <span>Time of Transaction: </span>
              {data ? handletime(data?.coupon?.created_at) : "time"}
              {/* {data
                ? moment(data.coupon.created_at).format("h:mm:ss a")
                : "time"} */}
            </li>
          </ul>

          <ul>
            <li>
              <span>Gross Value of transaction: </span>
              {data ? data.discounted_amount : "Gross Value"}
            </li>
          </ul>

          <ul>
            <li>
              <span>Net Amount of transaction: </span>
              {data ? data.payable_amount : "coupon"}
            </li>
          </ul>
          <ul>
            <li>
              <span>Verification Code: </span>
              {data ? data.coupon.confirmation_code : "coupon"}
            </li>
          </ul>

          <ul>
            <li>
              <span>You saved $: </span>
              {data
                ? (data.payable_amount - data.discounted_amount).toFixed(2)
                : "Saved"}
            </li>
          </ul>
        </Modal.Body>
      </Modal>
    );
  };
  return (
    <div className="row">
      {showTransaction()}
      {transaction &&
        transaction.map((item, index) => (
          <>
            <div className="col-lg-4 col-md-6">
              <div
                className="child_category_box"
                style={{
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                  justifyContent: "center",
                }}
                onClick={() => {
                  handleTransactionShow(item);
                }}
              >
                <div className="child_category_box_inner">
                  <h2 className="">{item.coupon.service_name}</h2>
                </div>
              </div>
            </div>
          </>
        ))}
    </div>
  );
};
export default MyTransaction;
