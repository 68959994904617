import axios from "axios";
import { apiActiveURL, appId, appKey } from "../../ApiBaseURL";

export const Search = (data) => {
  const SessionData = JSON.parse(localStorage.getItem("SessionData"));
  // console.log(data, SessionData, "location data");
  //   return;

  var config = {
    url: `${apiActiveURL}/search_business?listing_type=${
      data.listingType
    }&area=${data?.area}&subrub=${
      data?.suburb == "All" ? "" : data?.suburb
    }&category_id=${data?.categoryId}&service_id=${data?.serviceId}&q=${
      data?.query
    }`,
    method: "GET",
    headers: {
      AppKey: appKey,
      Token: SessionData[0],

      AppId: appId,
    },
    data: data,
  };

  return (dispatch) => {
    axios(config)
      .then((res) => {
        // console.log(res, "Search response =======");
        // console.log(res, "hotel response");
        if (res.data.status) {
          SearchSuccess(dispatch, res);
        } else {
          SearchFail(dispatch, res.data.message);
        }
      })
      .catch((e) => {
        // console.log(e, "fail");
        SearchFail(dispatch, e.message);
      });
  };
};

export const ResetSearch = () => {
  // console.log("reset sign up action called");
  return (dispatch) => {
    dispatch({ type: "SEARCH", payload: null });
  };
};

export const ResetSearchError = () => {
  // console.log("reset sign up error action called");
  return (dispatch) => {
    dispatch({ type: "SEARCH_FAIL", payload: null });
  };
};

const SearchSuccess = (dispatch, res) => {
  // console.log(res, "sign up data");
  dispatch({ type: "SEARCH", payload: res });
};

const SearchFail = (dispatch, res) => {
  // console.log(res, "res action");
  dispatch({ type: "SEARCH_FAIL", payload: res });
};
