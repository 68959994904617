import React from "react";
import { useNavigate } from "react-router-dom";
import home_img from "../../assets/images/home_img.png";
import home_logo from "../../assets/images/homelogo.png";
import your_gateway from "../../assets/images/connecting.png";
import Button from "../../component/Button/Button";

const Main = (props) => {
  let navigate = useNavigate();

  return (
    <>
      <div className="bg_body">
        <div className="inner_body">
          <div className="container">
            <div className="home_main">
              <div className="home_header_section">
                <img className="home-logo" src={home_logo} />
                <div className="home_header_content">
                  <img className="gate_way" src={your_gateway} />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="img_div home_center_img">
                    <img src={home_img} />
                  </div>
                </div>
              </div>
              <div className="home_bottom">
                <Button
                  name="Create An Account"
                  className="create_account_btn"
                  onClick={() => navigate("/create-account")}
                />
                <Button
                  name="Sign in"
                  className="sign_in_btn"
                  onClick={() => navigate("/login-account")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Main;
