import axios from "axios";
import { apiActiveURL, appId, appKey } from "../../ApiBaseURL";

export const FeaturedPromotion = (data) => {
  const SessionData = JSON.parse(localStorage.getItem("SessionData"));
  // const subrub = localStorage.getItem("subrub");
  // const area = localStorage.getItem("area");

  var config = {
    url: `${apiActiveURL}/feature_promotion?area=${data.area}&subrub=${data.suburb}&listing_type=${data.listingType}`,
    method: "GET",
    headers: {
      AppKey: appKey,
      Token: SessionData[0],
      AppId: appId,
    },
  };

  return (dispatch) => {
    axios(config)
      .then((res) => {
        // console.log(res, "Feature promotion response");

        if (res.data.status) {
          FeaturePromotionSuccess(dispatch, res);
        } else {
          FeaturePromotionFail(dispatch, res.data.message);
        }
      })
      .catch((e) => FeaturePromotionFail(dispatch, e.message));
  };
};

export const ResetFeaturePromotion = () => {
  return (dispatch) => {
    dispatch({ type: "FEATURE_PROMOTION_SUCCESS", payload: null });
  };
};

const FeaturePromotionSuccess = (dispatch, res) => {
  //console.log(res, 'post data res');
  dispatch({ type: "FEATURE_PROMOTION_SUCCESS", payload: res });
};

const FeaturePromotionFail = (dispatch, res) => {
  // console.log(res, "res error");
  dispatch({ type: "FEATURE_PROMOTION_FAIL", payload: res });
};
export const ResetFeaturePromotionFail = () => {
  return (dispatch) => {
    dispatch({ type: "FEATURE_PROMOTION_FAIL", payload: null });
  };
};
