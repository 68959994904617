import React, { useEffect, useState } from "react";
import Footer from "../../footer/footer";
import Header from "../../Header/Header";
import ChildCategoryBox from "../../component/ChildCategoryBox/ChildCategoryBox";
import { useDispatch, useSelector } from "react-redux";
import {
  ResetSearch,
  ResetSearchError,
  Search,
} from "../../Redux/Actions/Search";
import Button from "../../component/Button/Button";

// import { toast } from "react-toastify";
import Loader from "../../component/Loader/Loader";

const servicePerPage = 6;
const SearchBusiness = (props) => {
  const [searchData, setSearchData] = useState(null);
  const [loader, setLoader] = useState(true);
  const [error, setError] = useState("");
  const [next, setNext] = useState(servicePerPage);

  const dispatch = useDispatch();
  //   const navigate = useNavigate();

  const SearchSuccess = useSelector((state) => {
    // console.log(state?.Search?.data, "state?.Search?.data");
    return state?.Search?.data;
  });

  useEffect(() => {
    if (SearchSuccess?.data?.code == 200) {
      setLoader(false);
      // if(SearchSuccess?.data?.data?.length !=0)
      // console.log(SearchSuccess.data.data, "SearchSuccess.data.data");
      var arr = Object.values(SearchSuccess.data.data);
      setSearchData(arr);
    }
    return () => {
      dispatch(ResetSearch());
      dispatch(ResetSearchError());
      //   dispatch(ResetSeasrchError());
    };
  }, [SearchSuccess]);

  // useEffect(() => {
  //   if (SearchSuccess?.data?.data?.data?.code == 200) {
  //     console.log('here 2')
  //     setLoader(false);
  //     console.log(ServiceSuccess.service.data, "service data");

  //     // if (ServiceSuccess.service?.data?.data.length > 0) {
  //     //   setServiceData(
  //     //     ServiceSuccess?.service?.data?.data.sort((a, b) =>
  //     //       a.title.localeCompare(b.title)
  //     //     )
  //     //   );
  //     //   // dispatch(ResetCategory());
  //     // } else {
  //     //   setError("No Service Found");
  //     // }
  //   }
  //   else if (ServiceFail) {
  //     toast.error(ServiceFail, {
  //       position: "top-center",
  //       autoClose: 3000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "colored",
  //     });

  //   }
  // }, [ServiceSuccess, ServiceFail]);

  const handleShowMorePosts = () => {
    setNext(next + servicePerPage);
  };

  return (
    <>
      <div className="bg_body">
        <Header />
        <div className="ChildCategory business_listing_main">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 offset-xl-0 col-lg-10 offset-lg-1 col-md-8 offset-md-2">
                <h1>Services</h1>
                {loader ? (
                  <div>
                    <Loader />
                  </div>
                ) : searchData.length > 0 ? (
                  <div className="row">
                    {searchData.slice(0, next).map((item, index) => (
                      <ChildCategoryBox
                        key={index}
                        i={index}
                        title={item.title}
                        text={item.short_description}
                        data={item}
                        businessimage={item.image}
                      />
                    ))}
                  </div>
                ) : (
                  <div className="service_loader text-center">
                    <p>No Search Found </p>
                  </div>
                )}
                {next < searchData?.length && (
                  <div className="btn_div">
                    <Button
                      className="black_btn"
                      name="Load More"
                      onClick={handleShowMorePosts}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default SearchBusiness;
