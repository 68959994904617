import axios from "axios";
import { apiActiveURL, appId, appKey } from "../../ApiBaseURL";

export const FetchSuburb = (areaid) => {
  const SessionData = JSON.parse(localStorage.getItem("SessionData"));

  var config = {
    url: `${apiActiveURL}/get_subrubs/${areaid}`,
    method: "GET",
    headers: {
      AppKey: appKey,
      Token: SessionData[0],
      AppId: appId,
    },
  };

  return (dispatch) => {
    axios(config)
      .then((res) => {
        // console.log(res, "fetch suburb response");
        if (res.data.status) {
          FetchSuburbSuccess(dispatch, res);
        } else {
          FetchSuburbFail(dispatch, res.data.message);
        }
        // if (res.data.code === 401) {
        //   console.log("FetchAreaFail");
        //   FetchAreaFail(dispatch);
        // } else {
        //   FetchAreaSuccess(dispatch, res);
        // }
      })
      .catch((e) => {
        FetchSuburbFail(dispatch, e.message);
      });
  };
};

export const ResetFetchSuburb = () => {
  return (dispatch) => {
    dispatch({ type: "FETCH_SUBURB_SUCCESS", payload: null });
  };
};

export const ResetFetchSuburbError = () => {
  return (dispatch) => {
    dispatch({ type: "FETCH_SUBURB_FAIL", payload: null });
  };
};

const FetchSuburbSuccess = (dispatch, res) => {
  //console.log(res, 'post data res');
  dispatch({ type: "FETCH_SUBURB_SUCCESS", payload: res });
};

const FetchSuburbFail = (dispatch, res) => {
  // console.log(res, "suburb fail");
  dispatch({ type: "FETCH_SUBURB_FAIL", payload: res });
};
