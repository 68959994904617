import axios from "axios";
import { apiActiveURL, appId, appKey } from "../../ApiBaseURL";

export const ChildCategory = (data) => {
  const SessionData = JSON.parse(localStorage.getItem("SessionData"));
  // const subrub = localStorage.getItem("subrub");
  // const area = localStorage.getItem("area");
  // console.log(data, "catId catId catId");
  // return
  // console.log(data, "child cata ddata");
  var config = {
    url: `${apiActiveURL}/categories/${data.catId}?area=${data.area}&subrub=${data.suburb}&listing_type=${data?.listingType}`,
    method: "GET",
    headers: {
      AppKey: appKey,
      Token: SessionData[0],
      AppId: appId,
    },
  };
  // console.log(config.url, "config.url");

  return (dispatch) => {
    axios(config)
      .then((res) => {
        // console.log(res, "Child Category response");

        if (res.data.status) {
          //   console.log("kasdmaskjdsak");
          ChildCategorySuccess(dispatch, res);
        } else {
          ChildCategoryFail(dispatch, res.data.message);
          //   ChildCategorySuccess(dispatch, res);
        }
      })
      .catch((e) => ChildCategoryFail(dispatch, e.message));
  };
};

export const ResetChildCategory = () => {
  return (dispatch) => {
    dispatch({ type: "CHILD_CATEGORY_SUCCESS", payload: null });
  };
};
export const ServiceId = (res) => {
  // console.log(res, "SERVICE data res");
  return (dispatch) => {
    dispatch({ type: "SERVICE_ID", payload: res });
  };
};

const ChildCategorySuccess = (dispatch, res) => {
  //console.log(res, 'post data res');
  dispatch({ type: "CHILD_CATEGORY_SUCCESS", payload: res });
};

const ChildCategoryFail = (dispatch, res) => {
  // console.log(res, " Child res error");
  dispatch({ type: "CHILD_CATEGORY_FAIL", payload: res });
};

export const ResetChildCategoryFail = () => {
  return (dispatch) => {
    dispatch({ type: "CHILD_CATEGORY_FAIL", payload: null });
  };
};
