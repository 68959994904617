import React, { useState, useEffect } from "react";
import { Container, Form } from "react-bootstrap";
import header_img from "../../assets/images/verticallogo.png";
import user_icon from "../../assets/images/user_icon.png";
import confirm_mail_icon from "../../assets/images/confirm_mail_icon.png";
import user_mail_icon from "../../assets/images/user_mail_icon.png";
import Button from "../../component/Button/Button";
import InputField from "../../component/InputText/InputText";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { ResetLogin, ResetLoginError } from "../../Redux/Actions";
import { Login } from "../../Redux/Actions";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "../../component/Loader/Loader";
import { toast } from "react-toastify";
import axios from "axios";
import { apiActiveURL } from "../../ApiBaseURL";

const PasswordReset = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showconfirmPassword, setShowConfirmPassword] = useState(false);
  const [loader, setLoader] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");

  console.log(location, "location--reset--password");

  const authenticate = (password, cpassword) => {
    if (password == cpassword) {
      const url = `${apiActiveURL}/change_password`;
      setLoader(true);
      axios
        .post(url, {
          email: location?.state?.email,
          password: password,
          code: location?.state?.code,
        })
        .then(function (response) {
          if (response?.data?.code == 200) {
            toast.success("Password Reset!", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            navigate("/login-account");
            console.log(response, "email sent successfully");
          } else {
            toast.error(response?.data?.message, {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            navigate("/create-account");
          }
          setLoader(false);
        })
        .catch(function (error) {
          setLoader(false);
          console.log(error);
          toast.error("Something Went Wrong", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
    } else {
      toast.error("Passwords Don't Match", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  return (
    <div className="bg_body">
      <Container>
        <div className="account_forgot_username">
          <div className="account_forgot_username_inner">
            <img className="account_header_logo" src={header_img} alt="" />
            <h3>Forgot Your Password? No Issues, Just</h3>
            <div className="creat_account_fields">
              <Form>
                <div className="row_section">
                  <div className="password_section">
                    <InputField
                      DivclassName="creation_input_section"
                      type={showPassword ? "text" : "password"}
                      placeholder="Rest Password"
                      className="custom_input_field"
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                      value={password}
                      required
                    />
                    {showPassword ? (
                      <FaEyeSlash onClick={() => setShowPassword(false)} />
                    ) : (
                      <FaEye onClick={() => setShowPassword(true)} />
                    )}
                  </div>
                </div>
                <div className="row_section">
                  <div className="password_section">
                    <InputField
                      DivclassName="creation_input_section"
                      type={showconfirmPassword ? "text" : "password"}
                      placeholder="Rest Confirm Password"
                      className="custom_input_field"
                      onChange={(e) => {
                        setConfirmPassword(e.target.value);
                      }}
                      value={confirmPassword}
                      required
                    />
                    {showconfirmPassword ? (
                      <FaEyeSlash
                        onClick={() => setShowConfirmPassword(false)}
                      />
                    ) : (
                      <FaEye onClick={() => setShowConfirmPassword(true)} />
                    )}
                  </div>
                </div>

                <p style={{ textAlign: "center", color: "#ff3333" }}>{error}</p>
                {loader ? (
                  <Button
                    name={<Loader />}
                    className="sign_up_btn"
                    type="submit"
                  />
                ) : (
                  <>
                    {/* <Button
                      onClick={() => authenticate(password, confirmPassword)}
                      name="Password Reset"
                      className="sign_up_btn"
                    /> */}
                    <button
                      onClick={() => authenticate(password, confirmPassword)}
                      className="sign_up_btn"
                    >
                      Reset Your Password
                    </button>
                  </>
                )}
              </Form>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default PasswordReset;
