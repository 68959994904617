import React from "react";
import Button from "../../component/Button/Button";
import VouchersBox from "../../component/VouchersBox/VouchersBox";
import Footer from "../../footer/footer";
import Header from "../../Header/Header";
import "./MyPassCoupons.css";

const voucher_text = [
  {
    voucher_tile: "Restaurants & Eateries",
    route: "/list-pass-coupons",
    listingtype: "5",
  },
  {
    voucher_tile: "Experiences",
    route: "/list-pass-coupons",
    listingtype: "6",
  },
  {
    voucher_tile: "Others",
    route: "/list-pass-coupons",
    listingtype: "9",
  },
  // {
  //   voucher_tile: "Voucher or Coupon Name",
  // },
  // {
  //   voucher_tile: "Voucher or Coupon Name",
  // },
  // {
  //   voucher_tile: "Voucher or Coupon Name",
  // },
  // {
  //   voucher_tile: "Voucher or Coupon Name",
  // },
  // {
  //   voucher_tile: "Voucher or Coupon Name",
  // },
  // {
  //   voucher_tile: "Voucher or Coupon Name",
  // },
  // {
  //   voucher_tile: "Voucher or Coupon Name",
  // },
];
const MyPassCoupons = (props) => {
  return (
    <>
      <div className="bg_body">
        <Header />
        <div className="inner_pg">
          <div className="voucherscoupons">
            <div className="container">
              <div className="row">
                <div className="col-md-8 offset-md-2">
                  <h1 className="top_heading">My 360 Pass and Coupons</h1>
                  <div className="row">
                    {voucher_text.map((item) => (
                      <VouchersBox
                        title={item.voucher_tile}
                        route={item.route}
                        listingType={item.listingtype}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default MyPassCoupons;
