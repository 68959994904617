import React, { Fragment, useState, useRef, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Header from "../../Header/Header";
import Footer from "../../footer/footer";
import CarouselMulti from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";
import restaurantImage from "../../assets/images/restaurant_images.png";
import restaurantdefault from "../../assets/images/slider_default_img.png";
import Button from "../../component/Button/Button";
import { useDispatch, useSelector } from "react-redux";
// import { Category } from "../../Redux/Actions";
import Loader from "../../component/Loader/Loader";
import { useLocation } from "react-router";
import {
  ChildCategory,
  ResetChildCategory,
  ResetChildCategoryFail,
  ServiceId,
} from "../../Redux/Actions/ChildCategories";
import {
  FeaturedPromotion,
  ResetFeaturePromotionFail,
} from "../../Redux/Actions/FeaturedPromotion";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { useMediaQuery } from "react-responsive";
import { Carousel } from "react-responsive-carousel";

const ChildCategories = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let carouselRef = useRef();
  let carouselTwoRef = useRef();
  let headerRef = useRef();
  let footerRef = useRef();

  const [activeId, setActiveId] = useState();
  const [loader, setLoader] = useState(true);
  const [featureLoader, setFeatureLoader] = useState(true);

  const [error, setError] = useState("");

  const [categoryData, setCategoryData] = useState([]);
  const [featurePromotion, setFeaturePromotion] = useState([]);
  const SaveUserSuccess = useSelector((state) => {
    return state.GetUser.data;
  });
  const ChildCategorySuccess = useSelector((state) => state?.ChildCategories);
  const ChildCategoryFail = useSelector(
    (state) => state?.ChildCategories?.error
  );

  const CategoryId = useSelector((state) => state?.Categories?.category_id);

  const GetFeaturePromotionsSucces = useSelector((state) => {
    return state.FeaturedPromotion.data;
  });

  const GetFeaturePromotionsError = useSelector((state) => {
    return state.FeaturedPromotion.error;
  });
  const listing_type = localStorage.getItem("listingType");
  const activeSearch = (data) => {
    // console.log(headerRef, "data in active callback");
    headerRef?.current?.activeSearchField();
  };
  useEffect(() => {
    if (headerRef) console.log(headerRef, "headerRefheaderRef");

    return () => {};
  }, [headerRef]);
  useEffect(() => {
    return () => {
      dispatch(ResetChildCategory());
      dispatch(ResetChildCategoryFail());
      dispatch(ResetFeaturePromotionFail());
      // console.log(
      //   ChildCategorySuccess,
      //   ChildCategoryFail,
      //   GetFeaturePromotionsError,
      //   "reset child category"
      // );
    };
  }, [listing_type]);

  useEffect(() => {
    setLoader(true);
    setFeatureLoader(true);
    // console.log(
    //   SaveUserSuccess?.data?.data?.suburb,
    //   "SaveUserSuccess?.data?.data?.suburb child"
    // );
    const data = {
      catId: CategoryId ? CategoryId : localStorage.getItem("categoryId"),
      listingType: listing_type,
      area: SaveUserSuccess?.data?.data?.area,
      suburb: SaveUserSuccess?.data?.data?.suburb,
    };
    if (data?.catId) {
      dispatch(ChildCategory(data));
    } else {
      // console.log("no id");
    }
    dispatch(FeaturedPromotion(data));
  }, [CategoryId]);

  useEffect(() => {
    if (GetFeaturePromotionsSucces) {
      setFeatureLoader(false);
      let sortedarr = Object.values(GetFeaturePromotionsSucces.data.data);

      // console.log(sortedarr, "sorted arr");
      setFeaturePromotion(sortedarr);
    } else if (GetFeaturePromotionsError) {
      setFeatureLoader(false);
      // console.log(
      //   GetFeaturePromotionsError,
      //   "else if useEffect GetAreaError error"
      // );
      // setLoading(false);
      // //  emptyfields();
      toast.error(GetFeaturePromotionsError, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      // dispatch(ResetFeaturePromotionFail());
    }

    //    setErrMsg(true);
    else {
      // dispatch(ResetFeaturePromotionFail());
      // setFeatureLoader(false);
      // setLoading(false);
    }

    return () => {
      // dispatch(ResetFeaturePromotionFail());
    };
  }, [GetFeaturePromotionsSucces, GetFeaturePromotionsError]);

  const handleFeaturePromotion = (promotion) => {
    // console.log("promotion clicked", promotion);
    // navigate("/business", {
    //   state: promotion,
    // });

    if (promotion?.status) {
      if (!promotion.id) {
        window.open(promotion.iframe_url).then((data) => {
          if (data) {
            window.open(promotion.iframe_url);
          } else {
            // props.setFeedback("YourHotel", "Sorry, Broken Link", true, "");
            console.log("sorry broken link");
          }
        });
      } else {
        // handleAnalytics('Promotion_Page', {
        //   promoName: promotion?.title,
        //   promoid: promotion?.id,
        // });

        // console.log(promotion.tagline, "promotion----------------------");
        navigate("/business", {
          state: promotion,
        });
        // navigation.navigate("Promotions", {
        //   screen: "Promotion Business",
        //   params: {
        //     promoid: promotion.id,
        //     promotitle: promotion.title,
        //     screen: "promotion",
        //     couponStatus: promotion.coupon,
        //     term_conditions: promotion.term_conditions,
        //     tagline: promotion.tagline,
        //     expiry_date: promotion.expiry,
        //     coupon_details: promotion.coupon_details,
        //   },
        // });
      }
    } else {
      // props.setFeedback(
      //   "YourHotel",
      //   "Sorry, No Feature Promotions Available",
      //   true,
      //   ""
      // );
    }
  };

  useEffect(() => {
    if (ChildCategorySuccess?.childcategory?.data.code == 200) {
      setLoader(false);
      if (ChildCategorySuccess.childcategory?.data?.data.length > 0) {
        // setCategoryData(ChildCategorySuccess.childcategory?.data?.data);

        let sortCategories =
          ChildCategorySuccess.childcategory?.data?.data.sort(
            (a, b) => a.display_order - b.display_order
          );
        // console.log(sortCategories, "category data");
        setCategoryData(sortCategories);
        // dispatch(ResetCategory());
      } else {
        setError("No Categories Found");
      }
    } else if (ChildCategoryFail) {
      // console.log(ChildCategoryFail, "ChildCategoryFail");
      toast.error(ChildCategoryFail, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      // dispatch(ResetChildCategoryFail());
    }
  }, [ChildCategorySuccess, ChildCategoryFail]);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 5120, min: 1680 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 1679, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const restaurantresponsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 5120, min: 1680 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 1679, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const desktopSize = useMediaQuery({ query: "(max-width: 1680px)" });
  const largeDesktopSize = useMediaQuery({ query: "(max-width: 3400px)" });
  const superLargeDesktopSize = useMediaQuery({
    query: "(max-width: 5120px)",
  });

  return (
    <Fragment>
      <div className="bg_body">
        <Header ref={headerRef} />
        <Container>
          <div className="broadbeach_casual">
            <div className="broadbeach_casual_inner">
              <h2>{location.state}</h2>
              {loader ? (
                <div className="category_loader">
                  <Loader />
                </div>
              ) : categoryData.length > 0 ? (
                <div className="broadbeach_casual_slider">
                  <Row>
                    <Col md={8} className="offset-md-2">
                      <div className="Carousel_sec">
                        <div className="slide_button">
                          <div
                            className="previous_slide"
                            onClick={() => {
                              carouselRef.previous();
                            }}
                          >
                            <BsArrowLeft />
                          </div>
                          <div
                            className="next_slide"
                            onClick={() => {
                              carouselRef.next();
                            }}
                          >
                            <BsArrowRight />
                          </div>
                        </div>
                        {/* {loader ? (
                      <Loader />
                    ) : ( */}
                        <CarouselMulti
                          ref={(el) => {
                            carouselRef = el;
                          }}
                          arrows
                          swipeable={false}
                          draggable={true}
                          showDots={false}
                          responsive={responsive}
                          ssr={true}
                          infinite={true}
                          autoPlay={false}
                          autoPlaySpeed={1000}
                          keyBoardControl={true}
                          customTransition="all 0.5s"
                          transitionDuration={500}
                          containerClass="carousel-container"
                          removeArrowOnDeviceType={["tablet", "mobile"]}
                          deviceType={props.deviceType}
                          dotListClass="custom-dot-list-style"
                        >
                          {categoryData &&
                            categoryData.map((val) => (
                              <>
                                {/* {console.log(val, "val--category--box--val")} */}
                                <div
                                  onClick={() => {
                                    setActiveId(val?.id);
                                    dispatch(ServiceId(val.id));
                                    localStorage.setItem("categoryId", val.id);
                                    navigate("/services", { state: val?.name });
                                  }}
                                  className={`broadbeach_casual_slider_content ${
                                    activeId === val?.id ? "item-active" : ""
                                  }`}
                                >
                                  <p>{val?.name}</p>
                                </div>
                              </>
                            ))}
                        </CarouselMulti>
                      </div>
                    </Col>
                  </Row>
                </div>
              ) : (
                <div className="category_loader text-center">{error}</div>
              )}

              <div className="feature_promotions_section">
                <div className="feature_promotions_content">
                  <p>Feature Promotions</p>
                </div>
                {featureLoader ? (
                  <div className="category_loader">
                    <Loader />
                  </div>
                ) : (
                  <>
                    {" "}
                    <div className="feature_promotions_slider">
                      <Row>
                        {featurePromotion?.length == 0 ? (
                          <div className="restaurant_image">
                            <img src={restaurantdefault} alt="" />
                          </div>
                        ) : (
                          <Col
                            xl={0}
                            md={8}
                            className="offset-md-2 offset-xl-2 slider_cols"
                          >
                            {/* <div className="Carousel_sec_category_img">
                              <div className="slide_button">
                                <div
                                  className="previous_slide"
                                  onClick={() => {
                                    carouselTwoRef.previous();
                                  }}
                                >
                                  <BsArrowLeft />
                                </div>
                                <div
                                  className="next_slide"
                                  onClick={() => {
                                    carouselTwoRef.next();
                                  }}
                                >
                                  <BsArrowRight />
                                </div>
                              </div>
                              <Carousel
                                ref={(el) => {
                                  carouselTwoRef = el;
                                }}
                                arrows
                                swipeable={false}
                                draggable={true}
                                showDots={false}
                                responsive={restaurantresponsive}
                                ssr={true}
                                infinite={true}
                                autoPlay={true}
                                autoPlaySpeed={5000}
                                keyBoardControl={true}
                                customTransition="all 0.5s"
                                transitionDuration={500}
                                containerClass="carousel-container"
                                removeArrowOnDeviceType={["tablet", "mobile"]}
                                deviceType={props.deviceType}
                                dotListClass="custom-dot-list-style"
                                itemClass="carousel-item-padding-40-px"
                              >
                                {featurePromotion.map((val) => (
                                  <div>
                                    <div className="feature_promotions_content">
                                      <h3>{val.name}</h3>
                                    </div>

                                    <div
                                      onClick={() => {
                                        handleFeaturePromotion(val);
                                      }}
                                      className="restaurant_image"
                                    >
                                      <div className="restaurant_bg_img">
                                        <img src={val?.image_url} alt="" />
                                        <div
                                          className="restaurant_info_para"
                                          dangerouslySetInnerHTML={{
                                            __html: val.tagline,
                                          }}
                                        ></div>

                                        <Button
                                          name="Learn More"
                                          className="learn_more_category_btn"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </Carousel>
                            </div> */}

                            <Carousel
                              showThumbs={false}
                              showIndicators={false}
                              swipeable={true}
                              useKeyboardArrows={true}
                              showStatus={false}
                              infiniteLoop={true}
                              renderArrowPrev={(clickHandler, hasPrev) => {
                                return (
                                  <div
                                    className={`${
                                      hasPrev ? "absolute" : "hidden"
                                    } custom_arrow_pre_slide custom_arrow_slide`}
                                    onClick={clickHandler}
                                  >
                                    <BsArrowLeft
                                      size={
                                        desktopSize
                                          ? 27
                                          : largeDesktopSize
                                          ? 45
                                          : superLargeDesktopSize
                                          ? 75
                                          : 75
                                      }
                                      color="#CAC8C8"
                                    />
                                  </div>
                                );
                              }}
                              renderArrowNext={(clickHandler, hasNext) => {
                                return (
                                  <div
                                    className={`${
                                      hasNext ? "absolute" : "hidden"
                                    } custom_arrow_next_slide custom_arrow_slide`}
                                    onClick={clickHandler}
                                  >
                                    <BsArrowRight
                                      size={
                                        desktopSize
                                          ? 27
                                          : largeDesktopSize
                                          ? 45
                                          : superLargeDesktopSize
                                          ? 75
                                          : 75
                                      }
                                      color="#CAC8C8"
                                    />
                                  </div>
                                );
                              }}
                            >
                              {featurePromotion.map((val, index) => (
                                <>
                                  {/* {console.log(val, "val")} */}
                                  <div key={index}>
                                    <div className="feature_promotions_content">
                                      <h3>{val.name}</h3>
                                    </div>

                                    <div
                                      onClick={() => {
                                        handleFeaturePromotion(val);
                                      }}
                                      className="restaurant_image"
                                    >
                                      <div className="restaurant_bg_img">
                                        <img src={val?.image_url} />
                                        <div
                                          className="restaurant_info_para"
                                          dangerouslySetInnerHTML={{
                                            __html: val.tagline,
                                          }}
                                        ></div>

                                        <Button
                                          name="Learn More"
                                          className="learn_more_category_btn"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ))}
                            </Carousel>
                          </Col>
                        )}
                      </Row>
                    </div>
                  </>
                )}
              </div>
              {/* <div className="broadbeach_casual_bottom">
                <Button
                  name="New"
                  className="broadbeach_new_btn"
                  onClick={() => {
                    localStorage.setItem("categoryId", "New");
                    navigate("/services");
                  }}
                />
                <Button
                  name="Most Popular"
                  className="broadbeach_Popular_btn"
                  onClick={() => {
                    localStorage.setItem("categoryId", "Popular");
                    navigate("/services");
                  }}
                />
              </div> */}
            </div>
          </div>
        </Container>
        <Footer ref={footerRef} activeSearch={activeSearch} />
      </div>
    </Fragment>
  );
};
export default ChildCategories;
