import axios from "axios";
import { apiActiveURL, appId, appKey } from "../../ApiBaseURL";

export const GetUser = () => {
  const SessionData = JSON.parse(localStorage.getItem("SessionData"));
  //   console.log() SessionData, "location data");
  //   return;

  //   var config = {
  //     url: `${apiActiveURL}/save/locationn`,
  //     method: "POST",
  //     headers: {
  //       AppKey: appKey,
  //       Token: SessionData[0],

  //       AppId: appId,
  //     },
  //     data: data,
  //   };
  var config = {
    url: `${apiActiveURL}/get_user/${SessionData[1]}`,
    method: "GET",
    headers: {
      AppKey: appKey,
      Token: SessionData[0],
      AppId: appId,
    },
    //  data: data,
  };

  //   const url = `${apiActiveURL}/get_user/${props.userid}`;
  //   const options = {
  //     method: "GET",
  //     headers: {
  //       AppKey: appKey,
  //       Token: props.token,
  //       AppId: appId,
  //     },
  //     url,
  //   };

  return (dispatch) => {
    axios(config)
      .then((res) => {
        // console.log(res, "get user response =======");
        // console.log(res, "hotel response");
        if (res.data.status) {
          GetUserSuccess(dispatch, res);
        } else {
          GetUserFail(dispatch, res.data.message);
        }
      })
      .catch((e) => {
        // console.log(e, "fail");
        GetUserFail(dispatch, e.message);
      });
  };
};

export const ResetGetUser = () => {
  // console.log("reset sign up action called");
  return (dispatch) => {
    dispatch({ type: "GET_USER_SUCCESS", payload: null });
  };
};

export const ResetGetUserError = () => {
  // console.log("reset sign up error action called");
  return (dispatch) => {
    dispatch({ type: "GET_USER_FAIL", payload: null });
  };
};

const GetUserSuccess = (dispatch, res) => {
  // console.log(res, "sign up data");
  dispatch({ type: "GET_USER_SUCCESS", payload: res });
};

const GetUserFail = (dispatch, res) => {
  // console.log(res, "res action");
  dispatch({ type: "GET_USER_FAIL", payload: res });
};
