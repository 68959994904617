import React, { useState, useEffect } from "react";
import { Container, Form } from "react-bootstrap";
import header_img from "../../assets/images/verticallogo.png";
import user_icon from "../../assets/images/user_icon.png";
import confirm_mail_icon from "../../assets/images/confirm_mail_icon.png";
import user_mail_icon from "../../assets/images/user_mail_icon.png";
import Button from "../../component/Button/Button";
import InputField from "../../component/InputText/InputText";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { ResetLogin, ResetLoginError } from "../../Redux/Actions";
import { Login } from "../../Redux/Actions";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "../../component/Loader/Loader";
import { toast } from "react-toastify";
import { apiActiveURL } from "../../ApiBaseURL";
import axios from "axios";

const ConfirmCode = () => {
  const location = useLocation();
  console.log(location, "location--location");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [confirmcode, setConfirmCode] = useState("");
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState("");

  const authenticate = (code) => {
    const email = location?.state;
    setLoader(true);

    const url = `${apiActiveURL}/verify_code?email=${email}&code=${code}`;
    const options = {
      method: "GET",
      url,
    };
    const stateData = { email: email, code: code };
    axios(options)
      .then((res) => {
        if (res?.data?.code == 200) {
          setLoader(false);
          toast.success("You can reset your account password", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          navigate("/rest-password", { state: stateData });
          console.log(res, "email sent successfully");
        } else {
          setLoader(false);
          toast.error("Something Went Wrong", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch((error) => {
        //props.setFeedback('YourHostel', 'Something Went Wrong!', true , '');
        console.log(error, "response error");
        setLoader(false);
      });
  };

  return (
    <div className="bg_body">
      <Container>
        <div className="account_forgot_username">
          <div className="account_forgot_username_inner">
            <img className="account_header_logo" src={header_img} alt="" />
            <h3>Get a Confirmation Code In Email?</h3>
            <p className="confirmation_code_para">
              If you didn't recevice the verification email in your inbox please
              your spam folder
            </p>
            <div className="creat_account_fields">
              <Form>
                <div className="row_section">
                  <InputField
                    DivclassName="mail_input_section"
                    type="email"
                    placeholder="Enter Your Confirmation Code Here"
                    className="custom_input_field"
                    icon={user_icon}
                    iconClass="account_mail_icons"
                    onChange={(e) => {
                      setConfirmCode(e.target.value);
                    }}
                    value={confirmcode}
                  />
                </div>

                <p style={{ textAlign: "center", color: "#ff3333" }}>{error}</p>
                {loader ? (
                  <Button
                    name={<Loader />}
                    className="sign_up_btn"
                    type="submit"
                  />
                ) : (
                  <>
                    {/* <Button
                    name="Rest Password"
                    className="sign_up_btn"
                  /> */}
                    <button
                      onClick={() => authenticate(confirmcode)}
                      className="sign_up_btn"
                    >
                      Rest Password
                    </button>
                  </>
                )}
              </Form>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ConfirmCode;
