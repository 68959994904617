import axios from "axios";
import { apiActiveURL, appId, appKey } from "../../ApiBaseURL";

export const SignUp = (data) => {
  // console.log(data, "signupData");
  //   return;

  var config = {
    url: `${apiActiveURL}/registration_new`,
    method: "POST",
    headers: {
      AppId: appId,
    },
    data: data,
  };

  return (dispatch) => {
    axios(config)
      .then((res) => {
        // console.log(res.data, "reg all data =======");
        // console.log(res, "hotel response");
        if (res.data.status) {
          SignUplSuccess(dispatch, res);
        } else {
          SignUplFail(dispatch, res.data.message);
        }
      })
      .catch((e) => {
        // console.log(e, "fail");
        SignUplFail(dispatch, e.message);
      });
  };
};

export const ResetSignUp = () => {
  // console.log("reset sign up action called");
  return (dispatch) => {
    dispatch({ type: "SIGN_UP_SUCCESS", payload: null });
  };
};

export const ResetSignUpError = () => {
  // console.log("reset sign up error action called");
  return (dispatch) => {
    dispatch({ type: "SIGN_UP_FAIL", payload: null });
  };
};

const SignUplSuccess = (dispatch, res) => {
  // console.log(res, "sign up data");
  dispatch({ type: "SIGN_UP_SUCCESS", payload: res });
};

const SignUplFail = (dispatch, res) => {
  // console.log(res, "res action");
  dispatch({ type: "SIGN_UP_FAIL", payload: res });
};
