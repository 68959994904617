import React, { useState, useEffect } from "react";
import { Container, Form } from "react-bootstrap";
import header_img from "../../assets/images/verticallogo.png";
import user_mail_icon from "../../assets/images/user_mail_icon.png";
import Button from "../../component/Button/Button";
import InputField from "../../component/InputText/InputText";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../component/Loader/Loader";
import { toast } from "react-toastify";
import { apiActiveURL } from "../../ApiBaseURL";
import axios from "axios";

const ConfirmEmail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [emailf, setEmailF] = useState("");
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState("");

  const authenticate = (email) => {
    setLoader(true);

    const url = `${apiActiveURL}/forget_password_request?email=${email}`;
    const options = {
      method: "GET",
      url,
    };
    axios(options)
      .then((res) => {
        if (res?.data?.code == 200) {
          setLoader(false);
          toast.success(
            "Check your email the code has been sent successfully",
            {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            }
          );
          navigate("/confirm-code", { state: email });
          console.log(res, "email sent successfully");
        } else {
          setLoader(false);
          toast.error("Something Went Wrong", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch((error) => {
        //props.setFeedback('YourHostel', 'Something Went Wrong!', true , '');
        console.log(error, "response error");
        setLoader(false);
      });
  };

  return (
    <div className="bg_body">
      <Container>
        <div className="account_forgot_username">
          <div className="account_forgot_username_inner">
            <img className="account_header_logo" src={header_img} alt="" />
            <h3>Forgot Your Password? No Issues, Just</h3>
            <div className="creat_account_fields">
              <Form>
                <div className="row_section">
                  <InputField
                    DivclassName="mail_input_section"
                    type="email"
                    placeholder="Enter Your Email Address"
                    className="custom_input_field"
                    icon={user_mail_icon}
                    iconClass="account_mail_icons"
                    onChange={(e) => {
                      setEmailF(e.target.value);
                    }}
                    value={emailf}
                  />
                </div>

                <p style={{ textAlign: "center", color: "#ff3333" }}>{error}</p>
                {loader ? (
                  <Button
                    name={<Loader />}
                    className="sign_up_btn"
                    type="submit"
                  />
                ) : (
                  <>
                    {/* <Button
                      onClick={() => authenticate(emailf)}
                      name="Get Confirmation Code"
                      className="sign_up_btn"
                    /> */}
                    <button
                      onClick={() => authenticate(emailf)}
                      className="sign_up_btn"
                    >
                      Get Confirmation Code
                    </button>
                  </>
                )}
                <Button
                  name="Forgot Username"
                  className="sign_up_btn"
                  onClick={() => navigate("/rest-username")}
                />
              </Form>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ConfirmEmail;
