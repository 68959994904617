import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  FetchArea,
  ResetFetchAreaError,
  ResetFetchArea,
} from "../../Redux/Actions";
import {
  FetchSuburb,
  ResetFetchSuburb,
  ResetFetchSuburbError,
} from "../../Redux/Actions/Suburb";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../component/Loader/Loader";
import {
  SaveLocation,
  ResetSaveLocation,
  ResetSaveLocationError,
} from "../../Redux/Actions/SaveLocation";
import { GetUser as GetUserData } from "../../Redux/Actions/GetUser";

const SelectionRegion = () => {
  // console.log(window.location.pathname, "window");
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);
  const SessionData = JSON.parse(localStorage.getItem("SessionData"));
  const [userData, setUserData] = useState([]);

  const [showregion, setShowRegion] = useState(false);
  const [activeId, setActiveId] = useState(false);
  const [selectedArea, setSelectedArea] = useState("");
  // return selectedArea;

  const [showtwon, setShowTwon] = useState(false);
  const [townactiveId, setTownActiveId] = useState(false);
  const [selectedSuburb, setSelectedSuburb] = useState("");
  const [data, setData] = useState([]);
  const [states, setStates] = useState([]);

  const [areas, setAreas] = useState([]);
  const [suburbs, setSuburbs] = useState([]);

  const [loading, setLoading] = useState(true);

  const GetAreaSucces = useSelector((state) => {
    return state.Area.data;
  });

  const GetAreaError = useSelector((state) => {
    return state.Area.error;
  });

  const GetSuburbSucces = useSelector((state) => {
    return state.Suburb.data;
  });

  const GetSuburbError = useSelector((state) => {
    //  console.log(state.Suburb, "get Area success selector");
    return state.Suburb.error;
  });

  const SaveLocationSuccess = useSelector((state) => {
    // console.log(state.SaveLocation, "get save location succes");
    return state.SaveLocation.data;
  });

  const SaveLocationError = useSelector((state) => {
    return state.SaveLocation.error;
  });

  const { GetUser } = useSelector((state) => state);

  const SaveUserError = useSelector((state) => {
    return state.GetUser.error;
  });

  useEffect(() => {
    dispatch(GetUserData());
    return () => {};
  }, []);

  useEffect(() => {
    if (GetUser) {
      // console.log(GetUser?.data?.data?.data?.area, "GetUser in useEffect");
      setSelectedSuburb(GetUser?.data?.data?.data?.suburb);
      // console.log(
      //   GetUser?.data?.data?.data?.suburb,
      //   "GetUser in useEffect suburb"
      // );
      setSelectedArea(GetUser?.data?.data?.data?.area);
      //     setSelectedSuburb(SaveUserSuccess.data.data.suburb);
    }
    // if (SaveUserSuccess) {
    //   console.log(SaveUserSuccess.data.data, "if useEffect sign up success");
    //   setUserData(SaveUserSuccess.data.data);
    //   if (SaveUserSuccess.data.data.area && SaveUserSuccess.data.data.suburb) {
    //     setSelectedArea(SaveUserSuccess.data.data.area);
    //     setSelectedSuburb(SaveUserSuccess.data.data.suburb);
    //   } else {
    //     setSelectedArea("");
    //     setSelectedSuburb("");
    //   }
    // } else if (SaveUserError) {
    //   console.log(SaveUserError, "else if useEffect sign up error");
    // } else {
    //   //  setLoading(false);
    //   //  emptyfields();
    //   //  dispatch(ResetSignUp());
    //   //  dispatch(ResetSignUpError());
    // }
  }, [GetUser]);

  useEffect(() => {
    if (GetAreaSucces) {
      setStates(Object.keys(GetAreaSucces.data.data).sort());

      setAreas(GetAreaSucces.data.data);

      setLoading(false);

      dispatch(ResetFetchArea());
    } else if (GetAreaError) {
      setLoading(false);

      toast.error(GetAreaError, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      dispatch(ResetFetchAreaError());
    } else {
      setLoading(false);

      dispatch(ResetFetchArea());
      dispatch(ResetFetchAreaError());
    }
  }, [GetAreaSucces, GetAreaError]);

  useEffect(() => {
    if (GetSuburbSucces) {
      // console.log(GetSuburbSucces, "if useEffect get Subhurb success");
      let defaultvalue = { All: "All" };
      let object = Object.assign(defaultvalue, GetSuburbSucces.data.data);
      // console.log(object, "new object");
      setSuburbs(object);

      setLoading(false);

      dispatch(ResetFetchSuburb());
    } else if (GetSuburbError) {
      setLoading(false);

      toast.error(GetSuburbError, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      dispatch(ResetFetchSuburbError());
    }

    //    setErrMsg(true);
    //    dispatch(ResetSignUpError());
    else {
      setLoading(false);
      //  emptyfields();
      dispatch(ResetFetchSuburb());
      dispatch(ResetFetchSuburbError());
    }
  }, [GetSuburbSucces, GetSuburbError]);

  useEffect(() => {
    if (SaveLocationSuccess) {
      // console.log(SaveLocationSuccess, "if useEffect Save Location success");
      toast.success("Location Saved Successfully", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      dispatch(GetUserData());
      //  setLoading(false);

      dispatch(ResetSaveLocation());
    } else if (SaveLocationError) {
      // console.log(signUpError, "else if useEffect sign up error");
      //  setLoading(false);

      toast.error(SaveLocationError, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      //  setErrMsg(true);
      dispatch(ResetSaveLocationError());
    } else {
      //  setLoading(false);

      dispatch(ResetSaveLocation());
      dispatch(ResetSaveLocationError());
    }
  }, [SaveLocationSuccess, SaveLocationError]);

  const handleArea = (data, index) => {
    let item = {
      index,
      data,
    };
    // console.log(item, "click");
    setSelectedArea(data);
    setSelectedSuburb("");
    localStorage.setItem("area", data);
  };

  const handleAreaRegion = () => {
    // console.log("bilal");

    dispatch(FetchArea());
    setLoading(true);

    setShowRegion((current) => !current);
    setActiveId((current) => !current);
  };
  const handleTown = () => {
    // console.log("suburb/town", selectedArea);
    if (selectedArea) {
      // console.log("present");
      dispatch(FetchSuburb(selectedArea));
      setLoading(true);
    } else {
      // console.log("not present");
      toast.error("Please Select Area", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setLoading(false);
    }
    // return;

    setShowTwon((current) => !current);
    setTownActiveId((current) => !current);
  };

  // useSelector((state) =>
  //   console.log(
  //     state?.GetUser?.data?.data?.data?.suburb,
  //     "current state suburb"
  //   )
  // );
  const handleSuburb = (data, index) => {
    let item = {
      data,
      index,
    };
    // console.log(item, "suburb clikced");
    // return;
    setSelectedSuburb(data);
    localStorage.setItem("subrub", data);
    // console.log(selectedArea, selectedSuburb, "values");
    let area_data = {
      id: SessionData[1],
      suburb: data,
      area: selectedArea,
    };
    // console.log(area_data, "area_data");
    // return;
    dispatch(SaveLocation(area_data));

    // dispatch(GetUser());
    // console.log(localStorage.getItem("subrub"));
  };

  const closeOpenDropdown = (e) => {
    if (
      dropdownRef.current &&
      showregion &&
      !dropdownRef.current.contains(e.target)
    ) {
      setShowRegion(false);
      setActiveId(false);
    } else if (
      dropdownRef.current &&
      showtwon &&
      !dropdownRef.current.contains(e.target)
    ) {
      setShowTwon(false);
      setTownActiveId(false);
    }
    // else {
    //   console.log("InSide Clicked");
    // }
  };

  document.addEventListener("mousedown", closeOpenDropdown);

  return (
    <div className="selection_region">
      <div className="selection_region_inner">
        {window.location.pathname == "/home" ? (
          <>
            <a
              className={activeId ? "active_selection" : ""}
              onClick={() => {
                handleAreaRegion();
              }}
            >
              {
                // GetUser?.data?.data?.data?.area
                // ? GetUser?.data?.data?.data?.area
                selectedArea ? selectedArea : "Area/Region"
              }
            </a>
            <a
              className={townactiveId ? "active_selection" : ""}
              onClick={() => handleTown()}
            >
              {
                // GetUser?.data?.data?.data?.suburb

                // ? GetUser?.data?.data?.data?.suburb
                selectedSuburb ? selectedSuburb : "Suburb/Town"
              }

              {/* {selectedSuburb === "" || selectedSuburb === null
            ? "Suburb/Town"
            : selectedSuburb} */}
            </a>
          </>
        ) : (
          <>
            <a
              className={activeId ? "active_selection" : ""}
              onClick={() => {
                // handleAreaRegion();
              }}
            >
              {
                // GetUser?.data?.data?.data?.area
                // ? GetUser?.data?.data?.data?.area
                selectedArea ? selectedArea : "Area/Region"
              }
            </a>
            <a
              className={townactiveId ? "active_selection" : ""}
              // onClick={() => handleTown()}
            >
              {
                // GetUser?.data?.data?.data?.suburb

                // ? GetUser?.data?.data?.data?.suburb
                selectedSuburb ? selectedSuburb : "Suburb/Town"
              }

              {/* {selectedSuburb === "" || selectedSuburb === null
            ? "Suburb/Town"
            : selectedSuburb} */}
            </a>
          </>
        )}

        {showregion && (
          <div className="region_town_selection" ref={dropdownRef}>
            {loading ? (
              <></>
            ) : (
              <h4 className="select_region_heading">Select Area/Region</h4>
            )}

            <ul className={loading ? "loader_area" : ""}>
              {loading ? (
                <>
                  <td>
                    <tr>
                      <Loader />
                    </tr>
                  </td>
                </>
              ) : (
                <>
                  {states.map((state, index) => {
                    return (
                      <tr key={index} className="selection_main_tr">
                        <tr className="selection_head">
                          <td>
                            <b>{state} :</b>
                          </td>
                        </tr>
                        {areas[state].map((area, index) => {
                          return (
                            <td
                              onClick={() => {
                                handleArea(area, index);
                                setShowRegion(false);
                                setActiveId(false);
                              }}
                              className="selection_main_key_value"
                            >
                              <p>{area}</p>
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </>
              )}
            </ul>
          </div>
        )}

        {showtwon && (
          <div className="region_town_selection" ref={dropdownRef}>
            {loading ? (
              <></>
            ) : (
              <>
                {selectedArea ? (
                  <h4 className="select_region_heading">Select Suburb/Town</h4>
                ) : (
                  <p className="error_msg_select_region">
                    Please Select Area/Region
                  </p>
                )}
              </>
            )}

            <ul className={loading ? "loader_area" : ""}>
              {loading ? (
                <>
                  <td>
                    <tr>
                      <Loader />
                    </tr>
                  </td>
                </>
              ) : (
                <>
                  {Object.values(suburbs)
                    .sort()
                    .map((suburb, index) => (
                      <>
                        <li key={index} className="area_according_values">
                          <div
                            onClick={() => {
                              handleSuburb(suburb, index);
                              setShowTwon(false);
                              setTownActiveId(false);
                            }}
                          >
                            <p>{suburb}</p>
                          </div>
                        </li>
                        {/* <tr key={index} className="area_according_values">
                          <td
                            onClick={() => {
                              handleSuburb(suburb, index);
                            }}
                          >
                            <p>{suburb}</p>
                          </td>
                        </tr> */}
                      </>
                    ))}
                </>
              )}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectionRegion;
