const INITIAL_STATE = {
  category: null,
  category_id: 0,
  error: null,
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "CATEGORY_SUCCESS":
      return { ...state, category: action.payload };
    case "CATEGORY_ID":
      return { ...state, category_id: action.payload };

    case "CATEGORY_FAIL":
      return { ...state, error: action.payload };

    default:
      return state;
  }
};
