import React, { useState } from "react";
import { Col, Container, Modal, Nav, Row, Tab } from "react-bootstrap";
import Footer from "../../footer/footer";
import Header from "../../Header/Header";
import policyImg from "../../assets/images/privacypolicy_img.png";
import termconditionImg from "../../assets/images/termconditionsImg.png";
import CustomerDetail from "./AccountComponents/MyDetail";
import privacy_policy from "../../assets/pdffiles/yourhotel_privacy_policy.pdf";
import termsConditions from "../../assets/pdffiles/yourhotel_membership_terms_conditions.pdf";
import ContactTeam from "./AccountComponents/ContactTeam";
import Button from "../Button/Button";
import { useNavigate } from "react-router-dom";
import MyTransaction from "./AccountComponents/MyTransaction";
import Transaction from "./Transaction";

const CustomerAccount = () => {
  const tabData = [
    { id: 1, name: "My Details", eventkey: "first" },
    { id: 2, name: "My Transaction", eventkey: "second" },
    { id: 3, name: "Term & Conditions", eventkey: "third" },
    { id: 4, name: "Privacy Policy", eventkey: "fourth" },
    { id: 5, name: "Contact Team", eventkey: "fifth" },
    // { id: 6, name: "Delete Account", eventkey: "six" },
  ];
  const navigate = useNavigate();
  // const [showprivacy, setShowPrivacy] = useState(false);
  // const [showconditions, setShowConditions] = useState(false);

  // const handleClosePrivacy = () => setShowPrivacy(false);
  // const handleShowPrivacy = () => setShowPrivacy(true);

  // const handleCloseConditions = () => setShowConditions(false);
  // const handleShowConditions = () => setShowConditions(true);

  // console.log(tabData[3]?.eventkey, "tabDatatabData");

  return (
    <>
      <Header />

      {/* <Modal
        show={showprivacy}
        onHide={handleClosePrivacy}
        size="lg"
        centered
        className="privacy_policy_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="privacy_policy_modal">
            <iframe
              class="embed-responsive-item"
              height="200"
              src={privacy_policy}
              allowfullscreen
            />
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showconditions}
        onHide={handleCloseConditions}
        size="lg"
        centered
        className="privacy_policy_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="privacy_policy_modal">
            <iframe
              class="embed-responsive-item"
              height="200"
              src={termsConditions}
              allowfullscreen
            />
          </div>
        </Modal.Body>
      </Modal> */}

      <div className="my_account_section">
        <Container>
          <div className="row">
            <div className="col-md-12">
              <div className="my_account_main">
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                  <Row>
                    <Col lg={3} md={5}>
                      <div className="my_account_tab">
                        <Nav variant="pills" className="flex-column">
                          {tabData?.map((tab) => (
                            <Nav.Item>
                              <Nav.Link
                                eventKey={tab?.eventkey}
                                // onClick={() =>
                                //   tab?.eventkey == "fourth"
                                //     ? handleShowPrivacy()
                                //     : tab?.eventkey == "third"
                                //     ? handleShowConditions()
                                //     : null
                                // }
                              >
                                {tab?.name}
                              </Nav.Link>
                            </Nav.Item>
                          ))}
                        </Nav>
                      </div>
                      <div className="voucher_coupons">
                        <Button
                          name="My Voucher & Coupons"
                          className="black_btn"
                          onClick={() => navigate("/vouchers-coupons")}
                        />
                      </div>
                    </Col>
                    <Col lg={9} md={7}>
                      <div className="my_account_tab_content">
                        <Tab.Content>
                          <Tab.Pane eventKey="first">
                            <CustomerDetail />
                          </Tab.Pane>
                          <Tab.Pane eventKey="second">
                            <MyTransaction />
                            <Transaction />
                          </Tab.Pane>
                          <Tab.Pane eventKey="third">
                            <div className="privacy_policy_main">
                              <iframe
                                class="embed-responsive-item"
                                height="200"
                                src={termsConditions}
                                allowfullscreen
                              />
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="fourth">
                            <div className="privacy_policy_main">
                              <iframe
                                class="embed-responsive-item"
                                height="200"
                                src={privacy_policy}
                                allowfullscreen
                              />{" "}
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="fifth">
                            <ContactTeam />
                          </Tab.Pane>
                          {/* <Tab.Pane eventKey="six">
                            <Transaction title="Customer Delect Account Section" />
                          </Tab.Pane> */}
                        </Tab.Content>
                      </div>
                    </Col>
                  </Row>
                </Tab.Container>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default CustomerAccount;
