import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import ServicesBox from "../../component/ServicesBox/ServicesBox";
import Footer from "../../footer/footer";
import Header from "../../Header/Header";
import {
  FetchArea,
  ResetFetchArea,
  ResetFetchAreaError,
} from "../../Redux/Actions";
import { ResetCategoryFail } from "../../Redux/Actions";
import { ResetChildCategoryFail } from "../../Redux/Actions/ChildCategories";
import { ResetGetUserError } from "../../Redux/Actions/GetUser";
import { ResetServiceFail } from "../../Redux/Actions/Services";

import { GetUser } from "../../Redux/Actions/GetUser";
import { Logout } from "../../Redux/Actions/Logout";
import "./Home.css";
const service = [
  {
    img_services: require("../../assets/images/my_account.png"),
    serviceName: "My Account",
    listingType: null,
    pageName: "/my-account",
  },
  // {
  //   img_services: require("../../assets/images/my_account.png"),
  //   serviceName: "Voucher and Coupons",
  //   listingType: null,
  //   pageName: "/vouchers-coupons",
  // },
  {
    img_services: require("../../assets/images/restaurents.png"),
    serviceName: "Restaurants, Bars & Eateries",
    listingType: 5,
    pageName: "/categories",
  },
  {
    img_services: require("../../assets/images/home_base.png"),
    serviceName: "Home Based Services",
    listingType: 13,
    pageName: "/categories",
  },
  {
    img_services: require("../../assets/images/reverse.png"),
    serviceName: "Trades & Services",
    listingType: 15,
    pageName: "/categories",
  },
  {
    img_services: require("../../assets/images/professional.png"),
    serviceName: "Professional Services",
    listingType: 12,
    pageName: "/categories",
  },
  {
    img_services: require("../../assets/images/beauty.png"),
    serviceName: "Beauty & Retail",
    listingType: 9,
    pageName: "/categories",
  },
  {
    img_services: require("../../assets/images/other.png"),
    serviceName: "Other",
    listingType: 11,
    pageName: "/categories",
  },
  {
    img_services: require("../../assets/images/qoins.png"),
    serviceName: "Tokenise Spare Capacity with QOIN",
    listingType: 14,
    pageName: "/categories",
  },
];

export const Home = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState(false);

  const GetAreaSucces = useSelector((state) => {
    console.log(state, "state----");
    return state.Area.data;
  });

  const SaveUserSuccess = useSelector((state) => {
    return state.GetUser.data;
  });

  const GetAreaError = useSelector((state) => {
    return state.Area.error;
  });
  useEffect(() => {
    dispatch(ResetChildCategoryFail());
    dispatch(ResetCategoryFail());
    dispatch(ResetGetUserError());
    dispatch(FetchArea());
    dispatch(ResetServiceFail());
    // dispatch(GetUser());
  }, []);

  useEffect(() => {
    if (
      SaveUserSuccess?.data?.data?.area == false ||
      SaveUserSuccess?.data?.data?.suburb == false
    ) {
      setSelected(false);
    } else {
      setSelected(true);
    }
  }, [SaveUserSuccess]);

  useEffect(() => {
    if (GetAreaSucces) {
      // console.log(Object.keys(GetAreaSucces.data.data).sort(), "states home");
    } else if (GetAreaError) {
      // console.log(GetAreaError, "else if useEffect GetAreaError error home");
      setLoading(false);
      //  emptyfields();
      //   toast.error(GetAreaError, {
      //     position: "top-center",
      //     autoClose: 5000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      //     theme: "colored",
      //   });
      dispatch(Logout());

      navigate("/login-account");

      //   Navigate()
      // dispatch(ResetFetchAreaError());
    }
    return () => {
      dispatch(ResetFetchArea());
      dispatch(ResetFetchAreaError());
      //   dispatch(FetchAreaFail())
    };
  }, [GetAreaSucces, GetAreaError]);
  return (
    <>
      <div className="bg_body">
        <Header />
        <div className="inner_body">
          <div className="services">
            <div className="container">
              <div className="row">
                <div className="col-xl-12 offset-xl-0 col-lg-10 offset-lg-1 col-md-8 offset-md-2">
                  <div className="row">
                    {service.map((item, index) => (
                      <ServicesBox
                        key={index}
                        services_img={item.img_services}
                        services_name={item.serviceName}
                        listingType={item.listingType}
                        selected={selected}
                        pageName={item.pageName}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
