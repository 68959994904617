import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { RemoveFavourite } from "../../Redux/Actions/GetFavourites";
import "./FavouriteBox.css";

const FavouriteBox = ({ data, id, loading, removeItem, name }) => {
  // console.log(data.id, "id");
  const dispatch = useDispatch();

  const [specialPromotion, setSpecialPromotion] = useState();
  const [passCoupons, setPassCoupons] = useState();

  const RemoveFavouriteSuccess = useSelector((state) => {
    // console.log(state.GetFavourites.remove, "state.GetFavourites.data");
    return state.GetFavourites.remove;
  });

  useEffect(() => {
    if (RemoveFavouriteSuccess?.data?.code == 200) {
      // console.log(RemoveFavouriteSuccess, "RemoveFavouriteSuccess");
    }

    return () => {
      // dispatch(ResetCategoryFail());
      // dispatch(ResetCategory());
    };
  }, []);

  useEffect(() => {
    if (name == "specialPromotions") {
      // console.log(data, "special promotions");
      let specialPromotionDetails = JSON.parse(data.coupon_details);
      // console.log(specialPromotionDetails, "details");
      setSpecialPromotion(specialPromotionDetails);
    } else if (name == "passCoupons") {
      // console.log(data, "passCoupons--------------");
      let passCouponDetails = JSON.parse(data.coupon_details);
      // console.log(passCouponDetails, "details");
      setPassCoupons(passCouponDetails);
    }
  }, [name]);

  const removeFavourite = () => {
    dispatch(RemoveFavourite(data.id));
    // toast.
    toast.success("Favourite Remove Successfuly", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  const navigate = useNavigate();
  return (
    <div className="col-md-6">
      <div className="Vouchers_box">
        {name == "favoriteList" ? (
          <>
            {" "}
            <p
              className="remove"
              onClick={() => {
                removeFavourite();
                removeItem(data.id);
              }}
            >
              Remove
            </p>
          </>
        ) : null}
        {name == "favoriteList" ? (
          <>
            <div
              className=""
              onClick={() =>
                navigate("/Business", {
                  state: data,
                })
              }
            >
              <h2>{data.title}</h2>
            </div>
          </>
        ) : name == "specialPromotions" ? (
          <>
            <div
              className=""
              onClick={() =>
                navigate("/voucher-details", {
                  state: { name: "specialPromotions", data },
                })
              }
            >
              {specialPromotion ? (
                <>
                  <h2>{specialPromotion.coupon_name}</h2>
                </>
              ) : null}
            </div>
          </>
        ) : name == "passCoupons" ? (
          <>
            <div
              className=""
              onClick={() =>
                navigate("/voucher-details", {
                  state: { name: "passCoupons", data },
                })
              }
            >
              {passCoupons ? (
                <>
                  <h2>{passCoupons.coupon_name}</h2>
                </>
              ) : null}
            </div>
          </>
        ) : null}
        {/* {name == "favoriteList" ? (
          <>
            <div
              className=""
              onClick={() =>
                navigate("/Business", {
                  state: data,
                })
              }
            >
              <h2>{data.title}</h2>
            </div>
          </>
        ) : name == "specialPromotions" ? (
          <>
            <div
              className=""
              onClick={() =>
                navigate("/voucher-details", {
                  state: { name: "specialPromotions", data },
                })
              }
            >
              {specialPromotion ? (
                <>
                  <h2>{specialPromotion.coupon_name}</h2>
                </>
              ) : null}
            </div>
          </>
        ) : null} */}
      </div>
    </div>
  );
};

export default FavouriteBox;
