const INITIAL_STATE = {
    Login: null,
    error: null
};
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'LOGIN_SUCCESS':
            return { ...state, Login: action.payload };

        case 'LOGIN_FAIL':
            return { ...state, error: action.payload };

        default:
            return state;
    }
};