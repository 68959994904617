import React, { useState, useRef } from "react";
import "./Business.css";
import defaultThumbnail from "../../assets/images/default_thumbnail.png";
import Header from "../../Header/Header";
import Footer from "../../footer/footer";
import TextButton from "../../component/TextButton/TextButton";
import Buttonn from "../../component/Button/Button";
import GoogleMapReact from "google-map-react";
import ScheduleTime from "./ScheduleTime";
import { RxCross2 } from "react-icons/rx";
import { FaMapMarkerAlt } from "react-icons/fa";

import { useLocation, useNavigate } from "react-router";
import { useEffect } from "react";
import { GetBusiness } from "../../Redux/Actions/GetBusiness";
import { useDispatch, useSelector } from "react-redux";
import {
  ResetGetBusiness,
  ResetGetBusinessError,
} from "../../Redux/Actions/GetBusiness";
import Loader from "../../component/Loader/Loader";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {
  Favourite,
  ResetFavourite,
  ResetFavouriteError,
} from "../../Redux/Actions/Favourite";
import { toast } from "react-toastify";
import { BsCheck2 } from "react-icons/bs";
import axios from "axios";
import { apiActiveURL, appId, appKey, GOOGLE_MAP_KEY } from "../../ApiBaseURL";
const Business = (prop) => {
  const navigate = useNavigate();
  let headerRef = useRef();
  let footerRef = useRef();
  const SessionData = JSON.parse(localStorage.getItem("SessionData"));
  const listingType = localStorage.getItem("listingType");
  // console.log(listingType, "listingType");

  const [servicedetail, setServiceDetail] = useState([]);
  const [featuredpromotion, setFeaturedPromotion] = useState();
  const [showCoupon, setShowCoupon] = useState("");
  const [coupon, setCoupon] = useState("");
  const [discountCoupon, setDiscountCoupon] = useState(false);
  const [mapApiLoaded, setmapApiLoaded] = useState(false);
  const [mapApi, setmapApi] = useState(null);
  const [mapInstance, setmapInstance] = useState(null);

  const [successCoupon, setSuccessCoupon] = useState("");

  const [address, setAddress] = useState("");
  const [servicephone, setServicePhone] = useState("");
  const [vaccine, setVaccine] = useState("");
  const [menuURL, setMenuURL] = useState("");
  const [servicelatitude, setServiceLatitude] = useState("");
  var [tableHead, setTableHeads] = useState([
    "M",
    "T",
    "W",
    "T",
    "F",
    "S",
    "S",
  ]);
  const [calendardetails, setCalendarDetails] = useState();

  const [servicelongitude, setServiceLongitude] = useState("");
  const [websiteUrl, setWebsiteUrl] = useState("");
  const [servicebookingurl, setServiceBookingURl] = useState("");
  const [showWeb, setShowWeb] = useState(false);
  const [promodetails, setPromoDetails] = useState([]);
  const [confirmationcode, setConfirmationCode] = useState("");

  const [qoinDetails, setQoinDetails] = useState("");
  const [showCoin, setShowCoin] = useState(false);
  const [visibleQoin, setVisibleQoin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [numbershow, setNumberShow] = useState(false);
  const [showSpecial, setShowSpecial] = useState(false);

  const [showQoin, setShowQoin] = useState(false);
  const [isRequest, setIsRequest] = useState(true);
  const [mondiscount, setMonDiscount] = useState("");
  const [fridiscount, setFriDiscount] = useState("");
  const [coupondetails, setCouponDetails] = useState({});

  const [favLoader, setFavLoader] = useState(false);

  const AnyReactComponent = ({ text }) => (
    <div>
      <FaMapMarkerAlt
        size={25}
        // color={"#62a945"}
        color={"red"}
      />
    </div>
  );

  const dispatch = useDispatch();
  const location = useLocation();
  console.log(location, "location");
  const data = location.state;

  // console.log(location, "data location");

  const GetBusinessSuccess = useSelector((state) => {
    return state.GetBusiness.data;
  });
  const FavouriteSuccess = useSelector((state) => {
    // console.log(state?.Favourite?.data, "state.Favourite.success");

    return state.Favourite.data;
  });
  const FavouriteFail = useSelector((state) => {
    // console.log(state?.Favourite?.error?.data, "state.Favourite.error");
    return state?.Favourite?.error?.data;
  });

  const GetBusinessError = useSelector((state) => {
    return state.GetBusiness.error;
  });
  const activeSearch = (data) => {
    // console.log(headerRef, "data in active callback");
    headerRef?.current?.activeSearchField();
  };
  useEffect(() => {
    if (headerRef) console.log(headerRef, "headerRefheaderRef");

    return () => {};
  }, [headerRef]);
  useEffect(() => {
    if (data) {
      console.log(data, "data business");
      dispatch(GetBusiness(data?.service_id ? data?.service_id : data?.id));
      setLoading(true);
    } else {
    }
  }, [data]);

  useEffect(() => {
    if (GetBusinessSuccess) {
      // console.log(first)
      setLoading(false);
      // console.log(
      //   GetBusinessSuccess.data.data.service,
      //   "if useEffect get business success"
      // );
      setServiceDetail(GetBusinessSuccess.data.data.service);
      setPromoDetails(GetBusinessSuccess.data.data.promotions);

      if (GetBusinessSuccess.data.data.service.vaccine_password) {
        // console.log(
        //   GetBusinessSuccess.data.data.service.vaccine_password,
        //   "GetBusinessSuccess.data.data.service.vaccine_password"
        // );
        if (GetBusinessSuccess.data.data.service.vaccine_password == "1") {
          setVaccine(require("../../assets/images/vaccine_pass.jpg"));
        } else if (
          GetBusinessSuccess.data.data.service.vaccine_password == "2"
        ) {
          setVaccine(require("../../assets/images/vaccine_pass_cross.png"));
        } else {
          setVaccine("");
        }
      }

      let objCoupon = GetBusinessSuccess?.data?.data?.service?.meta?.find(
        (o) => o.meta_key === "coupon"
      );

      console.log(objCoupon, "objCoupon------");
      if (objCoupon != undefined) {
        let parseCoupon = JSON.parse(objCoupon?.meta_value);

        let today = new Date();
        let day = today.getDay();
        let daylist = [
          "sunday",
          "monday",
          "tuesday",
          "wednesday",
          "thursday",
          "friday",
          "saturday",
        ];
        if (parseCoupon?.hasOwnProperty(daylist[day])) {
          let couponpercentage = parseCoupon[daylist[day]];
          let moncouponpercentage = parseCoupon.monday;
          let fricouponpercentage = parseCoupon.friday;
          setMonDiscount(moncouponpercentage);
          setFriDiscount(fricouponpercentage);
          if (moncouponpercentage <= 0 && fricouponpercentage <= 0) {
            setIsRequest(false);
          }
          setCouponDetails({
            coupon_name: parseCoupon.coupon_name,
            coupon_details: parseCoupon.coupon_details,
            coupon_percentage: couponpercentage,
            min_amount: parseCoupon.min_amount,
          });
        }
      }

      let showcoupon = GetBusinessSuccess.data.data.service.meta.find(
        (o) => o.meta_key === "show_coupon"
      );
      setShowCoupon(showcoupon.meta_value);

      let objServiceDetails =
        GetBusinessSuccess?.data?.data?.service?.meta.find(
          (o) => o.meta_key === "service_details"
        );
      if (objServiceDetails != undefined) {
        let parseServiceDetails = JSON.parse(objServiceDetails.meta_value);
        // console.log(parseServiceDetails, "parseServiceDetails");
        setCalendarDetails([
          [
            parseServiceDetails?.monday?.breakfast,
            parseServiceDetails?.tuesday?.breakfast,
            parseServiceDetails?.wednesday?.breakfast,
            parseServiceDetails?.thursday?.breakfast,
            parseServiceDetails?.friday?.breakfast,
            parseServiceDetails?.saturday?.breakfast,
            parseServiceDetails?.sunday?.breakfast,
          ],
          [
            parseServiceDetails?.monday?.lunch,
            parseServiceDetails?.tuesday?.lunch,
            parseServiceDetails?.wednesday?.lunch,
            parseServiceDetails?.thursday?.lunch,
            parseServiceDetails?.friday?.lunch,
            parseServiceDetails?.saturday?.lunch,
            parseServiceDetails?.sunday?.lunch,
          ],
          [
            parseServiceDetails?.monday?.dinner,
            parseServiceDetails?.tuesday?.dinner,
            parseServiceDetails?.wednesday?.dinner,
            parseServiceDetails?.thursday?.dinner,
            parseServiceDetails?.friday?.dinner,
            parseServiceDetails?.saturday?.dinner,
            parseServiceDetails?.sunday?.dinner,
          ],
          [
            parseServiceDetails?.monday?.allday,
            parseServiceDetails?.tuesday?.allday,
            parseServiceDetails?.wednesday?.allday,
            parseServiceDetails?.thursday?.allday,
            parseServiceDetails?.friday?.allday,
            parseServiceDetails?.saturday?.allday,
            parseServiceDetails?.sunday?.allday,
          ],
        ]);
      } else {
        setCalendarDetails([
          ["0", "0", "0", "0", "0", "0", "0"],
          ["0", "0", "0", "0", "0", "0", "0"],
          ["0", "0", "0", "0", "0", "0", "0"],
          ["0", "0", "0", "0", "0", "0", "0"],
        ]);
      }

      // console.log(calendardetails, "calendarDetail &&");
      /*****************   Feature Promotion Work   *********************/

      let featuredImage = GetBusinessSuccess.data.data.service.meta.find(
        (o) => o.meta_key === "image"
      );

      if (featuredImage !== undefined) {
        // console.log(featuredImage.meta_value, "image");
        setFeaturedPromotion(featuredImage.meta_value);
      }
      // console.log(
      //   GetBusinessSuccess.data.data.promotions,
      //   "GetBusinessSuccess.data.data.promotions"
      // );
      setPromoDetails(GetBusinessSuccess.data.data.promotions);

      /*****************   Address Work   *********************/

      let serviceaddress = GetBusinessSuccess.data.data.service.meta.find(
        (o) => o.meta_key === "service_address"
      );
      if (serviceaddress != undefined) {
        setAddress(serviceaddress.meta_value);
      }

      /*****************   Phone Work   *********************/

      let servicephone = GetBusinessSuccess.data.data.service.meta.find(
        (o) => o.meta_key === "phone"
      );
      // console.log(servicephone, "servicephone");
      if (servicephone != undefined) {
        setServicePhone(servicephone.meta_value);
      }

      //vaccine_password work
      // let vaccine_pass = GetBusinessSuccess.data.data.service.meta.find(
      //   (o) => o.meta_key === "vaccine_password"
      // );
      // console.log(vaccine_pass, "vaccine_pass");
      // if (vaccine_pass) {
      //   if (vaccine_pass.meta_value == "1") {
      //     setVaccine(require("../../assets/images/vaccine_pass.jpg"));
      //   } else if (vaccine_pass.meta_value == "0") {
      //     setVaccine(require("../../assets/images/vaccine_pass_cross.png"));
      //   } else {
      //     setVaccine("");
      //   }
      // }

      //hotel_menu_url work
      let hotel_menu_url = GetBusinessSuccess.data.data.service.meta.find(
        (o) => o.meta_key === "hotel_menu_url"
      );
      if (hotel_menu_url) {
        setMenuURL(hotel_menu_url.meta_value);
      }

      //latlong work
      let servicelatitude = GetBusinessSuccess.data.data.service.meta.find(
        (o) => o.meta_key === "service_latitude"
      );
      // console.log(servicelatitude, "servicelatitude");
      if (servicelatitude != undefined) {
        setServiceLatitude(servicelatitude.meta_value);
      } else {
        setServiceLatitude("0.000000");
      }

      let servicelongitude = GetBusinessSuccess.data.data.service.meta.find(
        (o) => o.meta_key === "service_longitude"
      );
      if (servicelongitude != undefined) {
        setServiceLongitude(servicelongitude.meta_value);
      } else {
        setServiceLongitude("0.000000");
      }

      // website_url work
      let web_url = GetBusinessSuccess.data.data.service.meta.find(
        (o) => o.meta_key === "website_url"
      );
      if (web_url != undefined) {
        setWebsiteUrl(web_url.meta_value);
      }

      let servicebookingurl = GetBusinessSuccess.data.data.service.meta.find(
        (o) => o.meta_key === "booking_url"
      );
      // console.log(servicebookingurl, "servicebookingurl");
      if (servicebookingurl != undefined) {
        setServiceBookingURl(servicebookingurl.meta_value);
      }

      // Qoin_terms_conditions work

      let qoinShow = GetBusinessSuccess.data.data.service.meta.find(
        (o) => o.meta_key === "show_qoin"
      );

      let qoinTerms = GetBusinessSuccess.data.data.service.meta.find(
        (o) => o.meta_key === "qoin_terms"
      );
      // console.log(qoinShow, "qoin showwww");
      // console.log(qoinTerms, "qoin termsssssssssss");

      if (qoinShow !== undefined) {
        if (qoinShow?.meta_value == "1") {
          setShowCoin(true);
          setQoinDetails(qoinTerms?.meta_value);
        } else {
          setShowCoin(false);
        }
      } else {
        setShowCoin(false);
      }

      //website button show

      let show_web = GetBusinessSuccess.data.data.service.meta.find(
        (o) => o.meta_key === "show_website"
      );
      // console.log(show_web, "show_web");

      if (show_web !== undefined) {
        if (show_web?.meta_value == "1") {
          setShowWeb(true);
          // setQoinDetails(qoinTerms.meta_value);
        } else {
          setShowWeb(false);
        }
      } else {
        setShowWeb(false);
      }
      // console.log(show_web, "show_web");
      setConfirmationCode(generateConfirmationCode(6));
      dispatch(ResetGetBusiness());
    } else if (GetBusinessError) {
      // console.log(signUpError, "else if useEffect sign up error");
      // setLoading(false);
      // emptyfields();
      // toast.error(signUpError, {
      //   position: "top-center",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "colored",
      // });
      // setErrMsg(true);
      dispatch(ResetGetBusinessError());
    } else {
      // setLoading(false);
      // emptyfields();
      dispatch(ResetGetBusiness());
      dispatch(ResetGetBusinessError());
    }
  }, [GetBusinessSuccess, GetBusinessError]);

  useEffect(() => {
    if (FavouriteSuccess) {
      setFavLoader(false);
      // console.log(FavouriteSuccess, "FavouriteSuccessFavouriteSuccess");
      toast.success("Service has been added to your Favourites", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      dispatch(ResetFavourite());
    } else if (FavouriteFail) {
      setFavLoader(false);

      toast.error(FavouriteFail, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      // dispatch(ResetFavourite());
      dispatch(ResetFavouriteError());
    } else {
      ResetFavourite();
      ResetFavouriteError();
    }
  }, [FavouriteSuccess, FavouriteFail]);

  const AddFavourite = () => {
    setFavLoader(true);
    if (data) {
      dispatch(Favourite(data.service_id ? data.service_id : data.id));
    }
    // console.log(FavouriteFail, "FavouriteFail here");
    // if (FavouriteFail) {
    //   toast.error(FavouriteFail, {
    //     position: "top-center",
    //     autoClose: 3000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "colored",
    //   });
    // } else {
    //   toast.success("Service has been added to your Favourites", {
    //     position: "top-center",
    //     autoClose: 3000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "colored",
    //   });
    // }
    // dispatch(ResetFavouriteError());
    // dispatch(ResetFavourite());
    // dispatch(FavouriteFail());

    // console.log(FavouriteSuccess, "FavouriteSuccess");
  };

  const handleCoupon = () => {
    if (mondiscount <= 0 && fridiscount <= 0) {
      // setCoupon(true);
      return (
        <>
          <Modal show={coupon} onHide={handleCouponClose}>
            <Modal.Header closeButton>
              <Modal.Title>360 Coupon</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              360 Pass is the YourHotel loyalty program. Unfortunately, 360 Pass
              is not available at {data?.title ? data?.title : data?.name}
            </Modal.Body>
          </Modal>
        </>
      );
    } else {
      return (
        <>
          <Modal show={coupon} onHide={handleCouponClose}>
            <Modal.Header closeButton>
              <Modal.Title>360 Coupon</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              360 Pass is the YourHotel loyalty program entitling you to a
              discount whenever you visit{" "}
              {data?.title ? data?.title : data?.name}
            </Modal.Body>
            <Button onClick={() => handleRequestPass()}>Request Now</Button>
          </Modal>
        </>
      );
    }
  };

  const handleRequestPass = () => {
    // setShow(false);
    setCoupon(false);
    setDiscountCoupon(true);
    // var stringifycoupondetails = {};
    var stringifycoupondetails = JSON.stringify(coupondetails);
    // console.log(stringifycoupondetails, "stringifycoupondetails");
    // return;
    const url = `${apiActiveURL}/coupon/add`;
    let ApiParamForAddCoupon = {
      service_id: data.service_id ? data.service_id : data.id,
      user_id: SessionData[1],
      details: stringifycoupondetails,
    };

    const options = {
      method: "POST",
      headers: {
        AppKey: appKey,
        Token: SessionData[0],
        AppId: appId,
      },
      data: ApiParamForAddCoupon,
      url,
    };
    axios(options).then((res) => {
      if (res.data.code == 200) {
        if (res.data.hasOwnProperty("data")) {
          // handleDiscountCouponShow();
          handleDiscountCoupon();
          // if (mondiscount > 0 && fridiscount > 0) {
          //   setDiscountCoupon(true);
          //   return (
          //     <>
          //       <Modal show={discountCoupon} onHide={handleCouponClose}>
          //         <Modal.Header closeButton>
          //           <Modal.Title>360 Coupon</Modal.Title>
          //         </Modal.Header>
          //         <Modal.Body>
          //           Pass added to My Favourites. You can use 360 Pass at $
          //           {data.title} and enjoy a discount of ${mondiscount}% from
          //           Mon-Thurs and ${fridiscount}% from Fri-Sun.
          //         </Modal.Body>
          //       </Modal>
          //     </>
          //   );
          // } else if (mondiscount > 0 && fridiscount <= 0) {
          //   setDiscountCoupon(true);

          //   return (
          //     <>
          //       <Modal show={discountCoupon} onHide={handleCouponClose}>
          //         <Modal.Header closeButton>
          //           <Modal.Title>360 Coupon</Modal.Title>
          //         </Modal.Header>
          //         <Modal.Body>
          //           Pass added to My Favourites. You can use 360 Pass at $
          //           {data.title} and enjoy a discount of ${mondiscount}% from
          //           Mon-Thurs. Unfortunately, There is no discount from Fri-Sun.
          //         </Modal.Body>
          //       </Modal>
          //     </>
          //   );
          // } else if (mondiscount <= 0 && fridiscount > 0) {
          //   setDiscountCoupon(true);

          //   return (
          //     <>
          //       <Modal show={discountCoupon} onHide={handleCouponClose}>
          //         <Modal.Header closeButton>
          //           <Modal.Title>360 Coupon</Modal.Title>
          //         </Modal.Header>
          //         <Modal.Body>
          //           Pass added to My Favourites. You can use 360 Pass at $
          //           {data.title} and enjoy a discount of ${fridiscount}% from
          //           Fri-Sun. Unfortunately, There is no discount from Mon-Thurs.
          //         </Modal.Body>
          //       </Modal>
          //     </>
          //   );
          // }
          // setCoupon(true);
          setDiscountCoupon(true);
          // navigate("/vouchers-coupons");
        }
      }
    });
  };

  const handlePromotionsPass = () => {
    // setShow(false);
    //  setCoupon(false);
    //  setDiscountCoupon(true);
    var stringifycoupondetails = JSON.stringify(coupondetails);
    const url = `${apiActiveURL}/promotion/add`;
    let ApiParamForAddCoupon = {
      service_id: data.service_id ? data.service_id : data.id,
      user_id: SessionData[1],
      details: stringifycoupondetails,
      promotion_id: data.service_id,
      type: 2,
    };

    const options = {
      method: "POST",
      headers: {
        AppKey: appKey,
        Token: SessionData[0],
        AppId: appId,
      },
      data: ApiParamForAddCoupon,
      url,
    };
    axios(options)
      .then((res) => {
        // console.log(res, "add coupon");
        if (res.data.code == 200) {
          if (res.data.hasOwnProperty("data")) {
            // handleDiscountCouponShow();
            //  handleDiscountCoupon();
            toast.success("Promotion added successfully", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          } else {
            toast.success("Promotion already added", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }
        } else if (res.data.code === 403) {
          toast.error("Something Went Wrong", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          toast.error("Something Went Wrong", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch((error) => {
        toast.error("Something Went Wrong", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  const handleDiscountCoupon = () => {
    if (mondiscount > 0 && fridiscount > 0) {
      return (
        <>
          <Modal show={discountCoupon} onHide={handleDiscountCouponClose}>
            <Modal.Header closeButton>
              <Modal.Title>360 Coupon</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Pass added to My Favourites. You can use 360 Pass at $
              {data.title ? data?.title : data?.name} and enjoy a discount of $
              {mondiscount}% from Mon-Thurs and ${fridiscount}% from Fri-Sun.
            </Modal.Body>
          </Modal>
        </>
      );
    } else if (mondiscount > 0 && fridiscount <= 0) {
      return (
        <>
          <Modal show={discountCoupon} onHide={handleDiscountCouponClose}>
            <Modal.Header closeButton>
              <Modal.Title>360 Coupon</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Pass added to My Favourites. You can use 360 Pass at $
              {data.title ? data?.title : data?.name} and enjoy a discount of $
              {mondiscount}% from Mon-Thurs. Unfortunately, There is no discount
              from Fri-Sun.
            </Modal.Body>
          </Modal>
        </>
      );
    } else if (mondiscount <= 0 && fridiscount > 0) {
      return (
        <>
          <Modal show={discountCoupon} onHide={handleDiscountCouponClose}>
            <Modal.Header closeButton>
              <Modal.Title>360 Coupon</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Pass added to My Favourites. You can use 360 Pass at $
              {data.title ? data?.title : data?.name} and enjoy a discount of $
              {fridiscount}% from Fri-Sun. Unfortunately, There is no discount
              from Mon-Thurs.
            </Modal.Body>
          </Modal>
        </>
      );
    }
  };
  const generateConfirmationCode = (length) => {
    let confirmationcode = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (let i = 0; i < length; i++) {
      confirmationcode += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return confirmationcode;
  };

  const handleQoin = () => {
    return (
      <>
        <Modal show={showQoin} onHide={handleQoinClose}>
          <Modal.Header closeButton>
            <Modal.Title>Qoin</Modal.Title>
          </Modal.Header>
          <Modal.Body>{qoinDetails}</Modal.Body>
        </Modal>
      </>
    );
  };

  const opingTiming = [
    { id: 1, name: "M" },
    { id: 2, name: "T" },
    { id: 3, name: "W" },
    { id: 4, name: "T" },
    { id: 5, name: "F" },
    { id: 6, name: "S" },
    { id: 7, name: "S" },
  ];

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handlePhoneClose = () => setNumberShow(false);
  const handlePhoneShow = () => setNumberShow(true);

  const handleCouponClose = () => setCoupon(false);

  const handleCouponShow = () => setCoupon(true);
  const handleSpecialShow = () => setShowSpecial(true);
  const handleSpecialClose = () => setShowSpecial(false);

  const handleDiscountCouponShow = () => setDiscountCoupon(true);

  const handleDiscountCouponClose = () => setDiscountCoupon(false);

  const handleQoinClose = () => setShowQoin(false);
  const handleQoinShow = () => setShowQoin(true);

  const handleSpecialOffer = () => {
    return (
      <>
        <Modal
          show={showSpecial}
          onHide={handleSpecialClose}
          className="btn_modal avenue_modal"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <div className="row">
            {promodetails.length > 0 ? (
              promodetails.map((item, index) => (
                <div className="col-md-6">
                  <div
                    className="child_category_box"
                    onClick={() => {
                      navigate("/business", { state: item });
                      handleSpecialClose();
                    }}
                    // style={{ backgroundColor: "red" }}
                  >
                    <p>{item.title}</p>

                    <p
                      dangerouslySetInnerHTML={{
                        __html: item.short_description,
                      }}
                    >
                      {/* {item.short_description} */}
                    </p>
                  </div>
                </div>
              ))
            ) : (
              <Modal.Body>
                {data.title} has no Special Offers at the moment
              </Modal.Body>
            )}
          </div>
          {/* <Modal.Footer>
            
              <Button
                variant="primary"
                href={`tel:${servicephone}`}
                className="green_btn"
              >
                Call Now
              </Button>
           
           
              <Button
                variant="primary"
                href={servicebookingurl}
                target="_blank"
                className="black_btn"
              >
                Book Online{" "}
              </Button>
           
          </Modal.Footer> */}
        </Modal>
      </>
    );
  };
  const handleBookNow = () => {
    return (
      <>
        <Modal show={show} onHide={handleClose} className="btn_modal" centered>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Footer>
            {servicephone ? (
              <Button
                variant="primary"
                className="green_btn call_now_btn"
                onClick={() => {
                  handlePhoneShow();
                  setShow(false);
                }}
              >
                Call Now
              </Button>
            ) : (
              <></>
            )}
            {servicebookingurl && (
              <Button
                onClick={() => setShow(false)}
                variant="primary"
                href={servicebookingurl}
                target="_blank"
                className="black_btn book_online_btn"
              >
                Book Online{" "}
              </Button>
            )}
          </Modal.Footer>
        </Modal>

        <Modal
          show={numbershow}
          onHide={handlePhoneClose}
          className="btn_modal"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Footer>
            {/* <Button variant="primary" className="green_btn call_now_btn">
              Please call {servicephone}
            </Button> */}
            <button
              className="green_btn call_now_btn"
              // onClick={() => {
              //   navigator.clipboard.writeText(servicephone);
              //   setNumberShow(false);
              // }}
            >
              Please call {servicephone}
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };
  const apiHasLoaded = (map, maps) => {
    // this.setState({
    //   mapApiLoaded: true,
    //   mapInstance: map,
    //   mapApi: maps,
    // });
    setmapApiLoaded(true);
    setmapInstance(map);
    setmapApi(maps);
  };

  const defaultProps = {
    center: {
      lat: -28.0275021,
      lng: 153.4315186,
    },

    zoom: 10,
  };

  return (
    <>
      <div className="bg_body business_bg_body">
        <Header ref={headerRef} />
        {handleBookNow()}
        {handleQoin()}
        {handleCoupon()}
        {handleDiscountCoupon()}
        {handleSpecialOffer()}
        <div className="inner_pg">
          {loading ? (
            <Loader />
          ) : (
            <>
              {" "}
              <div className="Business business_schedule">
                <div className="container">
                  <div className="row">
                    <div className="col-xl-12 offset-xl-0 col-lg-10 offset-lg-1 col-md-8 offset-md-2">
                      <h1 className="top_heading">
                        {data.title ? data.title : data.name}{" "}
                      </h1>
                      <div className="row business_row_content">
                        <div className="col-md-6">
                          <div
                            className="bg_img"
                            // style={{
                            //   backgroundImage: `url(${
                            //     featuredpromotion
                            //       ? featuredpromotion
                            //       : defaultThumbnail
                            //   })`,
                            // }}
                          >
                            <img
                              src={
                                featuredpromotion
                                  ? featuredpromotion
                                  : data
                                  ? data?.image_url
                                  : defaultThumbnail
                              }
                            />
                          </div>
                          <p
                            className="business_info_para"
                            dangerouslySetInnerHTML={{
                              __html: servicedetail.description,
                            }}
                          ></p>
                          <div className="promo_business_btn">
                            <Buttonn
                              name={
                                favLoader ? <Loader /> : "Add to Favourites"
                              }
                              className="black_btn"
                              onClick={AddFavourite}
                            />
                            {servicebookingurl || servicephone ? (
                              <Buttonn
                                name="Book Now"
                                className="green_btn"
                                onClick={handleShow}
                              />
                            ) : (
                              <></>
                            )}

                            {isRequest === true ? (
                              <Buttonn
                                name="REQUEST 360 PASS"
                                className="green_btn"
                                onClick={handleCouponShow}
                              />
                            ) : (
                              <></>
                            )}
                            {data?.coupon?.toString() == 1 ? (
                              <Buttonn
                                name=" GET YOUR COUPON"
                                className="green_btn"
                                onClick={handlePromotionsPass}
                              />
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          {listingType == 5 ? (
                            <div className="calendar_detail_main">
                              <div className="calendar_detail_inner">
                                <div className="calendar_detail_open">
                                  <div className="calendar_open_head_title">
                                    <span>open</span>
                                  </div>
                                  <div className="calendar_head_openings">
                                    {opingTiming.map((val) => (
                                      <span>{val?.name}</span>
                                    ))}
                                  </div>
                                </div>
                                <div className="calendar_detail_schedule">
                                  <div className="calendar_open_title">
                                    <span>Breakfast</span>
                                  </div>
                                  <div className="calendar_detail_schedule_inner">
                                    {calendardetails &&
                                      calendardetails[0]?.map((item, index) => (
                                        <>
                                          {item == 1 ? (
                                            <BsCheck2
                                              color="#62a945"
                                              strokeWidth="1.7"
                                              size={15}
                                            />
                                          ) : (
                                            <RxCross2
                                              color="#bfbfbf"
                                              strokeWidth="1.7"
                                              size={15}
                                            />
                                          )}
                                        </>
                                      ))}
                                  </div>
                                </div>
                                <div className="calendar_detail_schedule">
                                  <div className="calendar_open_title">
                                    <span>Lunch</span>
                                  </div>
                                  <div className="calendar_detail_schedule_inner">
                                    {calendardetails &&
                                      calendardetails[1]?.map((item, index) => (
                                        <>
                                          {item == 1 ? (
                                            <BsCheck2
                                              color="#62a945"
                                              strokeWidth="1.7"
                                              size={15}
                                            />
                                          ) : (
                                            <RxCross2
                                              color="#bfbfbf"
                                              strokeWidth="1.7"
                                              size={15}
                                            />
                                          )}
                                        </>
                                      ))}
                                  </div>
                                </div>
                                <div className="calendar_detail_schedule">
                                  <div className="calendar_open_title">
                                    <span>Dinner</span>
                                  </div>
                                  <div className="calendar_detail_schedule_inner">
                                    {calendardetails &&
                                      calendardetails[2]?.map((item, index) => (
                                        <>
                                          {item == 1 ? (
                                            <BsCheck2
                                              color="#62a945"
                                              strokeWidth="1.7"
                                              size={15}
                                            />
                                          ) : (
                                            <RxCross2
                                              color="#bfbfbf"
                                              strokeWidth="1.7"
                                              size={15}
                                            />
                                          )}
                                        </>
                                      ))}
                                  </div>
                                </div>
                                <div className="calendar_detail_schedule calendar_schedule_all_day">
                                  <div className="calendar_open_title">
                                    <span>All Day</span>
                                  </div>
                                  <div className="calendar_detail_schedule_inner">
                                    {calendardetails &&
                                      calendardetails[3]?.map((item, index) => (
                                        <>
                                          {item == 1 ? (
                                            <BsCheck2
                                              color="#62a945"
                                              strokeWidth="1.7"
                                              size={15}
                                            />
                                          ) : (
                                            <RxCross2
                                              color="#bfbfbf"
                                              strokeWidth="1.7"
                                              size={15}
                                            />
                                          )}
                                        </>
                                      ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                          <div className="schedule_sheet_info">
                            <p>{address ? address : "No Address"}</p>
                            <a className="tell_num">{servicephone}</a>

                            <div className="schedule_sheet_bottom">
                              {showWeb ? (
                                <Button
                                  // name="Go to Website"
                                  className="black_border"
                                  href={websiteUrl}
                                >
                                  Go to Website
                                </Button>
                              ) : (
                                <></>
                              )}

                              <Buttonn
                                name="Special Offers"
                                className="black_border"
                                onClick={handleSpecialShow}
                              />

                              {menuURL ? (
                                <Button
                                  // name="MENU"
                                  className="black_border button"
                                  href={menuURL}
                                  target="_blank"
                                >
                                  Menu
                                </Button>
                              ) : (
                                <></>
                              )}

                              {vaccine ? (
                                <img
                                  src={vaccine}
                                  // style={{}}
                                  style={{
                                    height: 60,
                                    width: 60,
                                    resizeMode: "contain",
                                    alignSelf: "center",
                                    marginHorizontal: 5,
                                  }}
                                />
                              ) : (
                                <></>
                              )}
                              {showCoin ? (
                                <div onClick={handleQoinShow}>
                                  <img
                                    src={require("../../assets/images/qoin_image.png")}
                                    style={{
                                      height: 60,
                                      width: 80,
                                      resizeMode: "contain",
                                      alignSelf: "center",
                                      marginHorizontal: 5,
                                      borderRadius: 10,
                                    }}
                                  />
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {/* <div style={{ height: "100vh", width: "100%" }}>
       
      </div> */}
      {servicelatitude != "" ? (
        <div className="business_map_section">
          <div className="map_frame_section">
            <GoogleMapReact
              bootstrapURLKeys={{
                key: GOOGLE_MAP_KEY,
              }}
              defaultCenter={{
                lat:
                  // servicelatitude == ""
                  //   ? 10.99835602
                  //   :
                  parseFloat(servicelatitude),
                lng:
                  // servicelongitude == ""
                  //   ?
                  //   77.01502627
                  //   :
                  parseFloat(servicelongitude),
              }}
              defaultZoom={15}
              // yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={({ map, maps }) => apiHasLoaded(map, maps)}
            >
              <AnyReactComponent
                lat={parseFloat(servicelatitude)}
                lng={parseFloat(servicelongitude)}
                text="My Marker"
              />
            </GoogleMapReact>
          </div>
        </div>
      ) : (
        <></>
      )}
      <Footer ref={footerRef} activeSearch={activeSearch} />
    </>
  );
};

export default Business;
