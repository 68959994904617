import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useParams,
  Switch,
  Navigate,
  useNavigate,
} from "react-router-dom";
// import Home from "../Pages/Home/Main";
import Error from "../Pages/Error";
// import Services from "../Pages/Services";

import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { Hotel } from "../Redux/Actions";
import Footer from "../footer/footer";
import { Home } from "../Pages/Home/Home";
import ChildCategory from "../Pages/Services/Services";
import VouchersCoupons from "../Pages/VouchersCoupons/VouchersCoupons";
import PromoBusiness from "../Pages/PromoBusiness/PromoBusiness";
import Business from "../Pages/Business/Business";
import CreateAccount from "../Pages/CreateAccount/CreateAccount";
import SignAccount from "../Pages/SigninAccount/SignAccount";
import BroadbeachCasual from "../Pages/Categories/Categories";
import VoucherDetails from "../Pages/VoucherDetails/VoucherDetails";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";
import VoucherCouponName from "../Pages/VoucherCoupon/VoucherCoupon";
import Categories from "../Pages/Categories/Categories";
import ChildCategories from "../Pages/ChildCategories/ChildCategories";
import Main from "../Pages/Main/Main";
import Services from "../Pages/Services/Services";
import FavouriteList from "../Pages/FavouriteList/FavouriteList";
import SpecialPromotions from "../Pages/SpecialPromotions/SpecialPromotions";
import MyPassCoupons from "../Pages/MyPassCoupons/MyPassCoupons";
import ListPassCoupons from "../Pages/ListPassCoupons/ListPassCoupons";
import SearchBusiness from "../Pages/SearchBusiness/SearchBusiness";
import CustomerAccount from "../component/MyAccount/MyAccount";
import ConfirmEmail from "../Pages/ForgotNamePass/ConfirmEmail";
import ConfirmCode from "../Pages/ForgotNamePass/ConfirmCode";
import PasswordReset from "../Pages/ForgotNamePass/PasswordReset";
import UsernameReset from "../Pages/ForgotNamePass/UsernameReset";

const AppRoutes = (props) => {
  const [login, setLogin] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/create-account" element={<CreateAccount />} />
        <Route path="/login-account" element={<SignAccount />} />

        <Route
          path="/home"
          element={
            <RequireAuth>
              <Home />
            </RequireAuth>
          }
        />

        <Route
          path="/categories"
          element={
            <RequireAuth>
              <Categories />
            </RequireAuth>
          }
        />
        <Route
          path="/my-account"
          element={
            <RequireAuth>
              <CustomerAccount />
            </RequireAuth>
          }
        />
        <Route
          path="/child-categories"
          element={
            <RequireAuth>
              <ChildCategories />
            </RequireAuth>
          }
        />

        <Route
          path="/services"
          element={
            <RequireAuth>
              <Services />
            </RequireAuth>
          }
        />
        <Route
          path="/business"
          element={
            <RequireAuth>
              <Business />
            </RequireAuth>
          }
        />
        <Route
          path="/promo-business"
          element={
            <RequireAuth>
              <PromoBusiness />
            </RequireAuth>
          }
        />

        <Route
          path="/vouchers-coupons"
          element={
            <RequireAuth>
              <VouchersCoupons />
            </RequireAuth>
          }
        />

        <Route
          path="/voucher-details"
          element={
            <RequireAuth>
              <VoucherDetails />
            </RequireAuth>
          }
        />
        <Route
          path="/favourite-list"
          element={
            <RequireAuth>
              <FavouriteList />
            </RequireAuth>
          }
        />
        <Route
          path="/special-promotions"
          element={
            <RequireAuth>
              <SpecialPromotions />
            </RequireAuth>
          }
        />
        <Route
          path="/my-pass-coupons"
          element={
            <RequireAuth>
              <MyPassCoupons />
            </RequireAuth>
          }
        />
        <Route
          path="/list-pass-coupons"
          element={
            <RequireAuth>
              <ListPassCoupons />
            </RequireAuth>
          }
        />
        <Route
          path="/search-business"
          element={
            <RequireAuth>
              <SearchBusiness />
            </RequireAuth>
          }
        />
        <Route path="/confirm-email" element={<ConfirmEmail />} />
        <Route path="/confirm-code" element={<ConfirmCode />} />
        <Route path="/rest-password" element={<PasswordReset />} />
        <Route path="/rest-username" element={<UsernameReset />} />
      </Routes>
    </React.Fragment>
  );
};

const RequireAuth = ({ children }) => {
  const userIsLogged = localStorage.getItem("SessionData"); // Your hook to get login status

  if (!userIsLogged) {
    return <SignAccount />;
  }
  return children;
};

export default AppRoutes;
