import React from "react";
import Spinner from "react-bootstrap/Spinner";
const Button = ({ name, className, type, onClick, disabled, loading }) => {
  return (
    <button
      className={`${className} ${disabled && "btn_disabled"} `}
      type={type}
      onClick={onClick}
      disabled={disabled}
      loading={loading}
    >
      {name}
    </button>
  );
};

export default Button;
