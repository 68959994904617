import React, { useState, useEffect } from "react";
import { Container, Form, Modal } from "react-bootstrap";
import header_img from "../../assets/images/verticallogo.png";
import user_icon from "../../assets/images/user_icon.png";
import confirm_mail_icon from "../../assets/images/confirm_mail_icon.png";
import user_mail_icon from "../../assets/images/user_mail_icon.png";
import Button from "../../component/Button/Button";
import InputField from "../../component/InputText/InputText";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { ResetLogin, ResetLoginError } from "../../Redux/Actions";
import { Login } from "../../Redux/Actions";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../component/Loader/Loader";
import { toast } from "react-toastify";
import { apiActiveURL } from "../../ApiBaseURL";
import axios from "axios";

const UsernameReset = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [username, setUserName] = useState("");
  const [phone, setPhone] = useState("");
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState("");
  const [showMessagePopup, setShowMessagePopup] = useState(false);

  const handleAuthenticateClose = () => setShowMessagePopup(false);

  useEffect(() => {
    let timeout;
    if (showMessagePopup) {
      timeout = setTimeout(() => {
        setShowMessagePopup(false);
        navigate("/login-account");
      }, 5000);
    }
    return () => clearTimeout(timeout);
  }, [showMessagePopup]);

  const authenticate = (name, number) => {
    if (!name || !number) {
      toast.error("All Fields are Required.", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    }
    setLoader(true);
    const url = `${apiActiveURL}/forget_contact`;
    let ApiParamForUsername = {
      name,
      phone_no: number,
    };
    const options = {
      method: "POST",
      data: ApiParamForUsername,
      url,
    };
    axios(options)
      .then((res) => {
        console.log(res, "forgot username");
        if (res?.data?.code == 200) {
          setShowMessagePopup(true);
          setUserName("");
          setPhone("");
        } else {
          toast.error(res?.data?.message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        setLoader(false);
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
      });
  };

  return (
    <>
      <Modal
        show={showMessagePopup}
        onHide={handleAuthenticateClose}
        className="forgot_username_popup"
        backdrop="static"
        centered
      >
        <Modal.Header>
          <Modal.Title>Team Business Network</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          A Customer Support Representative will be in touch with you as soon as
          possible during business hours.
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={() => handleAuthenticateClose()}
            className="sign_up_btn tbn_forgot_popup_btn"
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
      <div className="bg_body">
        <Container>
          <div className="account_forgot_username">
            <div className="account_forgot_username_inner">
              <img className="account_header_logo" src={header_img} alt="" />
              <h3>Forgot Your Password? No Issues, Just</h3>
              <div className="creat_account_fields">
                <Form>
                  <div className="row_section">
                    <div className="password_section">
                      <InputField
                        DivclassName="creation_input_section"
                        type="text"
                        placeholder="Enter Your Username"
                        className="custom_input_field"
                        onChange={(e) => {
                          setUserName(e.target.value);
                        }}
                        value={username}
                        required
                      />
                    </div>
                  </div>
                  <div className="row_section">
                    <div className="password_section">
                      <InputField
                        DivclassName="creation_input_section"
                        type="number"
                        placeholder="Enter Your Phone Number"
                        className="custom_input_field"
                        onChange={(e) => {
                          setPhone(e.target.value);
                        }}
                        value={phone}
                        required
                      />
                    </div>
                  </div>

                  <p style={{ textAlign: "center", color: "#ff3333" }}>
                    {error}
                  </p>
                  {loader ? (
                    <Button
                      name={<Loader />}
                      className="sign_up_btn"
                      type="submit"
                    />
                  ) : (
                    <>
                      {/* <Button
                    name="Send"
                    className="sign_up_btn"
                    onClick={() => navigate("/confirm-code")}
                  /> */}
                      <button
                        onClick={() => {
                          authenticate(username, phone);
                        }}
                        className="sign_up_btn"
                      >
                        Send
                      </button>
                    </>
                  )}
                </Form>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default UsernameReset;
