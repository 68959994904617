import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Button from "../../component/Button/Button";
import FavouriteBox from "../../component/FavouriteBox/FavouriteBox";
import Loader from "../../component/Loader/Loader";
import Footer from "../../footer/footer";
import Header from "../../Header/Header";

import {
  GetSpecialPromotions,
  ResetGetSpecialPromotions,
  ResetGetSpecialPromotionsError,
} from "../../Redux/Actions/GetSpecialPromotions";
import "./SpecialPromotions.css";
import { useLocation } from "react-router";

const SpecialPromotions = (props) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(true);
  const [error, setError] = useState("");
  const location = useLocation();
  // console.log(location, "location special promotions");

  const [specialPromotionsData, setSpecialPromotionsData] = useState([]);

  const GetSpecialPromotionsSuccess = useSelector((state) => {
    // console.log(state.GetSpecialPromotions, "get special promotions success");
    return state.GetSpecialPromotions.data;
  });

  const GetSpecialPromotionsError = useSelector((state) => {
    return state.GetSpecialPromotions.error;
  });
  useEffect(() => {
    dispatch(GetSpecialPromotions());

    return () => {
      dispatch(ResetGetSpecialPromotions());
      dispatch(ResetGetSpecialPromotionsError());
    };
  }, []);

  useEffect(() => {
    if (GetSpecialPromotionsSuccess) {
      //   console.log(
      //     GetSpecialPromotionsSuccess,
      //     "if GetSpecialPromotionsSuccess"
      //   );
      setLoader(false);
      setSpecialPromotionsData(GetSpecialPromotionsSuccess.data.data);
    } else if (GetSpecialPromotionsError) {
      setLoader(false);
      toast.error(GetSpecialPromotionsError, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      setLoader(false);
      setError("No Special Promotions Found");
    }

    return () => {
      // dispatch(ResetCategoryFail());
      // dispatch(ResetCategory());
    };
  }, [GetSpecialPromotionsSuccess, GetSpecialPromotionsError]);

  //   const removeItem = (removeId) => {
  //     const afterRemoval = favouriteData.filter((a) => a.id != removeId);
  //     console.log(afterRemoval, ":afterRemoval");
  //     setfavouriteData([...afterRemoval]);
  //   };
  return (
    <>
      <div className="bg_body">
        <Header />
        <div className="inner_pg">
          <div className="voucherscoupons">
            <div className="container">
              <div className="row">
                <div className="col-md-8 offset-md-2">
                  <h1 className="top_heading">Special Promotions</h1>
                  {!loader ? (
                    <div className="row">
                      {specialPromotionsData.length > 0 ? (
                        specialPromotionsData.map((item) => (
                          <>
                            {console.log(item, "item")}
                            <FavouriteBox
                              data={item}
                              name="specialPromotions"
                            />
                          </>
                        ))
                      ) : (
                        <p className="text-center">
                          No Special Promotions Found
                        </p>
                      )}
                    </div>
                  ) : (
                    <Loader />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default SpecialPromotions;
