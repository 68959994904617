import axios from "axios";
import { apiActiveURL, appId, appKey } from "../../ApiBaseURL";

export const GetPassCoupons = (listingtype) => {
  // console.log(listingtype, "listing type");
  //   return;
  const SessionData = JSON.parse(localStorage.getItem("SessionData"));

  var config = {
    url: `${apiActiveURL}/coupons/${SessionData[1]}?listing_type=${listingtype}`,
    method: "GET",
    headers: {
      AppKey: appKey,
      Token: SessionData[0],
      AppId: appId,
    },
  };

  return (dispatch) => {
    axios(config)
      .then((res) => {
        // console.log(res, "get 360 pass coupons response =======");
        if (res.data.status) {
          GetPassCouponsSuccess(dispatch, res);
        } else {
          GetPassCouponsFail(dispatch, res.data.message);
        }
      })
      .catch((e) => {
        GetPassCouponsFail(dispatch, e.message);
      });
  };
};

export const ResetGetPassCoupons = () => {
  return (dispatch) => {
    dispatch({ type: "GET_PASS_COUPONS_SUCCESS", payload: null });
  };
};

export const ResetGetPassCouponsError = () => {
  return (dispatch) => {
    dispatch({ type: "GET_PASS_COUPONS_FAIL", payload: null });
  };
};

const GetPassCouponsSuccess = (dispatch, res) => {
  dispatch({ type: "GET_PASS_COUPONS_SUCCESS", payload: res });
};

const GetPassCouponsFail = (dispatch, res) => {
  dispatch({ type: "GET_PASS_COUPONS_FAIL", payload: res });
};
