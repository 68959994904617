import React, { useState } from "react";
import Footer from "../../footer/footer";
import Header from "../../Header/Header";
import ChildCategoryBox from "../../component/ChildCategoryBox/ChildCategoryBox";
import Button from "../../component/Button/Button";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import {
  ResetService,
  ResetServiceFail,
  Service,
} from "../../Redux/Actions/Services";
import { toast } from "react-toastify";
import Loader from "../../component/Loader/Loader";

const cat_detail = [
  {
    cat_title: "Business/Topic Name",
    cat_text:
      "Lorem ipsum dolor sit amet consectetur adipiscing elit. Cras eget sodales phasellus cursus no vitae,",
  },
  {
    cat_title: "Business/Topic Name",
    cat_text:
      "Lorem ipsum dolor sit amet consectetur adipiscing elit. Cras eget sodales phasellus cursus no vitae,",
  },
  {
    cat_title: "Business/Topic Name",
    cat_text:
      "Lorem ipsum dolor sit amet consectetur adipiscing elit. Cras eget sodales phasellus cursus no vitae,",
  },
  {
    cat_title: "Business/Topic Name",
    cat_text:
      "Lorem ipsum dolor sit amet consectetur adipiscing elit. Cras eget sodales phasellus cursus no vitae,",
  },
  {
    cat_title: "Business/Topic Name",
    cat_text:
      "Lorem ipsum dolor sit amet consectetur adipiscing elit. Cras eget sodales phasellus cursus no vitae,",
  },
  {
    cat_title: "Business/Topic Name",
    cat_text:
      "Lorem ipsum dolor sit amet consectetur adipiscing elit. Cras eget sodales phasellus cursus no vitae,",
  },
];
const servicePerPage = 6;
const Services = (props) => {
  const [serviceData, setServiceData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [error, setError] = useState("");
  const [next, setNext] = useState(servicePerPage);

  const SaveUserSuccess = useSelector((state) => {
    return state.GetUser.data;
  });
  const ServiceId = useSelector((state) => state?.ChildCategories?.service_id);
  const ServiceSuccess = useSelector((state) => state?.Services);
  const ServiceFail = useSelector((state) => state?.Services?.error);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const listing_type = localStorage.getItem("listingType");
    const data = {
      area: SaveUserSuccess?.data?.data?.area,
      suburb: SaveUserSuccess?.data?.data?.suburb,
      serviceId: ServiceId,
      listingType: listing_type,
    };
    // console.log(data, "service data");
    dispatch(Service(data));
    return () => {
      dispatch(ResetService());
      dispatch(ResetServiceFail());
      // console.log(ServiceSuccess, ServiceFail, "reset service fail");
    };
  }, []);

  useEffect(() => {
    if (ServiceSuccess?.service?.data.code == 200) {
      setLoader(false);
      // console.log(ServiceSuccess.service.data, "service data");

      if (ServiceSuccess.service?.data?.data.length > 0) {
        setServiceData(
          ServiceSuccess?.service?.data?.data.sort((a, b) =>
            a.title.localeCompare(b.title)
          )
        );
        // dispatch(ResetCategory());
      } else {
        setError("No Service Found");
      }
    } else if (ServiceFail) {
      toast.error(ServiceFail, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      // dispatch(ResetServiceFail());

      // dispatch(ResetService())
    }
  }, [ServiceSuccess, ServiceFail]);

  const handleShowMorePosts = () => {
    setNext(next + servicePerPage);
  };

  return (
    <>
      <div className="bg_body">
        <Header />
        <div className="ChildCategory business_listing_main">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 offset-xl-0 col-lg-10 offset-lg-1 col-md-8 offset-md-2">
                <h1>{location?.state}</h1>
                {loader ? (
                  <div>
                    <Loader />
                  </div>
                ) : serviceData.length > 0 ? (
                  <div className="row">
                    {serviceData.slice(0, next).map((item, index) => (
                      <>
                        {/* {console.log(item, "ChildCategoryBox-ChildCategoryBox")} */}
                        <ChildCategoryBox
                          key={index}
                          i={index}
                          title={item.title}
                          text={item.short_description}
                          data={item}
                          businessimage={item.image}
                          // next={next}
                        />
                      </>
                    ))}
                  </div>
                ) : (
                  <div className="service_loader text-center">{error}</div>
                )}
                {next < serviceData?.length && (
                  <div className="btn_div">
                    <Button
                      className="black_btn"
                      name="Load More"
                      onClick={handleShowMorePosts}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Services;
