const INITIAL_STATE = {
  data: null,
  error: null,
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "FOCUS_HANDLE":
      // console.log(action.payload, "status in  HandleFocus(true)");
      return { ...state, data: action.payload };

    default:
      return state;
  }
};
