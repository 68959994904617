import axios from "axios";
import { apiActiveURL, appId, appKey } from "../../ApiBaseURL";

export const GetBusiness = (servicedetailid) => {
  const SessionData = JSON.parse(localStorage.getItem("SessionData"));
  // console.log(SessionData[0], "session dataaaaaaaa");
  var config = {
    url: `${apiActiveURL}/service/${servicedetailid}`,
    method: "GET",
    headers: {
      AppKey: appKey,
      Token: SessionData[0],
      AppId: appId,
    },
  };

  return (dispatch) => {
    axios(config)
      .then((res) => {
        // console.log(res, "services response");
        // console.log(res, "hotel response");
        if (res.data.status) {
          GetBusinessSuccess(dispatch, res);
        } else {
          GetBusinessFail(dispatch, res.data.message);
        }
      })
      .catch((e) => {
        // console.log(e, "fail");
        GetBusinessFail(dispatch, e.message);
      });
  };
};

export const ResetGetBusiness = () => {
  // console.log("reset sign up action called");
  return (dispatch) => {
    dispatch({ type: "GET_BUSINESS_SUCCESS", payload: null });
  };
};

export const ResetGetBusinessError = () => {
  // console.log("reset sign up error action called");
  return (dispatch) => {
    dispatch({ type: "GET_BUSINESS_FAIL", payload: null });
  };
};

const GetBusinessSuccess = (dispatch, res) => {
  // console.log(res, "sign up data");
  dispatch({ type: "GET_BUSINESS_SUCCESS", payload: res });
};

const GetBusinessFail = (dispatch, res) => {
  // console.log(res, "res action");
  dispatch({ type: "GET_BUSINESS_FAIL", payload: res });
};
