import React, { useState, useEffect } from "react";
import { Container, Form } from "react-bootstrap";
import header_img from "../../assets/images/verticallogo.png";
import user_icon from "../../assets/images/user_icon.png";
import confirm_mail_icon from "../../assets/images/confirm_mail_icon.png";
import user_mail_icon from "../../assets/images/user_mail_icon.png";
import Button from "../../component/Button/Button";
import InputField from "../../component/InputText/InputText";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { ResetLogin, ResetLoginError } from "../../Redux/Actions";
import { Login } from "../../Redux/Actions";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../component/Loader/Loader";
import { toast } from "react-toastify";

const SignAccount = () => {
  const userData = JSON.parse(localStorage.getItem("UserData"));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState(userData ? userData[0] : "");
  const [loginStatus, setLoginStatus] = useState(false);
  const [loader, setLoader] = useState(false);

  const [password, setPassword] = useState(userData ? userData[1] : "");
  const [error, setError] = useState("");

  const userLoginSuccess = useSelector((state) => state?.login?.Login?.data);
  const userLoginFail = useSelector((state) => state?.login?.error);

  useEffect(() => {
    console.log(JSON.parse(localStorage.getItem("UserData")), "user data---");
    if (userLoginSuccess) {
      if (userLoginSuccess?.code == 200) {
        setLoader(false);
        const session_token = userLoginSuccess.data.token;
        const id = userLoginSuccess.data.user_id;
        const data = [session_token, id];
        localStorage.setItem("SessionData", JSON.stringify(data));
        const userData = [email, password];
        localStorage.setItem("UserData", JSON.stringify(userData));
        // localStorage.setItem("userToken", userLoginSuccess.data.token);
        setEmail("");
        setPassword("");
        setLoginStatus(true);
        navigate("/home");
        dispatch(ResetLogin());
      }
    } else if (userLoginFail) {
      // console.log(userLoginFail, "userLoginFail");
      // console.log(" here jajdja");
      setLoader(false);
      toast.error(userLoginFail, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      dispatch(ResetLoginError());
      // console.log("error here");
      // setError(userLoginFail.data.data);
    }
  }, [userLoginSuccess, userLoginFail, error]);

  const handleLogin = (e) => {
    e.preventDefault();
    setLoader(true);

    const data = {
      email: email,
      password: password,
      remember: 1,
    };
    // console.log(data.email, "datadata");
    // console.log(data.password, "datadata");

    dispatch(Login(data));
  };

  return (
    <div className="bg_body">
      <Container>
        <div className="create_account">
          <div className="login_account_inner">
            <img className="account_header_logo" src={header_img} alt="" />
            <h3>Welcome back! Sign In</h3>
            <div className="creat_account_fields">
              <Form onSubmit={handleLogin}>
                <div className="row_section">
                  <InputField
                    DivclassName="mail_input_section"
                    type="email"
                    placeholder="Email Address"
                    className="custom_input_field"
                    icon={user_mail_icon}
                    iconClass="account_mail_icons"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    // required
                    value={email}
                  />
                </div>
                <div className="row_section">
                  <div className="password_section">
                    <InputField
                      DivclassName="creation_input_section"
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      className="custom_input_field"
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                      // required
                      value={password}
                    />
                    {showPassword ? (
                      <FaEyeSlash onClick={() => setShowPassword(false)} />
                    ) : (
                      <FaEye onClick={() => setShowPassword(true)} />
                    )}
                  </div>
                </div>
                <p className="forget_password">
                  Forgotten your{" "}
                  <span onClick={() => navigate("/confirm-email")}>
                    Username or Password
                  </span>
                </p>
                <p style={{ textAlign: "center", color: "#ff3333" }}>{error}</p>
                {loader ? (
                  <Button
                    name={<Loader />}
                    className="sign_up_btn"
                    type="submit"
                  />
                ) : (
                  <Button name="Sign in" className="sign_up_btn" />

                  // <Button name="Submit" className="sign_up_btn" type="submit" />
                )}

                <p className="already_exits_account">
                  Don't have an account?{" "}
                  <span onClick={() => navigate("/create-account")}>
                    Sign Up
                  </span>
                </p>
              </Form>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default SignAccount;
