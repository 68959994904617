import axios from "axios";
import { apiActiveURL, appId, appKey } from "../../ApiBaseURL";

export const Login = (data) => {
  // console.log(data, "data===============================================");
  // return

  var config = {
    url: `${apiActiveURL}/login_new`,
    method: "POST",
    headers: {
      AppKey: appKey,
      // Token: "A3Oy18BqjvFPIUTXY94im76ENuh5HVsngbo2lJxkL0reaZftKWcSDQwMpCzR",
      AppId: appId,
      // Token: token
    },
    data: data,
  };

  return (dispatch) => {
    axios(config)
      .then((res) => {
        // console.log(res, "login response");

        if (res.data.code == 403) {
          // console.log(res, "kasdmaskjdsak");
          LoginFail(dispatch, res.data.data);
        } else {
          LoginSuccess(dispatch, res);
        }
      })
      .catch((e) => {
        // console.log(e.message, "catch error");
        LoginFail(dispatch, e.message);
      });
  };
};

export const ResetLoginError = () => {
  return (dispatch) => {
    dispatch({ type: "LOGIN_FAIL", payload: null });
  };
};

export const ResetLogin = () => {
  return (dispatch) => {
    dispatch({ type: "LOGIN_SUCCESS", payload: null });
  };
};

const LoginSuccess = (dispatch, res) => {
  // console.log(res, "post data res");
  dispatch({ type: "LOGIN_SUCCESS", payload: res });
};

const LoginFail = (dispatch, res) => {
  dispatch({ type: "LOGIN_FAIL", payload: res });
};
