import React, { forwardRef, useState } from "react";
import "./footer.css";
import f_icon_1 from "../assets/footer_icon_1.png";
import f_icon_2 from "../assets/footer_icon_2.png";
import f_icon_3 from "../assets/footer_icon_3.png";
import f_icon_4 from "../assets/footer_icon_4.png";
import { useNavigate } from "react-router-dom";
import { HandleFocus } from "../Redux/Actions/focusHandler";
import { useDispatch } from "react-redux";
// import { useRef } from "react";

const Footer = forwardRef((props, ref) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <footer className="footer_area">
      <ul>
        <li>
          <a
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/my-account");
            }}
          >
            <div className="footer_menu">
              <img src={f_icon_1} />
              My Account
            </div>
          </a>
        </li>
        <li>
          <a
            style={{ cursor: "pointer" }}
            onClick={() => {
              localStorage.setItem("listingType", 5);
              navigate("/categories");
            }}
          >
            <div className="footer_menu">
              <img src={f_icon_2} />
              Restaurants
            </div>
          </a>
        </li>
        <li>
          <a
            style={{ cursor: "pointer" }}
            onClick={() => {
              localStorage.setItem("listingType", 14);
              navigate("/categories");
            }}
          >
            <div className="footer_menu">
              <img src={f_icon_3} />
              Qoin
            </div>
          </a>
        </li>
        <li>
          <a
            style={{ cursor: "pointer" }}
            onClick={() => {
              // console.log("hereeee");
              dispatch(HandleFocus(true));
            }}
          >
            <div className="footer_menu">
              <img src={f_icon_4} />
              Search
            </div>
          </a>
        </li>
      </ul>
    </footer>
  );
});

export default Footer;
