import React, { useEffect, useState } from "react";
import { Container, Form } from "react-bootstrap";
import header_img from "../../assets/images/verticallogo.png";
import user_icon from "../../assets/images/user_icon.png";
import confirm_mail_icon from "../../assets/images/confirm_mail_icon.png";
import user_mail_icon from "../../assets/images/user_mail_icon.png";
import phone_icon from "../../assets/images/phone.svg";
import Button from "../../component/Button/Button";
import InputField from "../../component/InputText/InputText";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import {
  SignUp,
  ResetSignUp,
  ResetSignUpError,
} from "../../Redux/Actions/SignUp";
import { useDispatch, useSelector } from "react-redux";
import { HiOutlineMail } from "react-icons/hi";
import Loader from "../../component/Loader/Loader";
import CompanyLoader from "../../component/Loader/CompanyLoader";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const CreateAccount = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const signUpSuccess = useSelector((state) => {
    // console.log(state.SignUp, "signUppp success selector");
    return state.SignUp.data;
  });

  const signUpError = useSelector((state) => {
    // console.log(state.SignUp.error, "signUppp error selector");
    return state.SignUp.error;
  });

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [affiliateCode, setAffiliateCode] = useState("");
  const [errMsg, setErrMsg] = useState(false);
  const [msgBody, setMsgBody] = useState("");
  const [loading, setLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showconfirmPassword, setShowConfirmPassword] = useState(false);
  const [showaffiliate, setShowAffiliate] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrMsg(false);
    setMsgBody("");

    if (password !== confirmPassword) {
      setErrMsg(true);
      setMsgBody("Password does not match.");
      return;
    }

    let data = {
      firstname: firstName,
      lastname: lastName,
      email: email,
      username: email,
      password: password,
      phone: phoneNumber,
      affiliate_code: affiliateCode,
    };
    // console.log(data, "hello");
    setLoading(true);
    dispatch(SignUp(data));
  };

  const emptyfields = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhoneNumber("");
    setPassword("");
    setConfirmPassword("");
    setAffiliateCode("");
  };

  useEffect(() => {
    if (signUpSuccess) {
      // console.log(signUpSuccess, "if useEffect sign up success");
      toast.success("User Signed Up Successfully", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setLoading(false);
      emptyfields();
      dispatch(ResetSignUp());
    } else if (signUpError) {
      // console.log(signUpError, "else if useEffect sign up error");
      setLoading(false);
      emptyfields();
      toast.error(signUpError, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      setErrMsg(true);
      dispatch(ResetSignUpError());
    } else {
      setLoading(false);
      emptyfields();
      dispatch(ResetSignUp());
      dispatch(ResetSignUpError());
    }
  }, [signUpSuccess, signUpError]);

  return (
    <>
      <div className="bg_body">
        <Container>
          <div className="create_account">
            <div className="create_account_inner">
              <img className="account_header_logo" src={header_img} alt="" />
              <h3>Create Your Account</h3>
              <div className="row">
                <div className="col-lg-8 offset-lg-2 col-md-12">
                  <div className="creat_account_fields">
                    <Form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-md-6">
                          <InputField
                            className="custom_input_field"
                            DivclassName="name_input_section"
                            type="text"
                            placeholder="First Name"
                            icon={user_icon}
                            iconClass="account_name_icons"
                            onChange={(e) => {
                              setFirstName(e.target.value);
                            }}
                            value={firstName}
                            required
                          />
                        </div>
                        <div className="col-md-6">
                          <InputField
                            type="text"
                            placeholder="Last Name"
                            className="custom_input_field"
                            onChange={(e) => {
                              setLastName(e.target.value);
                            }}
                            value={lastName}
                            required
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <InputField
                            DivclassName="mail_input_section"
                            type="email"
                            placeholder="Email Address"
                            className="custom_input_field"
                            icon={user_mail_icon}
                            iconClass="account_mail_icons"
                            onChange={(e) => {
                              setEmail(e.target.value);
                            }}
                            value={email}
                            required
                          />
                        </div>
                        <div className="col-md-6">
                          <InputField
                            DivclassName="mail_input_section"
                            type="number"
                            placeholder="Phone Number"
                            className="custom_input_field"
                            icon={phone_icon}
                            iconClass="account_mail_icons"
                            onChange={(e) => {
                              setPhoneNumber(e.target.value);
                            }}
                            value={phoneNumber}
                            required
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="password_section">
                            <InputField
                              DivclassName="creation_input_section"
                              type={showPassword ? "text" : "password"}
                              placeholder="Password"
                              className="custom_input_field"
                              onChange={(e) => {
                                setPassword(e.target.value);
                              }}
                              value={password}
                              required
                            />
                            {showPassword ? (
                              <FaEyeSlash
                                onClick={() => setShowPassword(false)}
                              />
                            ) : (
                              <FaEye onClick={() => setShowPassword(true)} />
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="password_section">
                            <InputField
                              DivclassName="creation_input_section"
                              type={showconfirmPassword ? "text" : "password"}
                              placeholder="Confirm Password"
                              className="custom_input_field"
                              onChange={(e) => {
                                setConfirmPassword(e.target.value);
                              }}
                              value={confirmPassword}
                              required
                            />
                            {showconfirmPassword ? (
                              <FaEyeSlash
                                onClick={() => setShowConfirmPassword(false)}
                              />
                            ) : (
                              <FaEye
                                onClick={() => setShowConfirmPassword(true)}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row_section">
                        <InputField
                          DivclassName="creation_input_section"
                          type="text"
                          placeholder="Affiliate Code"
                          className="custom_input_field"
                          onChange={(e) => {
                            setAffiliateCode(e.target.value);
                          }}
                          value={affiliateCode}
                        />
                      </div>

                      {msgBody ? (
                        <>
                          <p
                            style={{
                              textAlign: "center",
                              color: "#ff3333",
                              fontWeight: "bolder",
                              fontSize: "15px",
                            }}
                          >
                            {msgBody}
                          </p>
                        </>
                      ) : null}

                      {loading ? (
                        <Button
                          name={<Loader />}
                          className="sign_up_btn"
                          type="submit"
                        />
                      ) : (
                        <Button
                          name="Submit"
                          className="sign_up_btn"
                          type="submit"
                        />
                      )}

                      <p className="already_exits_account">
                        Already Have an Account?{" "}
                        <span onClick={() => navigate("/login-account")}>
                          Sign in
                        </span>
                      </p>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default CreateAccount;
